import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable, filter } from 'rxjs';
import { AuthService } from '../core/auth/auth.service';
import { NotificationsService } from '../shared/notifications/notifications.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ds-notificationmodule',
  templateUrl: './notificationmodule.component.html',
  styleUrls: ['./notificationmodule.component.scss'],
})
export class NotificationmoduleComponent implements OnInit {
  fifthFloorImageId = 0;
  tenthFloorImageId = 0;
  isImageLoading = true;
  constructor(
    private authService: AuthService,
    private router: Router,
    private notificationService: NotificationsService,
    private changeDetectionRef: ChangeDetectorRef
  ) { }
  announcement: boolean;
  calendar: boolean;
  halloffame: boolean;
  isLoggedIn: Observable<boolean>;
  tabs: any = [];
  activeTabName: string = 'Mumbai & Nagpur';
  announcementData: any = [];
  manipulateAnnouncementData: any = [];
  monthOfYear: any;
  holidayData: any = {
    'Mumbai & Nagpur': [
      {
        'S.No': '1',
        Holiday: 'Republic Day',
        Date: '26-Jan-23',
        Day: 'Thursday',
      },
      {
        'S.No': '2',
        Holiday: 'Holi(Second Day)/Dhuleti',
        Date: '7-Mar-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '3',
        Holiday: 'Gudi Padwa/Ugadi Festival',
        Date: '22-Mar-23',
        Day: 'Wednesday',
      },
      {
        'S.No': '4',
        Holiday: 'Shree Ram Navami',
        Date: '30-Mar-23',
        Day: 'Thursday',
      },
      {
        'S.No': '5',
        Holiday: 'Mahavir Jayanti',
        Date: '4-Apr-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '6',
        Holiday: 'Good Friday',
        Date: '7-Apr-23',
        Day: 'Friday',
      },
      {
        'S.No': '7',
        Holiday: 'Dr. Babasaheb Ambedkar Jayanti/Bohag Bihu',
        Date: '14-Apr-23',
        Day: 'Friday',
      },
      {
        'S.No': '8',
        Holiday: 'Maharashtra Day',
        Date: '1-May-23',
        Day: 'Monday',
      },
      {
        'S.No': '9',
        Holiday: 'Buddha Purnima',
        Date: '5-May-23',
        Day: 'Friday',
      },
      {
        'S.No': '10',
        Holiday: 'Bakari Eid',
        Date: '28-Jun-23',
        Day: 'Wednesday',
      },
      {
        'S.No': '11',
        Holiday: 'Independence Day',
        Date: '15-Aug-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '12',
        Holiday: 'Parsi New Year (Shahenshahi)',
        Date: '16-Aug-23',
        Day: 'Wednesday',
      },
      {
        'S.No': '13',
        Holiday: 'Ganesh Chaturthi/Samvatsari (Chaturthi Paksha)',
        Date: '19-Sep-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '14',
        Holiday:
          "Eid-E-Milad/Eid-e-Meeladunnabi - (Prophet Mohammad's Birthday) (Bara Vafat)",
        Date: '28-Sep-23',
        Day: 'Thursday',
      },
      {
        'S.No': '15',
        Holiday: 'Mahatma Gandhi ',
        Date: '2-Oct-23',
        Day: 'Monday',
      },
      {
        'S.No': '16',
        Holiday: 'Dussehra/Dusshera (Vijaya Dashmi)/Durga Puja',
        Date: '24-Oct-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '17',
        Holiday:
          'Diwali (Bali Pratipada)/Deepavali/Vikram Samvant New Year Day',
        Date: '14-Nov-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '18',
        Holiday: 'Guru Nanak Jayanti/Kartika Purnima',
        Date: '27-Nov-23',
        Day: 'Monday',
      },
      {
        'S.No': '19',
        Holiday: 'Christmas',
        Date: '25-Dec-23',
        Day: 'Monday',
      },
    ],
    Hyderabad: [
      {
        'S.No': '1',
        Holiday: 'Republic Day',
        Date: '26-Jan-23',
        Day: 'Thursday',
      },
      {
        'S.No': '2',
        Holiday: 'Holi(Second Day)/Dhuleti',
        Date: '7-Mar-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '3',
        Holiday: 'Gudi Padwa/Ugadi Festival',
        Date: '22-Mar-23',
        Day: 'Wednesday',
      },
      {
        'S.No': '4',
        Holiday: 'Shree Ram Navami',
        Date: '30-Mar-23',
        Day: 'Thursday',
      },
      {
        'S.No': '5',
        Holiday: 'Good Friday',
        Date: '7-Apr-23',
        Day: 'Friday',
      },
      {
        'S.No': '6',
        Holiday: 'Dr. Babasaheb Ambedkar Jayanti/Bohag Bihu',
        Date: '14-Apr-23',
        Day: 'Friday',
      },
      {
        'S.No': '7',
        Holiday: 'Maharashtra Day/May Day',
        Date: '1-May-23',
        Day: 'Monday',
      },
      {
        'S.No': '8',
        Holiday: 'Buddha Purnima',
        Date: '5-May-23',
        Day: 'Friday',
      },
      {
        'S.No': '9',
        Holiday: 'Bakari Eid',
        Date: '28-Jun-23',
        Day: 'Wednesday',
      },
      {
        'S.No': '10',
        Holiday: 'Independence Day',
        Date: '15-Aug-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '11',
        Holiday: 'Janmashtami (Shravan Vad-8)/Sri Krishna Ashtami',
        Date: '7-Sep-23',
        Day: 'Thursday',
      },
      {
        'S.No': '12',
        Holiday: 'Varasiddhi Vinayaka Vrata/Vinayaka Chathurthi',
        Date: '18-Sep-23',
        Day: 'Monday',
      },
      {
        'S.No': '13',
        Holiday:
          "Eid-E-Milad/Eid-e-Meeladunnabi - (Prophet Mohammad's Birthday) (Bara Vafat)",
        Date: '28-Sep-23',
        Day: 'Thursday',
      },
      {
        'S.No': '14',
        Holiday: 'Mahatma Gandhi ',
        Date: '2-Oct-23',
        Day: 'Monday',
      },
      {
        'S.No': '15',
        Holiday: 'Dussehra/Dusshera (Vijaya Dashmi)/Durga Puja',
        Date: '24-Oct-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '16',
        Holiday: 'Guru Nanak Jayanti/Kartika Purnima',
        Date: '27-Nov-23',
        Day: 'Monday',
      },
      {
        'S.No': '17',
        Holiday: 'Christmas',
        Date: '25-Dec-23',
        Day: 'Monday',
      },
    ],
    Chennai: [
      {
        'S.No': '1',
        Holiday: 'Thiruvalluvar Day',
        Date: '16-Jan-23',
        Day: 'Monday',
      },
      {
        'S.No': '2',
        Holiday: 'Uzhavar Thirunal',
        Date: '17-Jan-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '3',
        Holiday: 'Republic Day',
        Date: '26-Jan-23',
        Day: 'Thursday',
      },
      {
        'S.No': '4',
        Holiday:
          "Gudi Padwa/Ugadi Festival/Bihar Divas/Sajibu Nongmapanba (Cheiraoba)/Telugu New Year's Day",
        Date: '22-Mar-23',
        Day: 'Wednesday',
      },
      {
        'S.No': '5',
        Holiday: 'Mahavir Jayanti',
        Date: '4-Apr-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '6',
        Holiday: 'Good Friday',
        Date: '7-Apr-23',
        Day: 'Friday',
      },
      {
        'S.No': '7',
        Holiday: 'Dr. Babasaheb Ambedkar Jayanti/Bohag Bihu',
        Date: '14-Apr-23',
        Day: 'Friday',
      },
      {
        'S.No': '8',
        Holiday: 'Maharashtra Day/May Day',
        Date: '1-May-23',
        Day: 'Monday',
      },
      {
        'S.No': '9',
        Holiday: 'Bakari Eid',
        Date: '28-Jun-23',
        Day: 'Wednesday',
      },
      {
        'S.No': '10',
        Holiday: 'Independence Day',
        Date: '15-Aug-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '11',
        Holiday: 'Sri Krishna Janmashtami',
        Date: '6-Sep-23',
        Day: 'Wednesday',
      },
      {
        'S.No': '12',
        Holiday:
          "Eid-E-Milad/Eid-e-Meeladunnabi - (Prophet Mohammad's Birthday) (Bara Vafat)",
        Date: '28-Sep-23',
        Day: 'Thursday',
      },
      {
        'S.No': '13',
        Holiday: 'Mahatma Gandhi ',
        Date: '2-Oct-23',
        Day: 'Monday',
      },
      {
        'S.No': '14',
        Holiday: 'Dusshera (Mahanavami)/Ayudha Pooja/Durga Puja/Vijaya Dasami',
        Date: '23-Oct-23',
        Day: 'Monday',
      },
      {
        'S.No': '15',
        Holiday: 'Dussehra/Dusshera (Vijaya Dashmi)/Durga Puja',
        Date: '24-Oct-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '16',
        Holiday: 'Christmas',
        Date: '25-Dec-23',
        Day: 'Monday',
      },
    ],
    'New Delhi': [
      {
        'S.No': '1',
        Holiday: 'Thiruvalluvar Day',
        Date: '16-Jan-23',
        Day: 'Monday',
      },
      {
        'S.No': '2',
        Holiday: 'Uzhavar Thirunal',
        Date: '17-Jan-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '3',
        Holiday: 'Republic Day',
        Date: '26-Jan-23',
        Day: 'Thursday',
      },
      {
        'S.No': '4',
        Holiday:
          "Gudi Padwa/Ugadi Festival/Bihar Divas/Sajibu Nongmapanba (Cheiraoba)/Telugu New Year's Day",
        Date: '22-Mar-23',
        Day: 'Wednesday',
      },
      {
        'S.No': '5',
        Holiday: 'Mahavir Jayanti',
        Date: '4-Apr-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '6',
        Holiday: 'Good Friday',
        Date: '7-Apr-23',
        Day: 'Friday',
      },
      {
        'S.No': '7',
        Holiday: 'Dr. Babasaheb Ambedkar Jayanti/Bohag Bihu',
        Date: '14-Apr-23',
        Day: 'Friday',
      },
      {
        'S.No': '8',
        Holiday: 'Maharashtra Day/May Day',
        Date: '1-May-23',
        Day: 'Monday',
      },
      {
        'S.No': '9',
        Holiday: 'Bakari Eid',
        Date: '28-Jun-23',
        Day: 'Wednesday',
      },
      {
        'S.No': '10',
        Holiday: 'Independence Day',
        Date: '15-Aug-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '11',
        Holiday: 'Sri Krishna Janmashtami',
        Date: '6-Sep-23',
        Day: 'Wednesday',
      },
      {
        'S.No': '12',
        Holiday:
          "Eid-E-Milad/Eid-e-Meeladunnabi - (Prophet Mohammad's Birthday) (Bara Vafat)",
        Date: '28-Sep-23',
        Day: 'Thursday',
      },
      {
        'S.No': '13',
        Holiday: 'Mahatma Gandhi ',
        Date: '2-Oct-23',
        Day: 'Monday',
      },
      {
        'S.No': '14',
        Holiday: 'Dusshera (Mahanavami)/Ayudha Pooja/Durga Puja/Vijaya Dasami',
        Date: '23-Oct-23',
        Day: 'Monday',
      },
      {
        'S.No': '15',
        Holiday: 'Dussehra/Dusshera (Vijaya Dashmi)/Durga Puja',
        Date: '24-Oct-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '16',
        Holiday: 'Christmas',
        Date: '25-Dec-23',
        Day: 'Monday',
      },
    ],
    Bangalore: [
      {
        'S.No': '1',
        Holiday: 'Republic Day',
        Date: '26-Jan-23',
        Day: 'Thursday',
      },
      {
        'S.No': '2',
        Holiday: 'Holi(Second Day)/Dhuleti',
        Date: '7-Mar-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '3',
        Holiday: 'Gudi Padwa/Ugadi Festival',
        Date: '22-Mar-23',
        Day: 'Wednesday',
      },
      {
        'S.No': '4',
        Holiday: 'Mahavir Jayanti',
        Date: '4-Apr-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '5',
        Holiday: 'Good Friday',
        Date: '7-Apr-23',
        Day: 'Friday',
      },
      {
        'S.No': '6',
        Holiday: 'Dr. Babasaheb Ambedkar Jayanti/Bohag Bihu',
        Date: '14-Apr-23',
        Day: 'Friday',
      },
      {
        'S.No': '7',
        Holiday: 'Maharashtra Day',
        Date: '1-May-23',
        Day: 'Monday',
      },
      {
        'S.No': '8',
        Holiday: 'Bakari Eid',
        Date: '29-Jun-23',
        Day: 'Thursday',
      },
      {
        'S.No': '9',
        Holiday: 'Independence Day',
        Date: '15-Aug-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '10',
        Holiday: 'Varasiddhi Vinayaka Vrata/Vinayaka Chathurthi',
        Date: '18-Sep-23',
        Day: 'Monday',
      },
      {
        'S.No': '11',
        Holiday:
          "Eid-E-Milad/Eid-e-Meeladunnabi - (Prophet Mohammad's Birthday) (Bara Vafat)",
        Date: '28-Sep-23',
        Day: 'Thursday',
      },
      {
        'S.No': '12',
        Holiday: 'Mahatma Gandhi ',
        Date: '2-Oct-23',
        Day: 'Monday',
      },
      {
        'S.No': '13',
        Holiday: 'Dusshera (Mahanavami)/Ayudha Pooja/Durga Puja',
        Date: '23-Oct-23',
        Day: 'Monday',
      },
      {
        'S.No': '14',
        Holiday: 'Dussehra/Dusshera (Vijaya Dashmi)/Durga Puja',
        Date: '24-Oct-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '15',
        Holiday: 'Kannada Rajyothsava',
        Date: '1-Nov-23',
        Day: 'Wednesday',
      },
      {
        'S.No': '16',
        Holiday:
          'Diwali (Bali Pratipada)/Deepavali/Vikram Samvant New Year Day',
        Date: '14-Nov-23',
        Day: 'Tuesday',
      },
      {
        'S.No': '17',
        Holiday: 'Kanakadasa Jayanthi',
        Date: '30-Nov-23',
        Day: 'Thursday',
      },
      {
        'S.No': '18',
        Holiday: 'Christmas',
        Date: '25-Dec-23',
        Day: 'Monday',
      },
    ],
  };

  announcements: [];
  subname: string;
  section: string;
  data: any = [
    {
      home: {
        title: [
          'ReBIT, in collaboration with LinkedIn Learning, is launching expert-led certification courses to help you develop in-demand skills to advance your career.',
          'Designed to meet the conferencing needs of RBI, this Progressive Web Application (PWA) will be used by various departments of RBI to organise conferences, create a schedule for them, and invite participants and speakers for the conference.',
          'During the first quarter of FY23, the Testing CoE will provide ETL training.',
        ],
        announcement: true,
        calendar: true,
        halloffame: true,
      },
      vertical: {
        title: [
          'ReBIT, in collaboration with LinkedIn Learning, is launching expert-led certification courses to help you develop in-demand skills to advance your career.',
          'Designed to meet the conferencing needs of RBI, this Progressive Web Application (PWA) will be used by various departments of RBI to organise conferences, create a schedule for them, and invite participants and speakers for the conference.',
          'During the first quarter of FY23, the Testing CoE will provide ETL training.',
        ],
        announcement: true,
        calendar: true,
        halloffame: true,
      },
      project: {
        title: [
          'ReBIT, in collaboration with LinkedIn Learning, is launching expert-led certification courses to help you develop in-demand skills to advance your career.',
          'Designed to meet the conferencing needs of RBI, this Progressive Web Application (PWA) will be used by various departments of RBI to organise conferences, create a schedule for them, and invite participants and speakers for the conference.',
          'During the first quarter of FY23, the Testing CoE will provide ETL training.',
        ],
        announcement: true,
        calendar: true,
        halloffame: true,
      },
      projectdetails: {
        title: [
          'ReBIT, in collaboration with LinkedIn Learning, is launching expert-led certification courses to help you develop in-demand skills to advance your career.',
          'Designed to meet the conferencing needs of RBI, this Progressive Web Application (PWA) will be used by various departments of RBI to organise conferences, create a schedule for them, and invite participants and speakers for the conference.',
          'During the first quarter of FY23, the Testing CoE will provide ETL training.',
        ],
        announcement: true,
        calendar: true,
        halloffame: true,
      },
      aria: {
        title: [
          'ReBIT, in collaboration with LinkedIn Learning, is launching expert-led certification courses to help you develop in-demand skills to advance your career.',
          'Designed to meet the conferencing needs of RBI, this Progressive Web Application (PWA) will be used by various departments of RBI to organise conferences, create a schedule for them, and invite participants and speakers for the conference.',
          'During the first quarter of FY23, the Testing CoE will provide ETL training.',
        ],
        announcement: true,
        calendar: true,
        halloffame: true,
      },
      profile: {
        title: [
          'ReBIT, in collaboration with LinkedIn Learning, is launching expert-led certification courses to help you develop in-demand skills to advance your career.',
          'Designed to meet the conferencing needs of RBI, this Progressive Web Application (PWA) will be used by various departments of RBI to organise conferences, create a schedule for them, and invite participants and speakers for the conference.',
          'During the first quarter of FY23, the Testing CoE will provide ETL training.',
        ],
        announcement: true,
        calendar: true,
        halloffame: true,
      },
      userprofile: {
        title: [
          'ReBIT, in collaboration with LinkedIn Learning, is launching expert-led certification courses to help you develop in-demand skills to advance your career.',
          'Designed to meet the conferencing needs of RBI, this Progressive Web Application (PWA) will be used by various departments of RBI to organise conferences, create a schedule for them, and invite participants and speakers for the conference.',
          'During the first quarter of FY23, the Testing CoE will provide ETL training.',
        ],
        announcement: true,
        calendar: true,
        halloffame: true,
      },
      coe: {
        title: [
          'ReBIT, in collaboration with LinkedIn Learning, is launching expert-led certification courses to help you develop in-demand skills to advance your career.',
          'Designed to meet the conferencing needs of RBI, this Progressive Web Application (PWA) will be used by various departments of RBI to organise conferences, create a schedule for them, and invite participants and speakers for the conference.',
          'During the first quarter of FY23, the Testing CoE will provide ETL training.',
        ],
        announcement: true,
        calendar: true,
        halloffame: true,
      },
      coeSeeAll: {
        title: [
          'ReBIT, in collaboration with LinkedIn Learning, is launching expert-led certification courses to help you develop in-demand skills to advance your career.',
          'Designed to meet the conferencing needs of RBI, this Progressive Web Application (PWA) will be used by various departments of RBI to organise conferences, create a schedule for them, and invite participants and speakers for the conference.',
          'During the first quarter of FY23, the Testing CoE will provide ETL training.',
        ],
        announcement: true,
        calendar: true,
        halloffame: true,
      },
      coedetail: {
        title: [
          'ReBIT, in collaboration with LinkedIn Learning, is launching expert-led certification courses to help you develop in-demand skills to advance your career.',
          'Designed to meet the conferencing needs of RBI, this Progressive Web Application (PWA) will be used by various departments of RBI to organise conferences, create a schedule for them, and invite participants and speakers for the conference.',
          'During the first quarter of FY23, the Testing CoE will provide ETL training.',
        ],
        announcement: true,
        calendar: true,
        halloffame: true,
      },
      'community-list': {
        title: [
          'ReBIT, in collaboration with LinkedIn Learning, is launching expert-led certification courses to help you develop in-demand skills to advance your career.',
          'Designed to meet the conferencing needs of RBI, this Progressive Web Application (PWA) will be used by various departments of RBI to organise conferences, create a schedule for them, and invite participants and speakers for the conference.',
          'During the first quarter of FY23, the Testing CoE will provide ETL training.',
        ],
        announcement: true,
        calendar: true,
        halloffame: true,
      },
      communities: {
        title: [
          'ReBIT, in collaboration with LinkedIn Learning, is launching expert-led certification courses to help you develop in-demand skills to advance your career.',
          'Designed to meet the conferencing needs of RBI, this Progressive Web Application (PWA) will be used by various departments of RBI to organise conferences, create a schedule for them, and invite participants and speakers for the conference.',
          'During the first quarter of FY23, the Testing CoE will provide ETL training.',
        ],
        announcement: true,
        calendar: true,
        halloffame: true,
      },
    },
  ];
  regionWiseHoliday: any;
  baseUrl: string = environment.rest.baseUrl;

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isAuthenticated();
    this.subname = this.router.url;
    this.tabs = Object.keys(this.holidayData);
    this.regionWiseHoliday = this.holidayData[this.tabs[0]];
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var date = new Date();
    this.monthOfYear = months[date.getMonth()] + ' ' + date.getFullYear();

    this.notificationService.getFilteredAnnouncementList().subscribe({
      next: (res) => {
        let arr = res.payload;
        this.announcementData = [];
        for (let i in arr) {
          this.announcementData.push(arr[i]);
        }
        this.announcementData.sort((a, b) => (new Date(a.announcementDate ?? a.announcementStartDate)).getTime() - (new Date(b.announcementDate ?? b.announcementStartDate)).getTime());
        this.announcementData.forEach((value, i) => {
          if (i <= 2) {
            this.manipulateAnnouncementData.push(value);
          }
        });
        this.changeDetectionRef.detectChanges();
      },
      error: (err) => {
        console.log(err);
      },
    });
    this.getMenuCafe();
  }
  ngAfterViewInit() {
    let url = this.router.url;

    if (url) {
      url = url.replace('/', '');
      var splitted = url.split('/', 1);
      url = splitted[0];
      if (this.data[0][url]) {
        this.announcements = this.data[0][url]['title'] || '';

        this.calendar = this.data[0][url]['calendar'] || '';
        this.halloffame = this.data[0][url]['halloffame'] || '';
        this.announcement = this.data[0][url]['announcement'] || '';
      }
    }
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        if (event.url) {
          let url = event.url.replace('/', '');

          if (this.data[0][url]) {
            this.announcements = this.data[0][url]['title'] || '';

            this.calendar = this.data[0][url]['calendar'] || '';
            this.halloffame = this.data[0][url]['halloffame'] || '';
            this.announcement = this.data[0][url]['announcement'] || '';
          }
        }
      });
  }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 600,
    navText: ['&#8249', '›'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      760: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
    nav: true,
  };

  sections(value) {
    this.section = value;
  }

  Holidays(val) {
    this.activeTabName = val;
    this.regionWiseHoliday = this.holidayData[val];
  }

  getMenuCafe() {
    this.isImageLoading = true;
    this.notificationService.getCafeMenu().subscribe(res => {
      console.log('CafeMenu Data', res)
      this.fifthFloorImageId = res?.find(x => x.cafeLocation === "5th Floor")?.cafeId ?? 0;
      this.tenthFloorImageId = res?.find(x => x.cafeLocation === "10th Floor")?.cafeId ?? 0;
      console.log("fifthFloorImageId", this.fifthFloorImageId)
      console.log("tenthFloorImageId", this.tenthFloorImageId)
      this.isImageLoading = false;
      this.changeDetectionRef.detectChanges();
      setTimeout(() => {
        this.changeDetectionRef.detectChanges();
      }, 1400);
      setTimeout(() => {
        this.changeDetectionRef.detectChanges();
      }, 2700);
    }, () => { this.isImageLoading = false },)
  }
}
