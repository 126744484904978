<!--<div class="card">
  <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/communities/', object.id]" class="card-img-top" [attr.title]="'search.results.view-result' | translate">
    <ds-thumbnail [thumbnail]="(object.logo | async)?.payload" [limitWidth]="false">
    </ds-thumbnail>
  </a>
  <span *ngIf="linkType == linkTypes.None" class="card-img-top">
    <ds-thumbnail [thumbnail]="(object.logo | async)?.payload" [limitWidth]="false">
    </ds-thumbnail>
  </span>
  <div class="card-body">
    <h4 class="card-title">{{object.name}}</h4>
    <p *ngIf="object.shortDescription" class="card-text">{{object.shortDescription}}</p>
    <div *ngIf="linkType != linkTypes.None" class="text-center">
      <a [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/communities/', object.id]" class="lead btn btn-primary viewButton">{{ 'search.results.view-result' | translate}}</a>
    </div>
  </div>
</div>-->

<div>
  <div>
    <div
      [ngbTooltip]="object.name"
      [routerLink]="['/communities/' + object.id]"
    >
      <!-- <span class="badge badge-collection5">Sub Community</span> -->
      <div class="row lg-3 md-2 align-items-center grid-card">
        <div class="col-auto">
          <div class="icon-container">
            <img loading="lazy" class="small-img" src="assets/images/commu-icon.svg" />
          </div>
        </div>

        <div
          class="col community-overflow pl-0"
          style="position: relative"
          *ngIf="linkType != linkTypes.None"
        >
          <span class="badge badge-collection5 mb-1">{{
            "batch.subcommunity" | translate
          }}</span>
          <h4
            class="card-title mb-0 community-card-heading"
            [routerLink]="['/communities/' + object.id]"
          >
            {{ object.name }}
          </h4>
        </div>
        <div
          class="col community-overflow pl-0"
          *ngIf="linkType == linkTypes.None"
        >
          <h4 class="card-title mb-0 community-card-heading">
            {{ object.name }}
          </h4>
          <div *ngIf="object.shortDescription" class="com-md abstract-text">
            {{ object.shortDescription }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--<a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/communities/' + object.id]" class="lead">
{{object.name}}
</a>
<span *ngIf="linkType == linkTypes.None" class="lead">
{{object.name}}
</span>-->
</div>
