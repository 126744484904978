<div class="forbidden container mt-5 mb-5">
  <h1>403</h1>
  <h2>
    <small>{{ "403.forbidden" | translate }}</small>
  </h2>
  <br />
  <p>{{ "403.help" | translate }}</p>
  <br />
  <p class="text-center">
    <a routerLink="/home" class="btn btn-primary">{{
      "403.link.home-page" | translate
    }}</a>
  </p>
</div>
