<ds-pagination
  [paginationOptions]="config"
  [pageInfoState]="objects?.payload"
  [collectionSize]="objects?.payload?.totalElements"
  [sortOptions]="sortConfig"
  [hideGear]="hideGear"
  [objects]="objects"
  [hidePagerWhenSinglePage]="hidePagerWhenSinglePage"
  [hidePaginationDetail]="hidePaginationDetail"
  [showPaginator]="showPaginator"
  (pageChange)="onPageChange($event)"
  (pageSizeChange)="onPageSizeChange($event)"
  (sortDirectionChange)="onSortDirectionChange($event)"
  (sortFieldChange)="onSortFieldChange($event)"
  (paginationChange)="onPaginationChange($event)"
  (prev)="goPrev()"
  (next)="goNext()"
>
  <div class="row" *ngIf="objects?.hasSucceeded">
    <div
      class="col-lg-3 col-md-4 pb-4 explore-card-height"
      *ngFor="let column of columns$ | async"
      @fadeIn
    >
      <div
        class="card-element community-card mb-3 p-0"
        *ngFor="let object of column"
        [attr.data-test]="'grid-object' | dsBrowserOnly"
      >
        <ds-listable-object-component-loader
          class="myecm-card"
          [object]="object"
          [viewMode]="viewMode"
          [context]="context"
          [linkType]="linkType"
        ></ds-listable-object-component-loader>
      </div>
    </div>
  </div>

  <ds-error
    *ngIf="objects.hasFailed"
    message="{{ 'error.objects' | translate }}"
  ></ds-error>
  <ds-themed-loading
    *ngIf="objects.isLoading"
    message="{{ 'loading.objects' | translate }}"
  ></ds-themed-loading>
</ds-pagination>
