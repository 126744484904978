<div class="row mb-2 flex-item">
  <div class="col">
    <h5 class="search-head">{{ "search.filters.head" | translate }}</h5>
  </div>
  <div class="col-auto">
    <div class="filters">
      <a
        class="clear-all"
        [routerLink]="[searchLink]"
        [queryParams]="clearParams | async"
        queryParamsHandling="merge"
        role="button"
        ><i class="fa-solid fa-rotate-right"></i>
        {{ "search.filters.reset" | translate }}</a
      >
    </div>
  </div>
</div>
<div *ngIf="(filters | async)?.hasSucceeded">
  <div *ngFor="let filter of (filters | async)?.payload; trackBy: trackUpdate">
    <ds-search-filter
      [filter]="filter"
      [inPlaceSearch]="inPlaceSearch"
      [refreshFilters]="refreshFilters"
    ></ds-search-filter>
  </div>
</div>
