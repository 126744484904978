<div
 
  id="search-navbar-container"
  [title]="'nav.search' | translate"
  (dsClickOutside)="collapse()"
>
  <div class="d-inline-block position-relative">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit(searchForm.value)">
      <input
        #searchInput
        [@toggleAnimation]="isExpanded"
        [attr.aria-label]="'nav.search' | translate"
        name="query"
        autocomplete="off"
        formControlName="query"
        type="text"
        placeholder="{{ searchExpanded ? ('nav.search' | translate) : '' }}"
        class="d-inline-block position-absolute form-control form-control-sm dropdown-menu-right p-3 pr-4 pd-30 mr-3"
        [attr.data-test]="'header-search-box' | dsBrowserOnly"
      />
      <a
        class="submit-icon"
        [routerLink]=""
        (click)="searchExpanded ? onSubmit(searchForm.value) : expand()"
        [attr.data-test]="'header-search-icon' | dsBrowserOnly"
      >
        <!-- <em style="color: #013b91" class="fa-solid fa-magnifying-glass"></em> -->
        <img src="assets/images/search_icon.png" alt="search icon" class="search-icon">
      </a>
      <!-- <button type="button" class="header_search"> -->
      <!-- <i class="fa-solid fa-magnifying-glass"></i> -->
      <!-- </button>   -->
    </form>
  </div>
</div>
