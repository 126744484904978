<div>
  <div class="vertical-box">
    <div>
      <div class="eve-heading p-3 digital-heading">
        <span class="flex-item"
          ><img
            loading="lazy"
            src="assets/images/arrow-left-solid.svg"
            alt="arrow"
            height="20"
            class="back-img"
            (click)="goToCoeProjectPage()"
          />{{ this.coeDetails }}</span
        >
      </div>

      <div
        class="navigation-menu digital-repository coedetails"
        style="overflow: auto"
      >
        <div class="card-box content">
          <div class="event-card">
            <div class="eve-heading">Initiative</div>
            <p class="blog-info">{{ this.coeDetails }}</p>

            <div class="eve-heading">Description</div>
            <div *ngFor="let desc of coeDesc">
              <p class="blog-info mb-0" *ngIf="coeDesc.length > 0">
                {{ desc }}
              </p>
            </div>
          </div>
        </div>
        <div class="card-box bx-shadow">
          <div class="event-card resource">
            <div class="eve-heading">Team</div>
            <div class="user-box" *ngIf="teamsList.length > 0">
              <div class="user-detail col-md-3 p-0" *ngFor="let teams of teamsList">
                <ng-container *ngIf="teams != null">
                  <img loading="lazy" src="assets/images/User.svg" alt="user" />

                  <a
                    class="profile-name"
                    (click)="goToProfile('profile', teams.employeeId)"
                    >{{ teams.employeeName }}</a>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
