<div
  class="thumbnail"
  [class.limit-width]="limitWidth"
  *ngVar="isLoading$ | async as isLoading"
>
  <div *ngIf="isLoading" class="thumbnail-content outer">
    <div class="inner">
      <div class="centered">
        <ds-themed-loading [spinner]="true"></ds-themed-loading>
      </div>
    </div>
  </div>
  <ng-container *ngVar="src$ | async as src">
    <!-- don't use *ngIf="!isLoading" so the thumbnail can load in while the animation is playing -->
    <img loading="lazy"
      *ngIf="src !== null"
      class="thumbnail-content img-fluid"
      [ngClass]="{ 'd-none': isLoading }"
      [src]="src | dsSafeUrl"
      (error)="errorHandler()"
      (load)="successHandler()"
    />
    <div *ngIf="src === null && !isLoading" class="thumbnail-content outer">
      <div>
        <!-- <div class="thumbnail-placeholder w-100 h-100 p-3 lead">{{ placeholder | translate }}</div> -->

        <!-- <div>
          <img loading="lazy" src="assets/images/commu-icon.svg" style="max-width: unset;" />
        </div> -->
      </div>
    </div>
  </ng-container>
</div>
