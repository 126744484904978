<form
  #form="ngForm"
  (ngSubmit)="onSubmit(value)"
  [action]="action"
  (keydown)="onKeydown($event)"
  (keydown.arrowdown)="shiftFocusDown($event)"
  (keydown.arrowup)="shiftFocusUp($event)"
  (keydown.esc)="close()"
  (dsClickOutside)="close()"
>
  <div class="form-group mb-0 pt-2 pb-2">
    <label *ngIf="label; else searchInput" class="mb-0 w-100">
      <span class="com-sm-head">
        {{ label }}
      </span>
      <ng-container *ngTemplateOutlet="searchInput"></ng-container>
    </label>
  </div>
  <ng-template #searchInput>
    <input
      #inputField
      type="text"
      [(ngModel)]="value"
      [name]="name"
      class="form-control form-control-sm suggestion_input mt-2 rounded-pill"
      [ngClass]="{ 'is-invalid': !valid }"
      [dsDebounce]="debounceTime"
      (onDebounce)="find($event)"
      [placeholder]="placeholder"
      [ngModelOptions]="{ standalone: true }"
      autocomplete="off"
    />
  </ng-template>
  <label class="d-none">
    <input type="submit" />
    <span>{{ "search.filters.search.submit" | translate }}</span>
  </label>
  <div
    class="autocomplete dropdown-menu"
    [ngClass]="{ show: (show | async) && isNotEmpty(suggestions) }"
  >
    <div class="dropdown-list">
      <div *ngFor="let suggestionOption of suggestions">
        <a
          href="javascript:void(0);"
          class="d-block dropdown-item"
          (click)="onClickSuggestion(suggestionOption)"
          #suggestion
        >
          <span [innerHTML]="suggestionOption.displayValue"></span>
        </a>
      </div>
    </div>
  </div>
</form>
