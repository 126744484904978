import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavbarComponent as BaseComponent } from '../../../../app/navbar/navbar.component';
import { slideMobileNav } from '../../../../app/shared/animations/slide';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';

/**
 * Component representing the public navbar
 */
@Component({
  selector: 'ds-navbar',
  styleUrls: ['./navbar.component.scss'],
  templateUrl: './navbar.component.html',
  animations: [slideMobileNav],
})
export class NavbarComponent extends BaseComponent implements AfterViewInit {
  btnActive: string = 'home';
  activeBtn(btnActive: string) {
    this.btnActive = btnActive;
  }

  public navigateToSection(section: string) {
    this.router.navigateByUrl('/home').then(() => {
      window.location.hash = '';
      window.location.hash = section;
    });
  }

  ngAfterViewInit() {
    let url = this.router.url;
    if (url === '/home') {
      this.btnActive = 'home';
    } else if (url === '/home#statistics') {
      this.btnActive = 'statistics';
    } else if (url === '/home#whatsnew_section') {
      this.btnActive = 'whatsnew_section';
    } else if (url === '/communities/dcf8ba25-aedb-43b2-9266-57d2bd237ac7') {
      this.btnActive = 'researchhub';
    } else if (url === '/about_us') {
      this.btnActive = 'about_us';
    } else if (url === '/') {
      // console.log("PPPPPPPPPPPPPPPP")
      this.btnActive = 'home';
    } else {
      this.btnActive = '';
    }
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        // console.log(event.url);
        //if (event.url === "/home" || event.url === "/home#statistics" || event.url === "/about_us" || event.url == "/") {
        if (event.url === '/home') {
          this.btnActive = 'home';
        } else if (event.url === '/home#statistics') {
          this.btnActive = 'statistics';
        } else if (event.url === '/home#whatsnew_section') {
          this.btnActive = 'whatsnew_section';
        } else if (event.url === '/about_us') {
          this.btnActive = 'about_us';
        } else if (event.url === '/') {
          // console.log("PPPPPPPPPPPPPPPP")
          this.btnActive = 'home';
        } else {
          this.btnActive = '';
        }
      });
  }
}
