import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as coedata from '../../app/coedata.json';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../shared/notifications/notifications.service';

@Component({
  selector: 'ds-coedetail',
  templateUrl: './coedetail.component.html',
  styleUrls: ['./coedetail.component.scss'],
})
export class CoedetailComponent implements OnInit {
  coeid: string;
  depid: string;
  initId: string;
  title: string;
  description: string;
  team: any[];
  initiativesData: any[];
  baseurl: string = environment.rest.baseUrl;
  coeDesc:any=[];
  coeDetails:any;
  teamsList:any=[];

  constructor(
    private actRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private http: HttpClient,
    private cdRef: ChangeDetectorRef,private notificationService: NotificationsService
  ) {
    this.coeid = this.actRoute.snapshot.params['id'];
    this.depid = this.actRoute.snapshot.params['depid'];
    this.initId = this.actRoute.snapshot.params['initid'];
    this.notificationService.getCoeDetails().subscribe((res:any) =>{
      this.coeDetails = res;
    });
  }

  ngOnInit(): void {
    // const initiativesData: any[] = (coedata as any).default;

    // if (this.coeid == 'coe1' || this.coeid == 'coe8') {
    //   this.title =
    //     initiativesData[this.coeid]['deps'][this.depid]['initiative'][
    //       this.initId
    //     ]['title'];
    //   this.description =
    //     initiativesData[this.coeid]['deps'][this.depid]['initiative'][
    //       this.initId
    //     ]['description'];
    //   this.team = initiativesData[this.coeid]['deps'][this.depid]['team'];
    // } else {
    //   this.title =
    //     initiativesData[this.coeid]['initiative'][this.initId]['title'];
    //   this.description =
    //     initiativesData[this.coeid]['initiative'][this.initId]['description'];
    //   this.team = initiativesData[this.coeid]['team'];
    //   // console.log(this.team);
    // }
    // this.getCoeInitiatives();
    this.getCoeTeams();
    this.getCoeIinitDesc();
  }

  goToProfile(route, val) {
    this.router.navigate([`/${route}/${val}`]);
  }
  goToCoeProjectPage() {
    this.location.back();
    let obj ={
      'coeid':this.coeid,
      'depid':this.depid,
    }
    this.notificationService.setCoeNames(obj);
  }
  getCoeIinitDesc(){
    let employeeCoeType:any;let employeeCoeName:any;
    if( this.coeid == 'coe1'){
      employeeCoeType =  'COPE'; 
      if(this.depid == 'dep1'){
        employeeCoeName = 'Program & product enablement';
      } else if(this.depid == 'dep2'){
        employeeCoeName = 'Enterprise Consulting';
      } else if(this.depid == 'dep3'){
        employeeCoeName = 'UI UX';
      } else if(this.depid == 'dep4'){
        employeeCoeName = 'Process standards and Research';
      } else if(this.depid == 'dep5'){
        employeeCoeName = 'Product Management';
      }
    } else if(this.coeid == 'coe2'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'Microsoft COE';
    } else if(this.coeid == 'coe3'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE JAVA';
    } else if(this.coeid == 'coe4'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE Testing';
    } else if(this.coeid == 'coe5'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE PMO';
    } else if(this.coeid == 'coe6'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE Sustenance Engineering';
    } else if(this.coeid == 'coe7'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'FCT';
    } else if(this.coeid == 'coe8'){
      employeeCoeType =  'SA'; 
      employeeCoeName = this.depid == 'dep1' ? 'BOOST':'FINTECH';
    } else if(this.coeid == 'coe9'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'SAP COE';
    }else if(this.coeid == 'coe10'){
      employeeCoeType =  'EA'; 
      employeeCoeName = this.depid == 'dep1' ? 'EA CoE':'Global Standards';
    }
    let obj={
      "coeType":  employeeCoeType,
      "coeName": employeeCoeName,
      "coeInitiatives":  this.coeDetails,
    }
    this.http.post(`${this.baseurl}/api/cope/coeDesc`,obj)
        .subscribe({
          next: (res: any) => {
            this.coeDesc = res;
            this.cdRef.detectChanges();
          },
        })
  }

  getCoeTeams(){
    let employeeCoeType:any;let employeeCoeName:any;
    if(this.coeid == 'coe1'){
      employeeCoeType =  'COPE'; 
      if(this.depid == 'dep1'){
        employeeCoeName = 'Program & product enablement';
      } else if(this.depid == 'dep2'){
        employeeCoeName = 'Enterprise Consulting';
      } else if(this.depid == 'dep3'){
        employeeCoeName = 'UI UX';
      } else if(this.depid == 'dep4'){
        employeeCoeName = 'Process standards and Research';
      } else if(this.depid == 'dep5'){
        employeeCoeName = 'Product Management';
      }
    } else if(this.coeid == 'coe2'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'Microsoft COE';
    } else if(this.coeid == 'coe3'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE JAVA';
    } else if(this.coeid == 'coe4'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE Testing';
    } else if(this.coeid == 'coe5'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE PMO';
    } else if(this.coeid == 'coe6'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE Sustenance Engineering';
    } else if(this.coeid == 'coe7'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'FCT';
    } else if(this.coeid == 'coe8'){
      employeeCoeType =  'SA'; 
      employeeCoeName = this.depid == 'dep1' ? 'BOOST':'FINTECH';
    } else if(this.coeid == 'coe9'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'SAP COE';
    }else if(this.coeid == 'coe10'){
      employeeCoeType =  'EA'; 
      employeeCoeName = this.depid == 'dep1' ? 'EA CoE':'Global Standards';
    }
    let obj={
      "coeType":  employeeCoeType,
      "coeName": employeeCoeName,
    }
    this.http.post(`${this.baseurl}/api/cope/coeTeam`,obj)
        .subscribe({
          next: (res: any) => {
            this.teamsList = res;
            this.cdRef.detectChanges();
          },
        })
  }
}
