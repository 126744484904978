<a
  href="javascript:void(0);"
  class="nav-item nav-link item-color"
  role="button"
  [title]="item.text | translate"
  (click)="activate($event)"
  (keyup.space)="activate($event)"
  (keyup.enter)="activate($event)"
  >{{ item.text | translate }}</a
>
