import { Component, OnInit } from '@angular/core';
import { DSONameService } from 'src/app/core/breadcrumbs/dso-name.service';
import { BitstreamDataService } from 'src/app/core/data/bitstream-data.service';
import { Item } from '../../../../core/shared/item.model';
import { ViewMode } from 'src/app/core/shared/view-mode.model';
import { getItemPageRoute } from 'src/app/item-page/item-page-routing-paths';
import { ItemSearchResult } from 'src/app/shared/object-collection/shared/item-search-result.model';
import { listableObjectComponent } from 'src/app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { TruncatableService } from 'src/app/shared/truncatable/truncatable.service';
import { SearchResultGridElementWhatsnewComponent } from '../search-result-grid-element-whatsnew.component ';

@listableObjectComponent('PublicationSearchResult', ViewMode.GridElement)
@listableObjectComponent(ItemSearchResult, ViewMode.GridElement)
@Component({
  selector: 'ds-item-search-result-grid-element-whatsnew',
  templateUrl: './item-search-result-grid-element-whatsnew.component.html',
  styleUrls: ['./item-search-result-grid-element-whatsnew.component.scss'],
})
export class ItemSearchResultGridElementWhatsnewComponent extends SearchResultGridElementWhatsnewComponent<
  ItemSearchResult,
  Item
> {
  itemPageRoute: string;

  dsoTitle: string;

  constructor(
    protected truncatableService: TruncatableService,
    protected bitstreamDataService: BitstreamDataService,
    private dsoNameService: DSONameService
  ) {
    super(truncatableService, bitstreamDataService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.itemPageRoute = getItemPageRoute(this.dso);
    this.dsoTitle = this.dsoNameService.getName(this.dso);
  }
}
