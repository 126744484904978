import { Component, OnInit } from '@angular/core';
import { Item } from 'src/app/core/shared/item.model';
import { ViewMode } from 'src/app/core/shared/view-mode.model';
import { listableObjectComponent } from '../../object-collection/shared/listable-object/listable-object.decorator';
import { AbstractListableElementComponent } from '../../object-collection/shared/object-collection-element/abstract-listable-element.component';

@listableObjectComponent('Publication', ViewMode.GridElement)
@listableObjectComponent(Item, ViewMode.GridElement)
@Component({
  selector: 'ds-item-grid-element-whatsnew',
  templateUrl: './item-grid-element-whatsnew.component.html',
  styleUrls: ['./item-grid-element-whatsnew.component.scss'],
})
export class ItemGridElementWhatsnewComponent extends AbstractListableElementComponent<Item> {}
