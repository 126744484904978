<ds-truncatable-part
  [maxLines]="1"
  [background]="isCurrent() ? 'primary' : 'default'"
  [showToggle]="false"
>
  <div
    class="my-desc"
    [ngClass]="isCurrent() ? 'text-light-item' : 'text-body'"
    [innerHTML]="
      (parentTitle$ && parentTitle$ | async)
        ? (parentTitle$ | async)
        : ('home.breadcrumbs' | translate)
    "
  ></div>
</ds-truncatable-part>
<ds-truncatable-part
  *ngIf="title"
  [maxLines]="1"
  [background]="isCurrent() ? 'primary' : 'default'"
  [showToggle]="false"
>
  <div
    class="font-weight-bold my-desc text-font-size"
    [ngClass]="isCurrent() ? 'text-light-item' : 'text-primary'"
    [innerHTML]="dsoTitle"
  ></div>
</ds-truncatable-part>
<ds-truncatable-part
  *ngIf="description"
  [maxLines]="1"
  [background]="isCurrent() ? 'primary' : 'default'"
  [showToggle]="false"
>
  <div
    class="text-secondary my-desc"
    [ngClass]="isCurrent() ? 'text-light-item' : 'text-secondary'"
    [innerHTML]="description"
  ></div>
</ds-truncatable-part>
