import {
  AUTOCOMPLETE_OFF,
  DynamicFormControlLayout,
  serializable,
} from '@ng-dynamic-forms/core';
import {
  DsDynamicInputModel,
  DsDynamicInputModelConfig,
} from '../ds-dynamic-input.model';
import { VocabularyOptions } from '../../../../../../core/submission/vocabularies/models/vocabulary-options.model';

export const DYNAMIC_FORM_CONTROL_TYPE_SCROLLABLE_DROPDOWN =
  'SCROLLABLE_DROPDOWN';

export interface DynamicScrollableDropdownModelConfig
  extends DsDynamicInputModelConfig {
  vocabularyOptions: VocabularyOptions;
  maxOptions?: number;
  value?: any;
}

export class DynamicScrollableDropdownModel extends DsDynamicInputModel {
  @serializable() maxOptions: number;
  @serializable() readonly type: string =
    DYNAMIC_FORM_CONTROL_TYPE_SCROLLABLE_DROPDOWN;

  constructor(
    config: DynamicScrollableDropdownModelConfig,
    layout?: DynamicFormControlLayout
  ) {
    super(config, layout);

    this.autoComplete = AUTOCOMPLETE_OFF;
    this.vocabularyOptions = config.vocabularyOptions;
    this.maxOptions = config.maxOptions || 10;
  }
}
