import { map, switchMap } from 'rxjs/operators';
import {
  combineLatest as observableCombineLatest,
  Observable,
  of as observableOf,
} from 'rxjs';
import { AuthorizationSearchParams } from './authorization-search-params';
import { SiteDataService } from '../site-data.service';
import { hasNoValue, hasValue, isNotEmpty } from '../../../shared/empty.util';
import { AuthService } from '../../auth/auth.service';
import { Authorization } from '../../shared/authorization.model';
import { Feature } from '../../shared/feature.model';
import { FeatureID } from './feature-id';
import { getFirstSucceededRemoteDataPayload } from '../../shared/operators';

/**
 * Operator accepting {@link AuthorizationSearchParams} and adding the current {@link Site}'s selflink to the parameter's
 * objectUrl property, if this property is empty
 * @param siteService The {@link SiteDataService} used for retrieving the repository's {@link Site}
 */
export const addSiteObjectUrlIfEmpty =
  (siteService: SiteDataService) =>
  (
    source: Observable<AuthorizationSearchParams>
  ): Observable<AuthorizationSearchParams> =>
    source.pipe(
      switchMap((params: AuthorizationSearchParams) => {
        if (hasNoValue(params.objectUrl)) {
          return siteService
            .find()
            .pipe(
              map((site) => Object.assign({}, params, { objectUrl: site.self }))
            );
        } else {
          return observableOf(params);
        }
      })
    );

/**
 * Operator accepting {@link AuthorizationSearchParams} and adding the authenticated user's uuid to the parameter's
 * ePersonUuid property, if this property is empty and an {@link EPerson} is currently authenticated
 * @param authService The {@link AuthService} used for retrieving the currently authenticated {@link EPerson}
 */
export const addAuthenticatedUserUuidIfEmpty =
  (authService: AuthService) =>
  (
    source: Observable<AuthorizationSearchParams>
  ): Observable<AuthorizationSearchParams> =>
    source.pipe(
      switchMap((params: AuthorizationSearchParams) => {
        if (hasNoValue(params.ePersonUuid)) {
          return authService.isAuthenticated().pipe(
            switchMap((authenticated) => {
              if (authenticated) {
                return authService
                  .getAuthenticatedUserFromStore()
                  .pipe(
                    map((ePerson) =>
                      Object.assign({}, params, { ePersonUuid: ePerson.uuid })
                    )
                  );
              } else {
                return observableOf(params);
              }
            })
          );
        } else {
          return observableOf(params);
        }
      })
    );

/**
 * Operator checking if at least one of the provided {@link Authorization}s contains a {@link Feature} that matches the
 * provided {@link FeatureID}
 * Note: This expects the {@link Authorization}s to contain a resolved link to their {@link Feature}. If they don't,
 * this observable will always emit false.
 * @param featureID
 * @returns true if at least one {@link Feature} matches, false if none do
 */
export const oneAuthorizationMatchesFeature =
  (featureID: FeatureID) =>
  (source: Observable<Authorization[]>): Observable<boolean> =>
    source.pipe(
      switchMap((authorizations: Authorization[]) => {
        if (isNotEmpty(authorizations)) {
          return observableCombineLatest(
            ...authorizations
              .filter((authorization: Authorization) =>
                hasValue(authorization.feature)
              )
              .map((authorization: Authorization) =>
                authorization.feature.pipe(getFirstSucceededRemoteDataPayload())
              )
          );
        } else {
          return observableOf([]);
        }
      }),
      map(
        (features: Feature[]) =>
          features.filter((feature: Feature) => feature.id === featureID)
            .length > 0
      )
    );
