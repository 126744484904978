/**
 * List of possible MenuItemTypes
 */
export enum MenuItemType {
  TEXT,
  LINK,
  ALTMETRIC,
  SEARCH,
  ONCLICK,
  EXTERNAL,
}
