<!--ecm to handle overlapping of badge-->
<div class="myecm-badge" style="width: 150px" *ngIf="submitter$ | async">
  <span class="text-muted"
    >{{ "submission.workflow.tasks.generic.submitter" | translate }} :
    <span class="badge badge-info myecm-badge-info">{{
      (submitter$ | async)?.name
    }}</span></span
  >
</div>
<!--end of code change-->
