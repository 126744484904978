<div
  *ngIf="configurationList?.length > 1"
  class="search-switch-configuration pt-1"
>
  <h5 id="configuration-switch">
    {{ "search.switch-configuration.title" | translate }}
  </h5>

  <select
    class="form-control rounded-pill"
    style="font-size: 12px"
    aria-labelledby="configuration-switch"
    [compareWith]="compare"
    [(ngModel)]="selectedOption"
    (change)="onSelect()"
  >
    <option *ngFor="let option of configurationList" [ngValue]="option">
      {{ option.label | translate }}
    </option>
  </select>
</div>
