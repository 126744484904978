<a
  *ngIf="isVisible | async"
  class="d-flex flex-row"
  [tabIndex]="-1"
  [routerLink]="[searchLink]"
  [queryParams]="addQueryParams"
  queryParamsHandling="merge"
>
  <label class="text-truncate">
    <input type="checkbox" [checked]="false" class="my-1" />
    <span class="filter-value px-1" style="font-size: 12px">
      {{
        "search.filters." + filterConfig.name + "." + filterValue.value
          | translate : { default: filterValue.value }
      }}
    </span>
  </label>
  <span class="float-right filter-value-count ml-auto">
    <span class="badge badge-pill item-badge mr-2">{{
      filterValue.count
    }}</span>
  </span>
</a>
