import { NgModule } from '@angular/core';
import { RouterModule, NoPreloading } from '@angular/router';
import { AuthBlockingGuard } from './core/auth/auth-blocking.guard';

import { AuthenticatedGuard } from './core/auth/authenticated.guard';
import { SiteAdministratorGuard } from './core/data/feature-authorization/feature-authorization-guard/site-administrator.guard';
import {
  ACCESS_CONTROL_MODULE_PATH,
  ADMIN_MODULE_PATH,
  BITSTREAM_MODULE_PATH,
  ERROR_PAGE,
  FORBIDDEN_PATH,
  FORGOT_PASSWORD_PATH,
  HEALTH_PAGE_PATH,
  INFO_MODULE_PATH,
  INTERNAL_SERVER_ERROR,
  LEGACY_BITSTREAM_MODULE_PATH,
  PROFILE_MODULE_PATH,
  REGISTER_PATH,
  REQUEST_COPY_MODULE_PATH,
  WORKFLOW_ITEM_MODULE_PATH,
} from './app-routing-paths';
import { COLLECTION_MODULE_PATH } from './collection-page/collection-page-routing-paths';
import { COMMUNITY_MODULE_PATH } from './community-page/community-page-routing-paths';
import { ITEM_MODULE_PATH } from './item-page/item-page-routing-paths';
import { PROCESS_MODULE_PATH } from './process-page/process-page-routing.paths';
import { ReloadGuard } from './core/reload/reload.guard';
import { EndUserAgreementCurrentUserGuard } from './core/end-user-agreement/end-user-agreement-current-user.guard';
import { SiteRegisterGuard } from './core/data/feature-authorization/feature-authorization-guard/site-register.guard';
import { ThemedPageNotFoundComponent } from './pagenotfound/themed-pagenotfound.component';
import { ThemedForbiddenComponent } from './forbidden/themed-forbidden.component';
import { GroupAdministratorGuard } from './core/data/feature-authorization/feature-authorization-guard/group-administrator.guard';
import { ThemedPageInternalServerErrorComponent } from './page-internal-server-error/themed-page-internal-server-error.component';
import { ServerCheckGuard } from './core/server-check/server-check.guard';
import { MenuResolver } from './menu.resolver';
import { ThemedPageErrorComponent } from './page-error/themed-page-error.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { EkmAdministratorGuard } from './core/data/feature-authorization/feature-authorization-guard/ekm-admin.guard';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: INTERNAL_SERVER_ERROR,
          component: ThemedPageInternalServerErrorComponent,
        },
        { path: ERROR_PAGE, component: ThemedPageErrorComponent },
        {
          path: '',
          canActivate: [AuthBlockingGuard],
          canActivateChild: [ServerCheckGuard],
          resolve: [MenuResolver],
          children: [
            { path: '', redirectTo: '/hackathon', pathMatch: 'full' }, //changed from home to login for EKM
            {
              path: 'reload/:rnd',
              component: ThemedPageNotFoundComponent,
              pathMatch: 'full',
              canActivate: [ReloadGuard],
            },
            {
              path: 'kjks',
              loadChildren: () =>
                import('./home-page/home-page.module').then(
                  (m) => m.HomePageModule
                ),
              data: { showBreadcrumbs: false },
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'hjguy',
              loadChildren: () =>
                import('./analytics/analytics.module').then(
                  (m) => m.AnalyticsModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                SiteAdministratorGuard,
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'rytk',
              loadChildren: () =>
                import('./about-us/about-us.module').then(
                  (m) => m.AboutUsModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'jhfh',
              loadChildren: () =>
                import('./vertical/vertical.module').then(
                  (m) => m.VerticalModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'jfjf',
              loadChildren: () =>
                import('./event/event.module').then((m) => m.EventModule),
              //data: { showBreadcrumbs: true },
              canActivate: [
               // EkmAdministratorGuard,
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'hackathon',
              loadChildren: () =>
                import('./hackathon/hackathon.module').then(
                  (m) => m.HackathonModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                // SiteAdministratorGuard,
                // AuthenticatedGuard,
                // EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'jhgjh',
              loadChildren: () =>
                import('./viewform/viewform.module').then(
                  (m) => m.ViewformModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                // SiteAdministratorGuard,
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'hjhk',
              loadChildren: () =>
                import('./announcement/announcement.module').then(
                  (m) => m.AnnouncementModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                // EkmAdministratorGuard,
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'ugiu',
              loadChildren: () =>
                import('./cafemenu/cafemenu.module').then(
                  (m) => m.CafemenuModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                // EkmAdministratorGuard,
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'uihiuh',
              loadChildren: () =>
                import('./contactus/contactus.module').then(
                  (m) => m.ContactusModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'guigiu',
              loadChildren: () =>
                import('./aria-form/ariaform.module').then(
                  (m) => m.AriaformModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                SiteAdministratorGuard,
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'giuhi',
              loadChildren: () =>
                import('./training-feedback/training-feedback.module').then(
                  (m) => m.TrainingFeedbackModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                //SiteAdministratorGuard,
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'gijgiu',
              loadChildren: () =>
                import('./news/news.module').then((m) => m.NewsModule),
              //data: { showBreadcrumbs: true },
              canActivate: [
                // EkmAdministratorGuard,
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'fuyfu',
              loadChildren: () =>
                import('./event/event.module').then((m) => m.EventModule),
              //data: { showBreadcrumbs: true },
              canActivate: [
                SiteAdministratorGuard,
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'hghg',
              loadChildren: () =>
                import('./admin-mis/admin-mis.module').then(
                  (m) => m.AdminMisModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                SiteAdministratorGuard,
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'guhjgh',
              loadChildren: () =>
                import('./announcement/announcement.module').then(
                  (m) => m.AnnouncementModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                SiteAdministratorGuard,
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'uuygu',
              loadChildren: () =>
                import('./contactus/contactus.module').then(
                  (m) => m.ContactusModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                SiteAdministratorGuard,
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'yutuy',
              loadChildren: () =>
                import('./aria-form/ariaform.module').then(
                  (m) => m.AriaformModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                SiteAdministratorGuard,
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'jhdj',
              loadChildren: () =>
                import('./news/news.module').then((m) => m.NewsModule),
              //data: { showBreadcrumbs: true },
              canActivate: [
                EkmAdministratorGuard,
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'hjdjd',
              loadChildren: () =>
                import('./profile/profile.module').then((m) => m.ProfileModule),
              //data: { showBreadcrumbs: true },
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'jsjjh',
              loadChildren: () =>
                import('./aria/aria.module').then((m) => m.AriaModule),
              //data: { showBreadcrumbs: true },
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'jhsjsh',
              loadChildren: () =>
                import('./project/project.module').then((m) => m.ProjectModule),
              //data: { showBreadcrumbs: true },
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'jhsjs',
              loadChildren: () =>
                import('./coe/coe-routing.module').then(
                  (m) => m.CoeRoutingModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'jhsjsj',
              loadChildren: () =>
                import('./coe-see-all/coeseeAll-routing.module').then(
                  (m) => m.CoeSeeAllRoutingModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'uihius',
              loadChildren: () =>
                import('./coedetail/coedetail-routing.module').then(
                  (m) => m.CoeDetailRoutingModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'jshjs',
              loadChildren: () =>
                import('./userprofile/userprofile.module').then(
                  (m) => m.UserProfileModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'sijsi',
              loadChildren: () =>
                import('./projectdetails/projectdetails.module').then(
                  (m) => m.ProjectDetailsModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'jsjsj',
              loadChildren: () =>
                import('./underconstruction/underconstruction.module').then(
                  (m) => m.UnderConstructionModule
                ),
              //data: { showBreadcrumbs: true },
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },

            {
              path: 'uuisjh',
              loadChildren: () =>
                import('./community-list-page/community-list-page.module').then(
                  (m) => m.CommunityListPageModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'jjsnj',
              loadChildren: () =>
                import('./community-list-page/community-list-page.module').then(
                  (m) => m.CommunityListPageModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'hjjjj',
              loadChildren: () =>
                import('./lookup-by-id/lookup-by-id.module').then(
                  (m) => m.LookupIdModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'hhhjss',
              loadChildren: () =>
                import('./lookup-by-id/lookup-by-id.module').then(
                  (m) => m.LookupIdModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: REGISTER_PATH,
              loadChildren: () =>
                import('./register-page/register-page.module').then(
                  (m) => m.RegisterPageModule
                ),
              canActivate: [AuthenticatedGuard, SiteRegisterGuard],
            },
            {
              path: FORGOT_PASSWORD_PATH,
              loadChildren: () =>
                import('./forgot-password/forgot-password.module').then(
                  (m) => m.ForgotPasswordModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: COMMUNITY_MODULE_PATH,
              loadChildren: () =>
                import('./community-page/community-page.module').then(
                  (m) => m.CommunityPageModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: COLLECTION_MODULE_PATH,
              loadChildren: () =>
                import('./collection-page/collection-page.module').then(
                  (m) => m.CollectionPageModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: ITEM_MODULE_PATH,
              loadChildren: () =>
                import('./item-page/item-page.module').then(
                  (m) => m.ItemPageModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'entities/:entity-type',
              loadChildren: () =>
                import('./item-page/item-page.module').then(
                  (m) => m.ItemPageModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: LEGACY_BITSTREAM_MODULE_PATH,
              loadChildren: () =>
                import('./bitstream-page/bitstream-page.module').then(
                  (m) => m.BitstreamPageModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: BITSTREAM_MODULE_PATH,
              loadChildren: () =>
                import('./bitstream-page/bitstream-page.module').then(
                  (m) => m.BitstreamPageModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'usuiusi',
              loadChildren: () =>
                import('./my-dspace-page/my-dspace-page.module').then(
                  (m) => m.MyDSpacePageModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'ususu',
              loadChildren: () =>
                import('./search-page/search-page-routing.module').then(
                  (m) => m.SearchPageRoutingModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'ususu',
              loadChildren: () =>
                import('./browse-by/browse-by-page.module').then(
                  (m) => m.BrowseByPageModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: ADMIN_MODULE_PATH,
              loadChildren: () =>
                import('./admin/admin.module').then((m) => m.AdminModule),
              canActivate: [
                SiteAdministratorGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'ususjh',
              loadChildren: () =>
                import('./login-page/login-page.module').then(
                  (m) => m.LoginPageModule
                ),
            },
            {
              path: 'hsuys',
              loadChildren: () =>
                import('./logout-page/logout-page.module').then(
                  (m) => m.LogoutPageModule
                ),
            },
            {
              path: 'jhjii',
              loadChildren: () =>
                import('./submit-page/submit-page.module').then(
                  (m) => m.SubmitPageModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'huii',
              loadChildren: () =>
                import(
                  './import-external-page/import-external-page.module'
                ).then((m) => m.ImportExternalPageModule),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: 'hijkjk',
              loadChildren: () =>
                import(
                  './workspaceitems-edit-page/workspaceitems-edit-page.module'
                ).then((m) => m.WorkspaceitemsEditPageModule),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: WORKFLOW_ITEM_MODULE_PATH,
              loadChildren: () =>
                import(
                  './workflowitems-edit-page/workflowitems-edit-page.module'
                ).then((m) => m.WorkflowItemsEditPageModule),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: PROFILE_MODULE_PATH,
              loadChildren: () =>
                import('./profile-page/profile-page.module').then(
                  (m) => m.ProfilePageModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: PROCESS_MODULE_PATH,
              loadChildren: () =>
                import('./process-page/process-page.module').then(
                  (m) => m.ProcessPageModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: INFO_MODULE_PATH,
              loadChildren: () =>
                import('./info/info.module').then((m) => m.InfoModule),
            },
            {
              path: REQUEST_COPY_MODULE_PATH,
              loadChildren: () =>
                import('./request-copy/request-copy.module').then(
                  (m) => m.RequestCopyModule
                ),
              canActivate: [
                AuthenticatedGuard,
                EndUserAgreementCurrentUserGuard,
              ],
            },
            {
              path: FORBIDDEN_PATH,
              component: ThemedForbiddenComponent,
            },
            {
              path: 'huiui',
              loadChildren: () =>
                import('./statistics-page/statistics-page-routing.module').then(
                  (m) => m.StatisticsPageRoutingModule
                ),
            },
            {
              path: HEALTH_PAGE_PATH,
              loadChildren: () =>
                import('./health-page/health-page.module').then(
                  (m) => m.HealthPageModule
                ),
            },
            {
              path: ACCESS_CONTROL_MODULE_PATH,
              loadChildren: () =>
                import('./access-control/access-control.module').then(
                  (m) => m.AccessControlModule
                ),
              canActivate: [GroupAdministratorGuard],
            },
            {
              path: '**',
              pathMatch: 'full',
              component: ThemedPageNotFoundComponent,
            },
          ],
        },
      ],
      {
        // enableTracing: true,
        useHash: false,
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        initialNavigation: 'enabledBlocking',
        preloadingStrategy: NoPreloading,
        onSameUrlNavigation: 'reload',
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
