<!--<div class="card">
  <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/collections/', object.id]" class="card-img-top" [attr.title]="'search.results.view-result' | translate">
    <ds-thumbnail [thumbnail]="(object.logo | async)?.payload" [limitWidth]="false">
    </ds-thumbnail>
  </a>
  <span *ngIf="linkType == linkTypes.None" class="card-img-top">
    <ds-thumbnail [thumbnail]="(object.logo | async)?.payload" [limitWidth]="false">
    </ds-thumbnail>
  </span>
  <div class="card-body">
    <h4 class="card-title">{{object.name}}</h4>
    <p *ngIf="object.shortDescription" class="card-text">{{object.shortDescription}}</p>
    <div *ngIf="linkType != linkTypes.None" class="text-center">
      <a [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/collections/', object.id]" class="lead btn btn-primary viewButton">{{ 'search.results.view-result' | translate}}</a>
    </div>
  </div>
</div>-->

<!-- <div class="card"> -->
<div>
  <div [ngbTooltip]="object.name" [routerLink]="['/collections/' + object.id]">
    <!-- <span class="badge badge-collection1">Collection</span> -->
    <div
      class="row lg-3 md-2 align-items-center grid-card"
      style="height: 90px"
    >
      <div class="col-auto">
        <div class="icon-container">
          <img loading="lazy" class="small-img" src="assets/images/commu-icon.svg" />
        </div>
      </div>
      <div class="col community-overflow pl-0" style="position: relative">
        <span class="badge badge-collection1">{{
          "batch.collection" | translate
        }}</span>
        <h4
          class="card-title mb-0 community-card-heading"
          [routerLink]="['/collections/' + object.id]"
        >
          {{ object.name }}
        </h4>
        <div
          *ngIf="object.shortDescription"
          class="com-md abstract-text text-truncate"
        >
          {{ object.shortDescription }}
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
<!-- <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/collections/' + object.id]" class="lead">
  {{object.name}}
</a> 
<span *ngIf="linkType == linkTypes.None" class="lead">
  {{object.name}}
</span>-->
