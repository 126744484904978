import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Bitstream } from '../../core/shared/bitstream.model';
import {
  getBitstreamDownloadRoute,
  getBitstreamDownloadRouteforDownload,
  getBitstreamRequestACopyRoute,
} from '../../app-routing-paths';
import { AuthorizationDataService } from '../../core/data/feature-authorization/authorization-data.service';
import { FeatureID } from '../../core/data/feature-authorization/feature-id';
import { hasValue, isNotEmpty } from '../empty.util';
import { map } from 'rxjs/operators';
import {
  of as observableOf,
  combineLatest as observableCombineLatest,
  Observable,
} from 'rxjs';
import { Item } from '../../core/shared/item.model';

@Component({
  selector: 'ds-file-download-link',
  templateUrl: './file-download-link.component.html',
  styleUrls: ['./file-download-link.component.scss'],
})
/**
 * Component displaying a download link
 * When the user is authenticated, a short-lived token retrieved from the REST API is added to the download link,
 * ensuring the user is authorized to download the file.
 */
export class FileDownloadLinkComponent implements OnInit, OnChanges {
  /**
   * Optional bitstream instead of href and file name
   */
  @Input() bitstream: Bitstream;

  @Input() item: Item;

  @Input() filenames: any;
  /**
   * Additional css classes to apply to link
   */
  @Input() cssClasses = '';

  /**
   * A boolean representing if link is shown in same tab or in a new one.
   */
  @Input() isBlank = false;

  @Input() enableRequestACopy = true;

  bitstreamPath$: Observable<{
    routerLink: string;
    queryParams: any;
  }>;

  canDownload$: Observable<boolean>;

  targettype: string = '_self';
  extension: any;

  constructor(private authorizationService: AuthorizationDataService) {}
  ngOnChanges(changes: SimpleChanges): void {
    let bits: Bitstream = changes.bitstream.currentValue;
    //alert(bits.name);
    //if ( ( hasValue(bits) && hasValue(bits.name) && bits.name.includes(".pdf") ) || ( hasValue(this.filenames) && this.filenames.includes(".pdf") ) )
    if (hasValue(bits) && hasValue(bits.name) && bits.name.includes('.pdf')) {
      this.targettype = '_blank';
    }
  }

  ngOnInit() {

//     console.log(this.bitstream.name)
//     let ext = null;
//     ext = this.bitstream.name.split('.')[1];
// console.log(ext)

//    if(ext=='docx'){
// {icon: "fa fa-file-excel-o"}
//    }

// let logo:any=this.getFileExtension(this.bitstream)
//   this.extension = logo;
// console.log(logo)
let ext = null;
ext = this.bitstream.name.split('.')[1];
this.extension = ext;

    if (this.enableRequestACopy) {
      this.canDownload$ = this.authorizationService.isAuthorized(
        FeatureID.CanDownload,
        isNotEmpty(this.bitstream) ? this.bitstream.self : undefined
      );
      const canRequestACopy$ = this.authorizationService.isAuthorized(
        FeatureID.CanRequestACopy,
        isNotEmpty(this.bitstream) ? this.bitstream.self : undefined
      );
      this.bitstreamPath$ = observableCombineLatest([
        this.canDownload$,
        canRequestACopy$,
      ]).pipe(
        map(([canDownload, canRequestACopy]) =>
          this.getBitstreamPath(canDownload, canRequestACopy)
        )
      );
      // console.log("path",this.bitstreamPath$);
    } else {
      this.bitstreamPath$ = observableOf(this.getBitstreamDownloadPath());
      // console.log("!!!!!!!!",this.bitstreamPath$);
      this.canDownload$ = observableOf(true);
    }
  }

  getBitstreamPath(canDownload: boolean, canRequestACopy: boolean) {
    if (!canDownload && canRequestACopy && hasValue(this.item)) {
      return getBitstreamRequestACopyRoute(this.item, this.bitstream);
    }
    return this.getBitstreamDownloadPath();
  }

  getBitstreamDownloadPath() {
    return {
      routerLink: getBitstreamDownloadRouteforDownload(this.bitstream),
      queryParams: {},
    };
  }
  getFileimage(){
    let newExt = this.extension?.toLowerCase();
    if (newExt?.endsWith('pdf')) {
      return 'assets/images/rep_Pdf_icon.svg';
    } else if (newExt.endsWith('docx') || newExt.endsWith('.doc')) {
      return 'assets/images/rep_word_icon.svg';
    } else if (newExt.endsWith('pptx') || newExt.endsWith('ppt')) {
      return 'assets/images/ppt-icon.svg';
    } else if (newExt.endsWith('mpg')) {
      return 'assets/images/MPG.png';
    } else if (newExt.endsWith('zip')) {
      return 'assets/images/zipfile.png';
    } else if (newExt.endsWith('xls') || newExt.endsWith('xlsx')) {
      return 'assets/images/rep_excel_icon.svg';
    } else if (newExt.endsWith('txt') || newExt.endsWith('xml')) {
      return 'assets/images/new_file_icon.svg';
    } else if (
      newExt.endsWith('png') ||
      newExt.endsWith('jpg') ||
      newExt.endsWith('jpeg')
    ) {
      return 'assets/images/rep_image_icon.svg';
    } else {
      return 'assets/images/Default icon.svg';
    }
  }
}
