<div style="position: relative">
  <div class="mt-2">
    <!-- <div class="card community-card" data-bs-toggle="tooltip" data-bs-placement="top" title="{{object.name}}" data-bs-title="Tooltip on top"> -->
    <!-- <span style="padding-bottom: 3px;" class="badge badge-collection4">Sub Community</span> -->
    <div class="row lg-3 md-2 align-items-center">
      <div class="col-auto">
        <div class="icon-container">
          <img loading="lazy" class="small-img" src="assets/images/commu-icon.svg" />
        </div>
      </div>
      <div
        class="col community-overflow pl-0"
        style="position: relative"
        *ngIf="linkType != linkTypes.None"
      >
        <span class="badge badge-collection4 mb-1">{{
          "batch.subcommunity" | translate
        }}</span>
        <h4
          class="card-title mb-0 community-card-heading"
          [routerLink]="['/communities/' + object.id]"
        >
          {{ object.name }}
        </h4>
      </div>
      <div
        class="col community-overflow pl-0"
        style="position: relative"
        *ngIf="linkType == linkTypes.None"
      >
        <span class="badge badge-collection4 mb-1">{{
          "batch.subcommunity" | translate
        }}</span>
        <h4 class="card-title mb-0 community-card-heading">
          {{ object.name }}
        </h4>
      </div>
    </div>
    <!-- </div> -->
  </div>
</div>

<!--<a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/communities/' + object.id]" class="lead">
    {{object.name}}
</a>
<span *ngIf="linkType == linkTypes.None" class="lead">
    {{object.name}}
</span>-->
<!-- <div *ngIf="object.shortDescription" class="text-muted abstract-text">
    {{object.shortDescription}}
</div> -->
