<div>
  <div class="vertical-box">
    <div class="navigation-menu">
      <ul
        class="nav nav-pills middle-box-events middle-box-color"
        id="pills-tab-vertical"
        role="tablist"
      >
        <li
          class="nav-item"
          role="presentation"
          *ngFor="let coe of codeList | keyvalue"
        >
          <div
            class="nav-link"
            [class]="coeId == coe.key ? 'active' : ''"
            id="pills-pm-tab"
            data-toggle="pill"
            data-target="#pills-pm"
            role="tab"
            aria-controls="pills-pm"
            aria-selected="true"
            (click)="onCoeClick(coe.key)"
          >
            {{ coe.value.title }}
          </div>
        </li>
      </ul>
      <div class="tab-content" id="pills-tab-vertical-content">
        <div
          class="tab-pane fade show active multiple-events"
          id="pills-pm"
          aria-labelledby="pills-pm-tab"
        >
          <div class="business-units" *ngIf="this.deptList.length != 0">
            <div *ngFor="let dept of deptList | keyvalue">
              <div
                class="bu-box text-truncate"
                [class]="deptId == dept.key ? 'active' : ''"
                (click)="myFunction(dept.key)"
              >
                {{ dept.value.title }}
              </div>
            </div>
          </div>
          <div>
            <div class="card-box user-detail bu-banner">
              <img
                loading="lazy"
                src="assets/images/Group 10124.png"
                alt="banner"
              />
              <div class="profile-data">
                <!-- <div class="welcome-icon"><img src="assets/images/asset1.png" loading="lazy" alt="user-img"></div> -->
                <p class="user-name m-auto w-auto">
                  <b class="user-desc p-0">{{ this.depTitle }}</b>
                </p>
              </div>
            </div>
            <div class="vertical-content">
              <div class="card-box content">
                <div class="event-card">
                  <div class="eve-heading">About</div>
                  <p
                    [ngClass]="
                      isReadMore
                        ? 'blog-info read-more'
                        : 'blog-info about-info'
                    "
                    style="white-space: pre-line"
                  >
                    {{ this.depAboutUs }}
                  </p>
                  <p
                    *ngIf="showReadMoreButton"
                    (click)="readMore()"
                    class="read-text"
                    style="white-space: pre-line"
                  >
                    {{ !isReadMore ? "Read more" : "Read less" }}
                  </p>
                  <div *ngIf="this.deptId == 'dep1' && this.coeId == 'coe10'" class="ul-templates">
                    EA CoE Distribution List: <a href="mailto:ReBIT_PMV_EA_COE@rebit.org.in" target="_blank" class="link-color">ReBIT_pmv_ea_coe@ReBIT.org.in</a> (*Note: includes chain of command upto CEO)
                  </div>
                  <div *ngIf="this.deptId != 'dep1' && this.coeId == 'coe10'">
                    <div class="heading-templates">Reference Documents</div>
                    <ul class="ul-templates">
                    <li class="li-templates">
                      Standardised Templates
                    <a href="assets/documents/2024-07-23 EA templates for circulation.pptx" download="2024-07-23 EA templates for circulation.pptx"> (Click here to download)</a>
                  </li>
                 </ul>
                </div>
                </div>
              </div>
              <div class="card-box bx-shadow">
                <div class="projects">
                  <div class="eve-heading">
                    <span>Initiatives</span
                    ><a
                      *ngIf="initiativeList"
                      routerLink="/coeSeeAll/{{ coeId }}/{{ deptId }}"
                      class="see-all"
                      >See all</a
                    >
                  </div>
                   <div class="project-slider project-width">
                     <ng-container *ngFor="let initiative of initiativeList;index as i"> 
                      <div
                      class="pro-card p-0" *ngIf="initiative != null;"
                      style="cursor: pointer"
                      routerLink="/coedetail/{{ coeId }}/{{ deptId }}/{{
                        'details'
                      }}"
                      (click)="goToCoeDtlPage(initiative)"
                    >
                      <div class="bg-one">
                        <img
                          loading="lazy"
                          src="assets/images/mask.png"
                          alt="square"
                        />
                      </div>
                      <div class="prj-details">
                        <p
                          class="eve-heading"
                          [ngbTooltip]="initiative"
                        >
                          {{ initiative }}
                        </p>
                        <!-- <p class="card-descript">
                          {{ initiative.value.description }}
                        </p> -->
                      </div>
                    </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="card-box bx-shadow">
                <div class="event-card resource">
                  <div class="eve-heading">Team</div>

                  <div class="tab-content" id="pills-user-box">
                    <div class="user-box">
                      <div
                        class="user-detail col-md-3 p-0"
                        *ngFor="let user of teamsList"
                      >
                      <ng-container *ngIf="user != null">
                          <img
                            loading="lazy"
                            src="assets/images/User.svg"
                            alt="user"
                          />
                          <a
                            class="profile-name"
                            href="profile/{{ user?.employeeId }}"
                            ><span>{{ user?.employeeName }}</span></a
                          >
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
