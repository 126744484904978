<!-- <a [routerLink]="(bitstreamPath$| async)?.routerLink" class="dont-break-out link" [queryParams]="(bitstreamPath$| async)?.queryParams" [target]="isBlank ? '_blank': '_self'" [ngClass]="cssClasses"> -->
  <div class="mb-2 flex-item">
    <img [src]="getFileimage()"
     class="mr-3 cur-ptr" style="height: auto;width: 20px;" />
  <a
  [routerLink]="(bitstreamPath$ | async)?.routerLink"
  class="dont-break-out link p-data"
  [queryParams]="(bitstreamPath$ | async)?.queryParams"
  [target]="targettype"
  [ngClass]="cssClasses"
>
  <span *ngIf="!(canDownload$ | async)"><i class="fas fa-lock"></i></span>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
</div>
