import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HomeNewsComponent as BaseComponent } from '../../../../../app/home-page/home-news/home-news.component';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ds-home-news',
  styleUrls: ['./home-news.component.scss'],
  templateUrl: './home-news.component.html',
})

/**
 * Component to render the news section on the home page
 */
export class HomeNewsComponent extends BaseComponent implements OnInit {
  private socket: any;
  section: any = 'events';
  baseUrl: string = environment.rest.baseUrl;
  eventData: any = [];
  newsData: any = [];
  announcementData: any = [];
  view: string = 'events';
  isReadMore = false;
  readMoreIndex = -1;
  choosenyear:number = 2024; 
  showReadMoreButton = true;

  constructor(
    private notifificationService: NotificationsService,
    private changeDetectionRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.notifificationService.getFilteredEventList().subscribe({
      next: (res) => {
        let arr = res.payload;

        this.eventData = [];
        for (let i in arr) {
          this.eventData.push(arr[i]);
        }
        this.changeDetectionRef.detectChanges();
      },
      error: () => { },
    });
    this.notifificationService.getFilteredNewsList().subscribe({
      next: (res) => {
        let arr = res.payload;

        this.newsData = [];
        for (let i in arr) {
          this.newsData.push(arr[i]);
        }
        this.changeDetectionRef.detectChanges();
      },
      error: () => { },
    });
    this.notifificationService.getFilteredAnnouncementList().subscribe({
      next: (res) => {
        let arr = res.payload;

        this.announcementData = [];
        for (let i in arr) {
          this.announcementData.push(arr[i]);
        }
        this.changeDetectionRef.detectChanges();
      },
      error: () => { },
    });
    // for (let i = 0; i < this.eventData.length; i++) {
    //   let arr = this.eventData[i]['desc'].split(' ', 34);
    //   if (arr.length == 34) {
    //     this.eventData[i]['shortDesc'] = arr.join(' ') + '...';
    //     this.eventData[i]['readMore'] = true;
    //   } else {
    //     this.eventData[i]['shortDesc'] = this.eventData[i]['desc'];
    //   }
    // }
  }

  year(event){
    // console.log(event.target.value);
    this.choosenyear = +event.target.value;
  }

  readMore(index: number) {
    if (index === this.readMoreIndex) {
      this.isReadMore = false;
      this.readMoreIndex = -1
    } else {
      this.isReadMore = true;
      this.readMoreIndex = index
    }
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 600,
    navText: ['&#8249', '›'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      760: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
    nav: true,
  };

  sections(value) { this.section = value; }
  changeSections(viewType: string) {
    this.view = viewType;
    this.isReadMore = false;
    this.readMoreIndex = -1;
  }
}
