<div class="flex-item mb-2">
  <div *ngIf="showThumbnails" class="">
    <!-- <a
      *ngIf="linkType != linkTypes.None"
      [target]="linkType == linkTypes.ExternalLink ? '_blank' : '_self'"
      rel="noopener noreferrer"
      class="dont-break-out"
    >
      <ds-thumbnail
        [thumbnail]="dso?.thumbnail | async"
        [limitWidth]="true"
        [routerLink]="[itemPageRoute]"
      >
      </ds-thumbnail>
    </a>
    <span *ngIf="linkType == linkTypes.None" class="dont-break-out">
      <ds-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="true">
      </ds-thumbnail>
    </span> -->
    <img
      loading="lazy"
      class="mr-2"
      src="assets/images/rep_folder_icon.svg"
      style="max-width: unset"
    />
  </div>
  <div [ngClass]="showThumbnails ? 'col-9 myecm-grid pl-0' : 'col-md-12'">
    <!-- <div class="d-flex" style="line-height: 1">
      <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
      <ds-access-status-badge
        [item]="dso"
        class="pl-1"
      ></ds-access-status-badge>
    </div> -->

    <ds-truncatable
      [id]="dso.id"
      *ngIf="object !== undefined && object !== null"
    >
      <a
        *ngIf="linkType != linkTypes.None"
        [target]="linkType == linkTypes.ExternalLink ? '_blank' : '_self'"
        rel="noopener noreferrer"
        [routerLink]="[itemPageRoute]"
        class="lead item-list-title align-heading"
        >{{ dsoTitle }}</a
      >
      <span
        *ngIf="linkType == linkTypes.None"
        class="text-truncate d-block"
        [routerLink]="[itemPageRoute]"
        >{{ dsoTitle }}</span
      >
      <!-- <span class="text-muted">
        <ds-truncatable-part [id]="dso.id" [minLines]="1">
          <div class="item-info com-md text-truncate">
            <ng-container
              *ngIf="
                dso.firstMetadataValue('dc.publisher') ||
                dso.firstMetadataValue('dc.date.issued')
              "
            >
              (<span
                *ngIf="dso.firstMetadataValue('dc.publisher')"
                class="item-list-publisher"
                >{{ firstMetadataValue("dc.publisher") + ", " }}</span
              >
              <span
                *ngIf="dso.firstMetadataValue('dc.date.issued')"
                class="item-list-date"
                >{{ firstMetadataValue("dc.date.issued") }}</span
              >)
            </ng-container>
            <span
              *ngIf="
                dso.allMetadata([
                  'dc.contributor.*',
                  'dc.creator',
                  'dc.contributor.author'
                ]).length > 0
              "
              class="item-list-authors"
            >
              <span
                *ngFor="
                  let author of allMetadataValues([
                    'dc.contributor.*',
                    'dc.creator',
                    'dc.contributor.author'
                  ]);
                  let last = last
                "
              >
                <span [innerHTML]="author"></span>
                <span *ngIf="!last">; </span>
              </span>
            </span>
          </div>
        </ds-truncatable-part>
      </span>

      <div
        *ngIf="dso.firstMetadataValue('dc.description.abstract')"
        class="item-list-abstract"
      >
        <ds-truncatable-part [id]="dso.id" [minLines]="3"
          ><span
            class="item-info d-block text-truncate"
            [innerHTML]="firstMetadataValue('dc.description.abstract')"
          ></span>
        </ds-truncatable-part>
      </div> -->
    </ds-truncatable>
  </div>
</div>
