import { FieldParser } from './field-parser';
import { DynamicFormControlLayout } from '@ng-dynamic-forms/core';
import { FormFieldMetadataValueObject } from '../models/form-field-metadata-value.model';
import {
  DsDynamicTextAreaModel,
  DsDynamicTextAreaModelConfig,
} from '../ds-dynamic-form-ui/models/ds-dynamic-textarea.model';

export class TextareaFieldParser extends FieldParser {
  public modelFactory(
    fieldValue?: FormFieldMetadataValueObject | any,
    label?: boolean
  ): any {
    const textAreaModelConfig: DsDynamicTextAreaModelConfig = this.initModel(
      null,
      label
    );

    let layout: DynamicFormControlLayout;

    layout = {
      element: {
        label: 'col-form-label',
      },
    };

    textAreaModelConfig.rows = 10;
    this.setValues(textAreaModelConfig, fieldValue);
    const textAreaModel = new DsDynamicTextAreaModel(
      textAreaModelConfig,
      layout
    );

    return textAreaModel;
  }
}
