<table id="metadata" class="table table-striped table-hover ecm-table">
  <!-- <thead>
    <tr>
      <th scope="col">{{ "item.edit.modify.overview.field" | translate }}</th>
      <th scope="col">{{ "item.edit.modify.overview.value" | translate }}</th>
      <th scope="col">
        {{ "item.edit.modify.overview.language" | translate }}
      </th>
    </tr>
  </thead> -->
  <tbody>
    <ng-container *ngFor="let mdEntry of metadata | keyvalue">
      <div *ngIf="mdEntry.key === 'dc.title'">
        <tr *ngFor="let mdValue of mdEntry.value" class="metadata-row">
          <!-- <td>{{ mdEntry.key }}</td> -->
          <td>{{ mdValue.value }}</td>
          <!-- <td>{{ mdValue.language }}</td> -->
        </tr>
      </div>
    </ng-container>
  </tbody>
</table>
