<!--adms no thumbnail show-->
<div class="row list-item-preview-class">
  <div *ngIf="showThumbnails" class="col-auto thumbnail-col">
    <ds-thumbnail
      [thumbnail]="item?.thumbnail | async"
      [limitWidth]="true"
      [routerLink]="[itemPageRoute]"
    >
    </ds-thumbnail>
  </div>
  <div [ngClass]="showThumbnails ? 'col-auto item-list-details' : 'col-md-12'">
    <div class="grid-card workflow-task" *ngIf="item" @fadeInOut>
      <ng-container *ngIf="status">
        <ds-mydspace-item-status [status]="status"></ds-mydspace-item-status>
      </ng-container>
      <div class="d-flex">
        <ds-type-badge [object]="item"></ds-type-badge>
        <ds-access-status-badge
          [item]="item"
          class="pl-1"
        ></ds-access-status-badge>
      </div>
      <ds-truncatable [id]="item.id">
        <h5
          [ngClass]="{
            'lead align-heading text-truncate': true,
            'text-muted': !item.firstMetadataValue('dc.title')
          }"
          [routerLink]="[itemPageRoute]"
          class="mb-0"
        >
          {{
            item.firstMetadataValue("dc.title") ||
              ("mydspace.results.no-title" | translate)
          }}
        </h5>

        <div>
          <span class="text-muted">
            <ds-truncatable-part [id]="item.id" [minLines]="1">
              (<span
                *ngIf="item.hasMetadata('dc.publisher')"
                class="item-list-publisher align-heading"
                >{{ item.firstMetadataValue("dc.publisher") + ", " }}</span
              >
              <span class="item-list-date">{{
                item.firstMetadataValue("dc.date.issued") ||
                  ("mydspace.results.no-date" | translate)
              }}</span
              >)
              <span
                *ngIf="
                  item.hasMetadata([
                    'dc.contributor.author',
                    'dc.creator',
                    'dc.contributor.*'
                  ])
                "
                class="item-list-authors d-block text-truncate"
              >
                <span
                  *ngIf="
                    item.allMetadataValues([
                      'dc.contributor.author',
                      'dc.creator',
                      'dc.contributor.*'
                    ]).length === 0
                  "
                  >{{ "mydspace.results.no-authors" | translate }}</span
                >
                <span
                  *ngFor="
                    let author of item.allMetadataValues([
                      'dc.contributor.author',
                      'dc.creator',
                      'dc.contributor.*'
                    ]);
                    let last = last
                  "
                >
                  <span
                    >{{ author }}
                    <!-- <span >{{author}}</span> -->
                  </span>
                </span>
              </span>
            </ds-truncatable-part>
          </span>

          <ds-truncatable-part
            [id]="item.id"
            [minLines]="1"
            class="item-list-abstract text-muted align-heading text-truncate d-block"
          >
            <span
              [ngClass]="{
                'text-muted': !item.firstMetadataValue(
                  'dc.description.abstract'
                )
              }"
              >{{
                item.firstMetadataValue("dc.description.abstract") ||
                  ("mydspace.results.no-abstract" | translate)
              }}</span
            >
          </ds-truncatable-part>
        </div>
      </ds-truncatable>
      <ds-item-submitter
        *ngIf="showSubmitter"
        [object]="object.indexableObject"
      ></ds-item-submitter>
    </div>
  </div>
</div>
<!--adms end of no thumbnail show-->
