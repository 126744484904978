<form
  #form="ngForm"
  (ngSubmit)="onSubmit(currentObject)"
  [action]="action"
  (keydown.arrowdown)="shiftFocusDown($event)"
  (keydown.arrowup)="shiftFocusUp($event)"
  (keydown.esc)="close()"
  (dsClickOutside)="close()"
>
  <input
    #inputField
    type="text"
    [(ngModel)]="value"
    [name]="name"
    class="form-control form-control-sm com-sm suggestion_input mb-2"
    [ngClass]="{ 'is-invalid': !valid }"
    [dsDebounce]="debounceTime"
    (onDebounce)="find($event)"
    [placeholder]="placeholder"
    [ngModelOptions]="{ standalone: true }"
    autocomplete="off"
  />
  <input type="submit" class="d-none" />
  <div
    class="autocomplete dropdown-menu"
    [ngClass]="{ show: (show | async) && isNotEmpty(suggestions) }"
  >
    <div class="dropdown-list">
      <div *ngFor="let suggestionOption of suggestions">
        <button
          class="d-block dropdown-item com-sm"
          (click)="onClickSuggestion(suggestionOption)"
          #suggestion
        >
          <ds-listable-object-component-loader
            [object]="suggestionOption"
            [viewMode]="viewMode"
            [linkType]="linkTypes.None"
          ></ds-listable-object-component-loader>
        </button>
      </div>
    </div>
  </div>
</form>
