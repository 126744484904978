<form #form="ngForm" (ngSubmit)="onSubmit(form.value)" action="/search">
  <div class="search_bar">
    <div class="form-group input-group">
      <div *ngIf="showScopeSelector" class="input-group-prepend">
        <!-- <button
          class="btn text-truncate allEcm search-title"
          type="button"
          (click)="openScopeModal()"
        >
          {{
            (selectedScope | async)?.name ||
              ("search.form.scope.all" | translate)
          }}
        </button> -->
      </div>
      <!-- <i class="fa-solid fa-magnifying-glass"></i> -->
      <input
        type="text"
        [(ngModel)]="query"
        name="query"
        class="form-control form-control-sm date-picker-border-radius" style="box-shadow: none;border-radius: 6px 0 0 6px;"
        attr.aria-label="{{ searchPlaceholder }}"
        [attr.data-test]="'search-box' | dsBrowserOnly"
        [placeholder]="searchPlaceholder"
        autocomplete="off"
      />

      <span class="input-group-append">
        <button
          type="submit"
          class="btn searchPage search-title btn-{{ brandColor }}"
          [attr.data-test]="'search-button' | dsBrowserOnly"
        >
          {{ "search.form.search" | translate }}
        </button>
      </span>
    </div>
  </div>
</form>
