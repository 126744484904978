<div class="space-children-mr d-flex">
  <button
    class="icon-btn workflow-view mt-1"
    data-test="view-btn"
    ngbTooltip="{{ 'submission.workflow.generic.view-help' | translate }}"
    [routerLink]="[getWorkspaceItemViewRoute(object)]"
  >
    <i class="fa fa-info-circle"></i>
    <!-- {{"submission.workflow.generic.view" | translate}} -->
  </button>
  <!--ecm to mantain consistency-->
  <a
    class="icon-btn mt-1"
    id="{{ 'edit_' + object.id }}"
    ngbTooltip="{{ 'submission.workflow.generic.edit-help' | translate }}"
    [routerLink]="['/workspaceitems/' + object.id + '/edit/type']"
    role="button"
  >
    <!--end of ecm -->
    <i class="fa fa-edit"></i>
    <!-- {{'submission.workflow.generic.edit' | translate}} -->
  </a>

  <button
    type="button"
    id="{{ 'delete_' + object.id }}"
    class="icon-btn icon-btn-delete mt-1"
    ngbTooltip="{{ 'submission.workflow.generic.delete-help' | translate }}"
    (click)="$event.preventDefault(); confirmDiscard(content)"
  >
    <span *ngIf="processingDelete$ | async"
      ><i class="fas fa-circle-notch fa-spin"></i>
      {{ "submission.workflow.tasks.generic.processing" | translate }}</span
    >
    <span *ngIf="!(processingDelete$ | async)"
      ><i class="fa fa-trash"></i>
      <!-- {{'submission.workflow.generic.delete' | translate}} -->
    </span>
  </button>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger">
      {{ "submission.general.discard.confirm.title" | translate }}
    </h4>
    <button
      type="button"
      id="delete_close"
      class="close"
      aria-label="Close"
      (click)="d('cancel')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ "submission.general.discard.confirm.info" | translate }}</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      id="delete_cancel"
      class="btn btn-secondary"
      (click)="c('cancel')"
    >
      {{ "submission.general.discard.confirm.cancel" | translate }}
    </button>
    <button
      type="button"
      id="delete_confirm"
      class="btn btn-danger"
      (click)="c('ok')"
    >
      {{ "submission.general.discard.confirm.submit" | translate }}
    </button>
  </div>
</ng-template>
