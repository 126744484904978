<!--Footer Start -->
<section>
  <!-- Footer -->
  <footer class="bg-footer text-white">
    <!-- Grid container -->
    <div class="container p-4">
      <!--Grid row-->
      <div class="row" style="font-family: 'NotoSans-Medium'">
        <!--Grid column-->
        <div class="col">
          <img
          loading="lazy"
            class="footer-ecmlogo-img"
            src="assets/images/ECM Footer Logo.png"
          />
        </div>

        <div class="col" style="text-align: right">
          <a href="https://www.rebit.org.in" target="_blank"
            ><img loading="lazy" class="footer-relogo-img" src="assets/images/logo-WHITE.svg"
          /></a>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
      <hr class="hr-footer" />
      <div class="row">
        <!--ecm bug 382-->
        <div class="col-8">
          <!--end-->
          <div class="footer-links">
            <div>
              <a
                class="text-white"
                href="javascript:void(0);"
                (click)="showCookieSettings()"
                >{{ "footer.link.cookies" | translate }}</a
              >
            </div>
            <div *ngIf="showPrivacyPolicy">
              <a class="text-white" routerLink="info/privacy">{{
                "footer.link.privacy-policy" | translate
              }}</a>
            </div>
            <div *ngIf="showEndUserAgreement">
              <a class="text-white" routerLink="info/end-user-agreement">{{
                "footer.link.end-user-agreement" | translate
              }}</a>
            </div>
            <div>
              <a class="text-white" routerLink="info/feedback">{{
                "footer.link.feedback" | translate
              }}</a>
            </div>
            <!-- <div>
              <a href="#">
                Help
              </a>
              
            </div> -->
          </div>
        </div>
        <!--ecm bug382-->
        <div class="col-4" style="font-size: 13px; text-align: right">
          <!--end-->
          <div>
            {{ "home.copyright" | translate }}
          </div>
        </div>
      </div>
    </div>
    <!-- Grid container -->
  </footer>
  <!-- Footer -->
</section>
<!--Footer End-->
