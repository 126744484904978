<div style="position: relative">
  <div class="mt-2">
    <!-- <div class="card community-card" data-bs-toggle="tooltip" data-bs-placement="top" title="Collections" data-bs-title="Tooltip on top"> -->
    <!-- <span class="badge badge-collection3">Collection</span> -->
    <div class="row lg-3 md-2 align-items-center">
      <div class="col-auto">
        <div class="icon-container">
          <img loading="lazy" class="small-img" src="assets/images/commu-icon.svg" />
        </div>
      </div>
      <div class="col community-overflow pl-0" style="position: relative">
        <!-- <span class="badge badge-collection3">{{
          "batch.collection" | translate
        }}</span> -->
        <div
          class="mb-0 community-card-heading pl-02"
          [routerLink]="['/collections/' + object.id]"
        >
          {{ object.name }}
        </div>
        <div *ngIf="object.shortDescription" class="com-md abstract-text">
          {{ object.shortDescription }}
        </div>
        <!-- <button>EDIT</button> -->

        <button
          id="openModalButton"
          type="button"
          class="holiday-popup"
          data-toggle="modal"
          data-target="#exampleModaledit"
        >
          EDIT
        </button>
        <div
          class="modal fade"
          data-backdrop="static" data-keyboard="false"
          id="exampleModaledit"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModaleditTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header holiday-model-header ml-2 mt-1">
                <h5
                  class="modal-title holiday-model-title"
                  id="exampleModaleditTitle"
                >
                  Edit
                </h5>
                <button
                  id="closeModal"
                  type="button"
                  class="close holiday-model-close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <input #editCollField />
                <button (click)="editColl(editCollField.value, object.id)">
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>

        <button (click)="delColl(object.id)">DEL</button>
      </div>
    </div>
    <!-- </div> -->
  </div>
</div>
<!-- <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/collections/' + object.id]" class="lead">
    {{object.name}}
</a> 
<span *ngIf="linkType == linkTypes.None" class="lead">
    {{object.name}}
</span>-->
<!-- <div *ngIf="object.shortDescription" class="text-muted abstract-text">
    {{object.shortDescription}}
</div> -->
