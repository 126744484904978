<div>
  <div class="vertical-box card-slider-new">
    <div class="projects">
      <div class="eve-heading p-3">
        <span class="flex-item">
          <img
          loading="lazy"
            src="assets/images/arrow-left-solid.svg"
            alt="arrow"
            height="20"
            class="back-img"
            (click)="goToCoePage()"
          />
          Initiatives</span
        >
      </div>

      <div class="card-slider">
        <div
          *ngFor="let initiative of initiativeList"
          class="project-card"
        >
          <a
            routerLink="/coedetail/{{ coeid }}/{{ depid }}/{{ 'details' }}" (click)="goToCoeDtlPage(initiative)"
          >
            <div class="pro-card">
              <div class="bg-one">
                <img loading="lazy" src="assets/images/mask.png" alt="square" />
              </div>
              <div class="prj-details">
                <p class="eve-heading" [ngbTooltip]="initiative">
                  {{ initiative }}
                </p>
                <!-- <p class="prj-desc text-truncate">
                  {{ initiative.value.description }}
                </p> -->
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
