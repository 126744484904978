<ng-container>
  <div class="mb-3">
    <h5 class="search-head">
      {{ "search.sidebar.settings.title" | translate }}
    </h5>
  </div>
  <div class="result-order-settings">
    <div class="accordion sort_accordian" id="accordionExample">
      <div class="card sort-item">
        <div class="card-header sort-header" id="headingthree">
          <h2 class="mb-0">
            <button
              id="colbtn"
              class="btn sort-btn sort-drop collapsed"
              type="button"
              (click)="isCollapsed()"
            >
              {{
                "sorting." +
                  currentSortOption.field +
                  "." +
                  currentSortOption.direction | translate
              }}
            </button>
          </h2>
        </div>

        <div
          class="collapse"
          [class]="isCollapse ? 'show' : ''"
          aria-labelledby="headingthree"
          data-parent="#accordionExample"
        >
          <div class="card-body p-0 most_rel_list">
            <option
              *ngFor="let sortOptionsEntry of sortOptionsList"
              [value]="
                sortOptionsEntry.field +
                ',' +
                sortOptionsEntry.direction.toString()
              "
              (click)="getOrder(sortOptionsEntry)"
              [selected]="
                sortOptionsEntry.field === currentSortOption?.field &&
                sortOptionsEntry.direction === currentSortOption?.direction
                  ? 'selected'
                  : null
              "
            >
              {{
                "sorting." +
                  sortOptionsEntry.field +
                  "." +
                  sortOptionsEntry.direction | translate
              }}
            </option>
          </div>
        </div>
      </div>
    </div>

    <!-- <ds-sidebar-dropdown *ngIf="sortOptionsList"
                         [id]="'search-sidebar-sort'"
                        (change)="reloadOrder($event)">
        <option *ngFor="let sortOptionsEntry of sortOptionsList"
                [value]="sortOptionsEntry.field + ',' + sortOptionsEntry.direction.toString()"
                [selected]="sortOptionsEntry.field === currentSortOption?.field && sortOptionsEntry.direction === (currentSortOption?.direction)? 'selected': null">
            {{'sorting.' + sortOptionsEntry.field + '.' + sortOptionsEntry.direction | translate}}
        </option>
    </ds-sidebar-dropdown> -->
  </div>

  <ds-page-size-selector></ds-page-size-selector>
</ng-container>
