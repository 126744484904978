<form
  class="w-100"
  [formGroup]="formData"
  (ngSubmit)="submitForm(formData.value)"
>
  <div class="mt-3">
    <ul class="nav nav-tabs dash_tab" id="nav-tab" role="tablist">
      <ng-container *ngFor="let subSection of subSections">
        <li>
          <a
            href="javascript:void(0);"
            (click)="redirectTo(subSection.model.link)"
            class="nav-link explore-tabs {{
              subSection.model.link == url ? 'active' : ''
            }}"
            data-toggle="tab"
            attr.data-target="#{{ subSection.id == url ? 'tab1' : 'tab2' }}"
            role="tab"
            attr.aria-selected="{{ subSection.id == url ? 'true' : 'false' }}"
            >{{ subSection.model.text | translate }}</a
          >
        </li>
      </ng-container>
    </ul>

    <!-- <div class="tab-pane fade" id="tab2" role="tabpanel" tabindex="0">  -->

    <div class="row align-items-center mt-3">
      <div class="col-md-5">
        <div class="row">
          <div class="col mob-mb">
            <div class="input-group">
              <input
                type="text"
                class="form-control input-explr"
                autocomplete="off"
                placeholder="{{ 'browse.startsWith.type_text' | translate }}"
                name="startsWith"
                formControlName="startsWith"
                [value]="getStartsWith()"
              />
              <button class="btn btn-explr" type="submit" id="button-addon2">
                {{ "home.explore" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- </div> -->
    </div>
  </div>
  <!-- </div> -->
  <hr />
</form>
