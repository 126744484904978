<div>
  <div class="modal-header">
    {{
      "dso-selector." +
        action +
        "." +
        objectType.toString().toLowerCase() +
        ".head" | translate
    }}
    <button type="button" class="close" (click)="close()" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body pt-0">
    <button
      class="btn btn-outline-primary btn-lg btn-block comm-btn"
      (click)="selectObject(undefined)"
    >
      {{ "dso-selector.create.community.top-level" | translate }}
    </button>
    <h3 class="position-relative py-1 mb-0 font-weight-normal">
      <!-- <hr> -->
      <!-- <div id="create-community-or-separator" class="text-center position-absolute w-100">
                <span class="px-4 bg-white" style="font-size: 16px;">or</span>
            </div> -->

      <div id="create-community-or-separator" class="or_line">
        <span>{{ "or" | translate }}</span>
      </div>
    </h3>

    <h5 class="model-subhead">
      {{ "dso-selector.create.community.sub-level" | translate }}
    </h5>
    <ds-dso-selector
      [currentDSOId]="dsoRD?.payload.uuid"
      [types]="selectorTypes"
      (onSelect)="selectObject($event)"
    ></ds-dso-selector>
  </div>
</div>
