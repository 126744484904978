<div class="d-flex justify-content-between export-btn">
  <!-- <h2 *ngIf="!disableHeader">{{ (configuration ? configuration + '.search.results.head' : 'search.results.head') | translate }}</h2> -->
  <ds-search-export-csv [searchConfig]="searchConfig"></ds-search-export-csv>
</div>
<div
  class="grid-item-align"
  *ngIf="
    searchResults &&
    searchResults?.hasSucceeded &&
    !searchResults?.isLoading &&
    searchResults?.payload?.page.length > 0
  "
  @fadeIn
>
  <ds-viewable-collection
    [config]="searchConfig.pagination"
    [sortConfig]="searchConfig.sort"
    [objects]="searchResults"
    [hideGear]="true"
    [selectable]="selectable"
    [selectionConfig]="selectionConfig"
    [linkType]="linkType"
    [context]="context"
    [hidePaginationDetail]="hidePaginationDetail"
    (contentChange)="contentChange.emit($event)"
    (deselectObject)="deselectObject.emit($event)"
    (selectObject)="selectObject.emit($event)"
    class="scroll-data"
  >
  </ds-viewable-collection>
</div>
<!-- <ds-themed-loading
  *ngIf="isLoading()"
  message="{{ 'loading.search-results' | translate }}"
></ds-themed-loading> -->
<ds-error
  *ngIf="showError()"
  message="{{ errorMessageLabel() | translate }}"
></ds-error>
<div
  style="padding: 0 4px; font-size: 12px"
  *ngIf="
    searchResults?.payload?.page.length == 0 || searchResults?.statusCode == 400
  "
>
  {{ "search.results.no-results" | translate }}
  <a
    [routerLink]="['/search']"
    [queryParams]="{ query: surroundStringWithQuotes(searchConfig?.query) }"
    queryParamsHandling="merge"
  >
    {{ "search.results.no-results-link" | translate }}
  </a>
</div>
