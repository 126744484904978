import { environment } from 'src/environments/environment';

export class RegexModel {
  maxLength: number;
  minLength: number;
  pattern: string;
  errorMessage: string;
}

export class RegexObject {
  regexModel: RegexModel = new RegexModel();
  getRegexObject(id: string) {
    let defaultValidation = environment.defaultValidation;
    let validation = environment.validation;
    this.regexModel.maxLength = defaultValidation[0].maxLength;
    this.regexModel.minLength = defaultValidation[0].minLength;
    this.regexModel.pattern = defaultValidation[0].pattern;
    this.regexModel.errorMessage = defaultValidation[0].errorMessage;
    validation.forEach((obj) => {
      if (obj.id.includes(id)) {
        this.regexModel.pattern = obj.pattern;
        this.regexModel.maxLength = obj.maxLength;
        this.regexModel.minLength = obj.minLength;
        this.regexModel.errorMessage = obj.errorMessage;
      }
    });
    return this.regexModel;
  }
}
