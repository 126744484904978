import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { EPerson } from '../../../core/eperson/models/eperson.model';
import { AppState } from '../../../app.reducer';
import { isAuthenticationLoading } from '../../../core/auth/selectors';
import { MYDSPACE_ROUTE } from '../../../my-dspace-page/my-dspace-page.component';
import { AuthService } from '../../../core/auth/auth.service';
import { getProfileModuleRoute } from '../../../app-routing-paths';
import { CookieService } from 'src/app/core/services/cookie.service';

/**
 * This component represents the user nav menu.
 */
@Component({
  selector: 'ds-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  email: string;
  lastActive: string;
  lastActiveTime: string;

  /**
   * True if the authentication is loading.
   * @type {Observable<boolean>}
   */
  public loading$: Observable<boolean>;

  /**
   * The authenticated user.
   * @type {Observable<EPerson>}
   */
  public user$: Observable<EPerson>;

  /**
   * The mydspace page route.
   * @type {string}
   */
  public mydspaceRoute = MYDSPACE_ROUTE;

  /**
   * The profile page route
   */
  public profileRoute = getProfileModuleRoute();

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private cookieservice: CookieService
  ) {}

  /**
   * Initialize all instance variables
   */
  ngOnInit(): void {
    // set loading
    this.loading$ = this.store.pipe(select(isAuthenticationLoading));

    // set user
    this.user$ = this.authService.getAuthenticatedUserFromStore();
    this.user$.subscribe((data) => {
      this.email = data.email;
    });
    this.authService.setLastActive(this.cookieservice.get('last-login'));
  }

  get getLastActive() {
    let dateTime = this.authService.getLastActive().subscribe((data) => {
      this.lastActiveTime = data;
    });
    return this.lastActiveTime;
  }
}
