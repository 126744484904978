<div *ngIf="isLoggedIn | async">
  <div class="card-box bx-shadow notification-css" *ngIf="announcement">
    <div class="eve-heading">Announcements</div>
    <owl-carousel-o [options]="customOptions">
      <ng-template
        carouselSlide
        *ngFor="let announcement of manipulateAnnouncementData"
      >
        <div class="slide">
          <p class="description">
            {{ announcement.announcementDesc }}
          </p>
        </div>
      </ng-template>
    </owl-carousel-o>

    <!-- <span class="see-all">See all</span> -->
    <div class="calender-box m-0 p-0">
      <!-- <span class="holiday-list">List of Holidays</span> -->
      <span
        type="button"
        class="holiday-list"
        data-toggle="modal"
        data-target="#see-all-model"
      >
        See all
      </span>
      <div
        class="modal fade hall-of-fame-modal"
        data-backdrop="static"
        data-keyboard="false"
        id="see-all-model"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content" style="max-width: 727px">
            <div class="modal-header holiday-model-header">
              <div class="d-flex">
                <h5
                  class="modal-title holiday-model-title mr-4"
                  id="exampleModalLongTitle"
                >
                  Announcements
                </h5>
                <!-- <select class="select-data">
                <option>January 2023</option>
                <option>February 2023</option>

                <option class="active">March 2023</option> <option>April 2023</option>
                <option>June 2023</option> <option>July 2023</option>
                <option>August 2023</option>
                <option>September 2023</option>
                <option>October 2023</option>
                <option>November 2023</option>
                <option>December 2023</option>
              </select> -->
              </div>

              <button
                type="button"
                class="close holiday-model-close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <p aria-hidden="true">&times;</p>
              </button>
            </div>
            <div class="modal-body pt-0 multiple-events py-3">
              <div
                class="announce-data-card"
                *ngFor="let annoucement of announcementData"
              >
                <div class="ann-event-card">
                  <h1 class="event-ann">{{ annoucement.announcementTitle }}</h1>
                  <p class="blog-info">
                    {{ annoucement.announcementDesc }}
                  </p>
                  <p class="date-ann">
                    {{ annoucement.announcementDate | date : "dd/MM/yyyy" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="card-box bx-shadow calendar-new">
      <div class="flex">
        <span class="hall-of-frame-head">Calendar</span>
        <div class="date-box">
          <span>{{ monthOfYear }}</span>
        </div>
      </div>
      <div class="navigation-menu calender-menu p-0">
        <ul
          class="nav nav-pills calender-details"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item ml-0" role="presentation">
            <div
              class="nav-link active"
              id="pills-holidays-tab"
              data-toggle="pill"
              data-target="#pills-holidays"
              role="tab"
              aria-controls="pills-holidays"
              aria-selected="true"
            >
              Holidays
            </div>
          </li>
          <li class="nav-item" role="presentation">
            <div
              class="nav-link"
              id="pills-calender-event-tab"
              data-toggle="pill"
              data-target="#pills-calender-event"
              role="tab"
              aria-controls="pills-calender-event"
              aria-selected="false"
            >
              Events
            </div>
          </li>

          <li class="nav-item" role="presentation">
            <div
              class="nav-link"
              id="pills-anniversary-tab"
              data-toggle="pill"
              data-target="#pills-anniversary"
              role="tab"
              aria-controls="pills-anniversary"
              aria-selected="false"
            >
              Work Anniversary
            </div>
          </li>
          <li class="nav-item" role="presentation">
            <div
              class="nav-link"
              id="pills-birthday-tab"
              data-toggle="pill"
              data-target="#pills-birthday"
              role="tab"
              aria-controls="pills-birthday"
              aria-selected="false"
            >
              Birthdays
            </div>
          </li>
        </ul>
      </div>
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-holidays"
          aria-labelledby="pills-holidays-tab"
        >
          <div class="event-box">
            <div
              class="events-name"
              style="width: 130px"
              title="Guru Nanak Jayanti"
            >
              <div class="event-date"><span>Mon</span> <span>27</span></div>
              <span class="mx-2 text-truncate">Guru Nanak Jayanti</span>
            </div>
            <div class="events-name" style="width: 130px" title="Eid-E-Milad">
              <div class="event-date"><span>Thurs</span> <span>28</span></div>
              <span class="mx-2 text-truncate">Eid-E-Milad</span>
            </div>
          </div>
          <div class="calender-box m-0">
            <!-- <span class="holiday-list">List of Holidays</span> -->
            <span
              type="button"
              class="holiday-list"
              data-toggle="modal"
              data-target="#ExampleModalLong"
            >
              List of Holidays
            </span>
            <div
              class="modal fade list-of-holi"
              data-backdrop="static"
              data-keyboard="false"
              id="ExampleModalLong"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div class="modal-content">
                  <div class="modal-header holiday-model-header">
                    <h5
                      class="modal-title holiday-model-title"
                      id="exampleModalLongTitle"
                    >
                      List of Holidays 2023
                    </h5>
                    <button
                      type="button"
                      class="close holiday-model-close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <p aria-hidden="true">&times;</p>
                    </button>
                  </div>
                  <div class="modal-body multiple-events">
                    <div class="container business-units px-0 pt-0">
                      <button
                        *ngFor="let tab of tabs"
                        (click)="Holidays(tab)"
                        class="bu-box"
                        [ngClass]="{ active: tab == activeTabName }"
                      >
                        {{ tab }}
                      </button>
                    </div>
                    <table class="table table-bordered holiday-table">
                      <thead>
                        <tr>
                          <th scope="col">S.no</th>
                          <th scope="col">Occasion/Festival</th>
                          <th scope="col">Date</th>
                          <th scope="col">Day</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let i of regionWiseHoliday">
                          <th scope="row">{{ i["S.No"] }}</th>
                          <td>{{ i["Holiday"] }}</td>
                          <td>{{ i["Date"] }}</td>
                          <td>{{ i["Day"] }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pills-calender-event"
          aria-labelledby="pills-calender-event-tab"
        >
          ...
        </div>

        <div
          class="tab-pane fade"
          id="pills-anniversary"
          aria-labelledby="pills-anniversary-tab"
        >
          ...
        </div>
        <div
          class="tab-pane fade"
          id="pills-birthday"
          aria-labelledby="pills-birthday-tab"
        >
          ...
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="hall-of-frame card-box bx-shadow" >
    <div class="flex">
      <span class="hall-of-frame-head">Hall of Fame</span>
      <div class="date-box">
        <span>Jan 2023</span>
      </div>
    </div>
    <div class="navigation-menu calender-menu p-0">
      <ul
        class="nav nav-pills calender-details"
        id="pills--hall-of-fame-tab"
        role="tablist"
      >
        <li class="nav-item ml-0" role="presentation">
          <div
            class="nav-link active"
            id="pills-spotlight-tab"
            data-toggle="pill"
            data-target="#pills-spotlight"
            role="tab"
            aria-controls="pills-spotlight"
            aria-selected="true"
          >
            Spotlight Award
          </div>
        </li>
        <li class="nav-item" role="presentation">
          <div
            class="nav-link"
            id="pills-high-five-event-tab"
            data-toggle="pill"
            data-target="#pills-high-five-event"
            role="tab"
            aria-controls="pills-high-five-event"
            aria-selected="false"
          >
            High5 Award
          </div>
        </li>
      </ul>
    </div>
    <div class="tab-content" id="pills-awardContent">
      <div
        class="tab-pane fade show active"
        id="pills-spotlight"
        aria-labelledby="pills-spotlight-tab"
      >
        <div class="award-box">
          <img loading="lazy" src="assets/images/user-2.png" alt="image" />
          <img loading="lazy" src="assets/images/user-3.png" alt="image" />
          <img loading="lazy" src="assets/images/user-4.png" alt="image" />
          <img loading="lazy" src="assets/images/user-6.png" alt="image" />
          <img loading="lazy" src="assets/images/ajay-mehta.png" alt="image" />
        </div>
        <div class="calender-box">
          <span class="holiday-list">View More</span>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="pills-high-five-event"
        aria-labelledby="pills-high-five-tab"
      >
        <div class="award-box">
          <img loading="lazy" src="assets/images/user-2.png" alt="image" />
          <img loading="lazy" src="assets/images/user-3.png" alt="image" />
          <img loading="lazy" src="assets/images/user-4.png" alt="image" />
          <img loading="lazy" src="assets/images/user-6.png" alt="image" />
          <img loading="lazy" src="assets/images/ajay-mehta.png" alt="image" />
        </div>
        <div class="calender-box">
          <span class="holiday-list">View More</span>
        </div>
      </div>
    </div>
  </div> -->

  <div class="hall-of-frame card-box bx-shadow">
    <div class="flex mb-2">
      <span class="hall-of-frame-head">Hall of Fame</span>
      <div class="date-box">
        <!-- <span>Jan 2023</span> -->
        <!-- <input type="date"> -->
      </div>
    </div>
    <div class="awards">
      <label class="award-css">Spotlight Award</label>
      <div class="award-box">
        <img
          loading="lazy"
          src="assets/images/Ranjana Singh.jpg"
          alt="image"
          title="Ranjana Singh"
        />
        <img
          loading="lazy"
          src="assets/images/Pranay Gupta.jpg"
          alt="image"
          title="Pranay Gupta"
        />
        <img
          loading="lazy"
          src="assets/images/Rajesh Ghosh.jpg"
          alt="image"
          title="Rajesh Ghosh"
        />
        <!-- <img
          loading="lazy"
          src="assets/images/Devanand-Otavkar.jpg"
          alt="image"
          title="Devanand Otavkar"
        />
        <img
          loading="lazy"
          src="assets/images/Mahesh-Hakale.jpg"
          alt="image"
          title="Mahesh Hakale"
        />

        <span class="number-count">+5</span> -->
      </div>
    </div>
    <div class="awards">
      <label class="award-css">High 5 Award</label>
      <div class="award-box">
        <img
          loading="lazy"
          src="assets/images/Sairam Venugopal.jpg"
          alt="image"
          title="Sairam Venugopal"
        />
        <img
          loading="lazy"
          src="assets/images/Harshad Patil.jpg"
          alt="image"
          title="Harshad Patil"
        />
        <img
          loading="lazy"
          src="assets/images/Romario Singha.jpeg"
          alt="image"
          title="Romario Singha"
        />
        <img
          loading="lazy"
          src="assets/images/Saurabh Dalvi.jpg"
          alt="image"
          title="Saurabh Dalvi"
        />
        <img
          loading="lazy"
          src="assets/images/Sayali Kulkarni.jpg"
          alt="image"
          title="Sayali Kulkarni"
        />

        <!-- <span class="number-count">+10</span> -->
      </div>
    </div>
    <div class="calender-box m-0">
      <!-- <span class="holiday-list">List of Holidays</span> -->
      <span
        type="button"
        class="holiday-list"
        data-toggle="modal"
        data-target="#hall-of-fame"
      >
        View More
      </span>
      <div
        class="modal fade hall-of-fame-modal"
        data-backdrop="static"
        data-keyboard="false"
        id="hall-of-fame"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header holiday-model-header">
              <h5
                class="modal-title holiday-model-title"
                id="exampleModalLongTitle"
              >
                Hall of Fame <span class="year">(Jun - Sep 2023)</span>
              </h5>

              <button
                type="button"
                class="close holiday-model-close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <p aria-hidden="true">&times;</p>
              </button>
            </div>
            <div class="modal-body pt-0">
              <div class="hall-of-frame">
                <div class="navigation-menu calender-menu p-0">
                  <ul
                    class="nav nav-pills calender-details mt-0"
                    id="pills--hall-of-fame-tab"
                    role="tablist"
                  >
                    <li class="nav-item ml-0" role="presentation">
                      <div
                        class="nav-link active mr-3 pb-1"
                        id="pills-spotlight-tab"
                        data-toggle="pill"
                        data-target="#pills-spotlight"
                        role="tab"
                        aria-controls="pills-spotlight"
                        aria-selected="true"
                      >
                        Spotlight Award
                      </div>
                    </li>
                    <li class="nav-item" role="presentation">
                      <div
                        class="nav-link pb-1"
                        id="pills-high-five-event-tab"
                        data-toggle="pill"
                        data-target="#pills-high-five-event"
                        role="tab"
                        aria-controls="pills-high-five-event"
                        aria-selected="false"
                      >
                        High5 Award
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="tab-content" id="pills-awardContent">
                  <div
                    class="tab-pane fade active show"
                    id="pills-spotlight"
                    aria-labelledby="pills-spotlight-tab"
                  >
                    <div class="user-box">
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Ranjana Singh.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Ranjana Singh">Ranjana Singh</a>
                        </div>
                      </div>
                        <div class="user-detail col-md-4 p-0">
                          <img
                            loading="lazy"
                            src="assets/images/Pranay Gupta.jpg"
                            alt="user"
                          />
                          <div class="profile-name">
                            <a title="Pranay Gupta">Pranay Gupta</a>
                          </div>
                        </div>
                        <div class="user-detail col-md-4 p-0">
                          <img
                            loading="lazy"
                            src="assets/images/Rajesh Ghosh.jpg"
                            alt="user"
                          />
                          <div class="profile-name">
                            <a title="Rajesh Ghosh">Rajesh Ghosh</a>
                          </div>
                        </div>
                        <!-- <div class="user-detail col-md-4 p-0">
                          <img
                            loading="lazy"
                            src="assets/images/Devanand-Otavkar.jpg"
                            alt="user"
                          />
                          <div class="profile-name">
                            <a title="Devanand Otavkar">Devanand Otavkar</a>
                          </div>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Mahesh-Hakale.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Mahesh Hakale">Mahesh Hakale</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Pradip-Somavanshi.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Pradip Somavanshi">Pradip Somavanshi</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Rafiqueahmed-Kakati.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Rafiqueahmed Kakati">Rafiqueahmed Kakati</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Reyons-Lobo.png"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Reyons Lobo">Reyons Lobo</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Salin-menon.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Salin menon">Salin menon</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Vishal-Kathe.jpeg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Vishal Kathe">Vishal Kathe</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-high-five-event"
                    aria-labelledby="pills-high-five-tab"
                  >
                    <div class="user-box">
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Brijesh-Chaurasia.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Brijesh Chaurasia">Brijesh Chaurasia</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Gajanan-Ade.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Gajanan Ade">Gajanan Ade</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Harshal-Ziradkar.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Harshal Ziradkar">Harshal Ziradkar</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Kommuri-Kiran.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Kommuri Kiran">Kommuri Kiran</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Mukul-Koli.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Mukul Koli">Mukul Koli</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Nirmiti.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Nirmiti Chunodkar">Nirmiti Chunodkar</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Rupanjali-Kalita.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Rupanjali Kalita">Rupanjali Kalita</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Sanjana-Hadawale.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Sanjana Hadawale">Sanjana Hadawale</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Sayli-Londhe.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Sayli Londhe">Sayli Londhe</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Sudhir-Panda.jpeg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Sudhir Panda">Sudhir Panda</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Sumedh-Ghadi.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Sumedh Ghadi">Sumedh Ghadi</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Sunil-Sahu.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Sunil Sahu">Sunil Sahu</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Suraj-Kamble.jpg"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Suraj Kamble">Suraj Kamble</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Vikram-Madane.png"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Vikram Madane">Vikram Madane</a>
                        </div>
                      </div>
                      <div class="user-detail col-md-4 p-0">
                        <img
                          loading="lazy"
                          src="assets/images/Vivek-Balgude.png"
                          alt="user"
                        />
                        <div class="profile-name">
                          <a title="Vivek Balgude">Vivek Balgude</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="calender-box">
            <span class="holiday-list">View More</span>
        </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- From Here -->
  <!-- <div
    class="card-box cafe-menu bx-shadow"
    data-toggle="modal"
    data-target="#cafe-menu"
  >
    <img loading="lazy" src="assets/images/cafe.svg" alt="cafe" />
    <div class="eve-heading">Café Menu</div>
  </div> -->
  <div class="cafe-shadow" data-toggle="modal" data-target="#cafe-menu">
    <img
      src="assets/images/cafe_menu_btn.png"
      alt="cafe"
      class="cafe-box-new"
      width="100%"
    />
  </div>

  <div
    class="modal fade cafe-modal"
    id="cafe-menu"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header p-3">
          <h4 class="modal-title">Café Menu</h4>
          <button type="button" class="close" data-dismiss="modal">×</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body p-0 ekm">
          <div class="navigation-menu">
            <ul
              class="nav nav-pills mb-4 middle-box-events"
              id="pills-tab-cafe"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <div
                  class="nav-link active"
                  id="pills-five-tab"
                  data-toggle="pill"
                  data-target="#pills-five"
                  role="tab"
                  aria-controls="pills-five"
                  aria-selected="true"
                >
                  REBIT 5<sup>th</sup> floor
                </div>
              </li>
              <li class="nav-item" role="presentation">
                <div
                  class="nav-link"
                  id="pills-ten-tab"
                  data-toggle="pill"
                  data-target="#pills-ten"
                  role="tab"
                  aria-controls="pills-ten"
                  aria-selected="false"
                >
                  REBIT 10<sup>th</sup> floor
                </div>
              </li>
            </ul>

            <div class="tab-content" id="pills-tab-five-content">
              <div
                class="tab-pane fade show active"
                id="pills-five"
                aria-labelledby="pills-five-tab"
              >
                <div *ngIf="isImageLoading" style="font-size: 14px;color: #000;">Loading...</div>
                <div *ngIf="!isImageLoading">
                  <img
                    src="{{ baseUrl }}/api/cafemenu/image/{{
                      fifthFloorImageId
                    }}"
                    onerror="this.src='assets/images/Menu_default_op_3_1.jpg'"
                    alt="cafe-menu"
                    width="100%"
                  />
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-ten"
                aria-labelledby="pills-ten-tab"
              >
                <div *ngIf="isImageLoading" style="font-size: 14px;color: #000;">Loading...</div>
                <div *ngIf="!isImageLoading">
                  <img
                    src="{{ baseUrl }}/api/cafemenu/image/{{
                      tenthFloorImageId
                    }}"
                    onerror="this.src='assets/images/Menu_default_op_3_1.jpg'"
                    alt="cafe-menu"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
