<ul class="navbar-nav" *ngIf="(isAuthenticated$ | async) && isImpersonating">
  <li class="nav-item">
    <button
      class="btn btn-sm btn-dark"
      ngbTooltip="{{ 'nav.stop-impersonating' | translate }}"
      (click)="stopImpersonating()"
    >
      <i class="fa fa-user-secret"></i>
    </button>
  </li>
</ul>
