<button
  *ngIf="isAuthorized$ | async"
  class="edit-button btn btn-edit btn-sm rounded-circle"
  (click)="createNewVersion()"
  [disabled]="disableNewVersionButton$ | async"
  role="button"
  [title]="tooltipMsg$ | async | translate"
  [attr.aria-label]="tooltipMsg$ | async | translate"
>
  <i class="fas fa-code-branch fa-fw"></i>
</button>
