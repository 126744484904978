<ds-metadata-field-wrapper [label]="label | translate">
  <div class="flex">
    <img
      loading="lazy"
      src="assets/images/arrow-left-solid.svg"
      (click)="back()"
      alt="arrow"
      height="20"
      class="back-img"
    />
    <span
      class="project-repositiory"
      *ngFor="let mdValue of mdValues; let last = last"
    >
      {{ mdValue.value }}<span *ngIf="!last">{{ separator }}</span>
    </span>
  </div>
</ds-metadata-field-wrapper>

<ng-template #markdown let-value="value" let-classes="classes">
  <span class="{{ classes }}">{{ value | dsMarkdown | async }} </span>
</ng-template>

<ng-template #simple let-value="value" let-classes="classes">
  <span class="{{ classes }}">
    {{ value }}
  </span>
</ng-template>
