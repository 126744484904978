<form
  class="w-100"
  [formGroup]="formData"
  (ngSubmit)="submitForm(formData.value)"
>
  <div class="mt-3">
    <ul class="nav nav-tabs dash_tab" id="nav-tab" role="tablist">
      <ng-container *ngFor="let subSection of subSections">
        <li>
          <a
            href="javascript:void(0);"
            (click)="redirectTo(subSection.model.link)"
            class="nav-link explore-tabs {{
              subSection.model.link == url ? 'active' : ''
            }}"
            data-toggle="tab"
            attr.data-target="#{{ subSection.id == url ? 'tab1' : 'tab2' }}"
            role="tab"
            attr.aria-selected="{{ subSection.id == url ? 'true' : 'false' }}"
            >{{ subSection.model.text | translate }}</a
          >
        </li>
      </ng-container>
    </ul>

    <div class="tab-content" id="nav-tabContent">
      <div
        class="tab-pane fade show active"
        id="tab1"
        role="tabpanel"
        tabindex="0"
      >
        <div class="align-items-center mt-3">
          <div class="row pl-1">
            <div class="col-2 input-mob-mb">
              <select
                class="form-control input-style"
                aria-label="All"
                id="year-select"
                (change)="setStartsWithYearEvent($event)"
                [attr.aria-label]="
                  'browse.startsWith.choose_year.label' | translate
                "
              >
                <option [value]="-1" [selected]="!startsWithYear">
                  {{ "browse.startsWith.choose_year" | translate }}
                </option>

                <option
                  *ngFor="let option of startsWithOptions"
                  [value]="option"
                  [selected]="option === startsWithYear ? 'selected' : null"
                >
                  {{ option }}
                </option>
              </select>
            </div>

            <div class="col-2 input-mob-mb pl-0" style="padding-left: 15px">
              <select
                id="month-select"
                class="form-control input-style mr-2"
                (change)="setStartsWithMonthEvent($event)"
                [attr.aria-label]="
                  'browse.startsWith.months.none.label' | translate
                "
              >
                <option
                  *ngFor="let option of monthOptions"
                  [value]="option"
                  [selected]="option === startsWithMonth ? 'selected' : null"
                >
                  {{ "browse.startsWith.months." + option | translate }}
                </option>
              </select>
            </div>
            <div class="mt-1" style="margin-right: 12px">or</div>
            <div class="form-group input-group col-5 mb-0 pl-0">
              <input
                class="form-control input-style pl-3"
                autocomplete="off"
                placeholder="{{ 'browse.startsWith.type_date' | translate }}"
                [attr.aria-label]="
                  'browse.startsWith.type_date.label' | translate
                "
                type="text"
                name="startsWith"
                formControlName="startsWith"
                [value]="getStartsWith() ? getStartsWith() : ''"
              />
              <span class="input-group-append">
                <button class="btn btn-primary explr-pg-search" type="submit">
                  {{ "browse.startsWith.submit" | translate }}
                </button>
              </span>
            </div>

            <div class="col-3"></div>

            <!-- <div class="col-lg-4 input-mob-mb pl-2">
               <input type="date" class="form-control form-control-sm input-style" placeholder="Select Date" aria-label="Select Date" aria-describedby="button-addon2">  -->
            <!-- <input class="form-control form-control-sm input-style" placeholder="Select Date" aria-label="Select Date" aria-describedby="button-addon2"  [value]="getStartsWith() ? getStartsWith() : ''" /> -->
            <!-- <span class="input-group-append">
                <button class="btn btn-primary" type="submit"><i class="fas fa-book-open"></i> {{'browse.startsWith.submit' | translate}}</button>
              </span> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="tab-pane fade" id="tab2" role="tabpanel" tabindex="0"> 
        <ds-starts-with-text></ds-starts-with-text>
      </div> -->
  </div>

  <hr />
</form>
