<!--ecm edit button css apply-->
<a
  *ngIf="object"
  class="btn icon-btn"
  ngbTooltip="{{ 'submission.workflow.tasks.claimed.edit_help' | translate }}"
  [routerLink]="[
    '/workflowitems/' + (object.workflowitem | async)?.payload?.id + '/edit'
  ]"
  role="button"
>
  <i class="fa fa-edit"></i>
  <!-- {{ "submission.workflow.tasks.claimed.edit" | translate }} -->
</a>
<!--end of bug code-->
