<div class="form-group w-100 has-search">
  <span class="fa fa-search form-control-search"></span>
  <input
    type="search"
    style="padding-left: 33px;"
    class="form-control"
    placeholder="Search for a Project Repository"
    [formControl]="input"
    ngbAutofocus
    (keyup.enter)="selectSingleResult()"
  />
</div>
<!-- <div class="col-auto">
  <ul class="grid-link mb-3">
    <li><a class="active" href="#"> <img loading="lazy" class="grid-img" src="./images/grid_view.svg"></a></li>
    <li><a href="#"><img loading="lazy" class="grid-img" src="./images/list-icon.svg"></a></li>
  </ul>
</div> -->
<!-- <div class="dropdown-divider"></div> -->
<div class="scrollable-menu list-group my-scrollable-menu multiple-events">
  <div
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="0"
    [infiniteScrollContainer]="'.scrollable-menu'"
    [fromRoot]="true"
    (scrolled)="onScrollDown()"
  >
    <ng-container *ngIf="listEntries$ | async">
      <button
        class="list-group-item list-group-item-action border-0 disabled pl-2"
        *ngIf="(listEntries$ | async).length == 0"
      >
        {{ "dso-selector.no-results" | translate : { type: typesString } }}
      </button>
      <button
        *ngFor="let listEntry of listEntries$ | async"
        class="list-group-item list-group-item-action border-0 list-entry"
        [ngClass]="{
          'bg-primary': listEntry.indexableObject.id === currentDSOId
        }"
        title="{{ getName(listEntry) }}"
        dsHoverClass="ds-hover"
        (click)="onSelect.emit(listEntry.indexableObject)"
        #listEntryElement
      >
        <ds-listable-object-component-loader
          [object]="listEntry"
          [viewMode]="viewMode"
          [linkType]="linkTypes.None"
          [context]="getContext(listEntry.indexableObject.id)"
          class="search-model-list-line"
        ></ds-listable-object-component-loader>
      </button>
    </ng-container>
    <button
      *ngIf="loading"
      class="list-group-item list-group-item-action border-0 list-entry"
    >
      <ds-themed-loading [showMessage]="false"></ds-themed-loading>
    </button>
  </div>
</div>
