import { filter, map, startWith } from 'rxjs/operators';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { combineLatest as combineLatestObservable, Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { MetadataService } from '../core/metadata/metadata.service';
import { HostWindowState } from '../shared/search/host-window.reducer';
import {
  NativeWindowRef,
  NativeWindowService,
} from '../core/services/window.service';
import { AuthService } from '../core/auth/auth.service';
import { CSSVariableService } from '../shared/sass-helper/sass-helper.service';
import { MenuService } from '../shared/menu/menu.service';
import { HostWindowService } from '../shared/host-window.service';
import { ThemeConfig } from '../../config/theme.model';
import { Angulartics2DSpace } from '../statistics/angulartics/dspace-provider';
import { environment } from '../../environments/environment';
import { slideSidebarPadding } from '../shared/animations/slide';
import { MenuID } from '../shared/menu/menu-id.model';
import { getPageInternalServerErrorRoute } from '../app-routing-paths';
import {
  isAuthenticated,
  isAuthenticationLoading,
} from '../core/auth/selectors';

@Component({
  selector: 'ds-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  animations: [slideSidebarPadding],
})
export class RootComponent implements OnInit {
  sidebarVisible: Observable<boolean>;
  isLoggedIn: Observable<boolean>;
  slideSidebarOver: Observable<boolean>;
  collapsedSidebarWidth: Observable<string>;
  totalSidebarWidth: Observable<string>;
  theme: Observable<ThemeConfig> = of({} as any);
  notificationOptions;
  models;
  showmodule: boolean = true;

  showhackathon: boolean = false; //code to show hackathon form to public

  /**
   * Whether or not to show a full screen loader
   */
  @Input() shouldShowFullscreenLoader: boolean;

  /**
   * Whether or not to show a loader across the router outlet
   */
  @Input() shouldShowRouteLoader: boolean;
  /**
   * Whether user is authenticated.
   * @type {Observable<string>}
   */
  public isAuthenticated: Observable<boolean>;

  /**
   * True if the authentication is loading.
   * @type {boolean}
   */
  public loading: Observable<boolean>;

  constructor(
    @Inject(NativeWindowService) private _window: NativeWindowRef,
    private translate: TranslateService,
    private store: Store<HostWindowState>,
    private metadata: MetadataService,
    private angulartics2DSpace: Angulartics2DSpace,
    private authService: AuthService,
    private router: Router,
    private cssService: CSSVariableService,
    private menuService: MenuService,
    private windowService: HostWindowService,
    private cdRef: ChangeDetectorRef
  ) {
    this.notificationOptions = environment.notifications;
  }

  ngOnInit() {
    this.sidebarVisible = this.menuService.isMenuVisible(MenuID.ADMIN);
    this.isLoggedIn = this.authService.isAuthenticated();
    // set loading
    this.loading = this.store.pipe(select(isAuthenticationLoading));

    // set isAuthenticated
    this.isAuthenticated = this.store.pipe(select(isAuthenticated));

    this.collapsedSidebarWidth = this.cssService.getVariable(
      'collapsedSidebarWidth'
    );
    this.totalSidebarWidth = this.cssService.getVariable('totalSidebarWidth');

    const sidebarCollapsed = this.menuService.isMenuCollapsed(MenuID.ADMIN);
    this.slideSidebarOver = combineLatestObservable([
      sidebarCollapsed,
      this.windowService.isXsOrSm(),
    ]).pipe(
      map(([collapsed, mobile]) => collapsed || mobile),
      startWith(true)
    );

    if (this.router.url === getPageInternalServerErrorRoute()) {
      this.shouldShowRouteLoader = false;
    }
  }

  ngAfterViewInit() {
    let url = this.router.url;
    if (url) {
      if (
        url.includes('hackathon-view') ||
        url.includes('search') ||
        url.includes('event') ||
        url.includes('ariaform') ||
        url.includes('news') ||
        url.includes('announcement') ||
        url.includes('cafemenu') ||
        url.includes('community-list') ||
        url.includes('communities') ||
        url.includes('collections') ||
        url.includes('access-control')
      ) {
        this.showmodule = false;
      } else if (url.includes('hackathon')) {
        this.showhackathon = true;
        this.cdRef.detectChanges();
      } else {
        this.showmodule = true;
      }
    }
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        if (event.url) {
          let url = event.url.replace('/', '');
          if (
            url.includes('hackathon-view') ||
            url.includes('search') ||
            url.includes('event') ||
            url.includes('ariaform') ||
            url.includes('news') ||
            url.includes('announcement') ||
            url.includes('cafemenu') ||
            url.includes('community-list') ||
            url.includes('communities') ||
            url.includes('collections') ||
            url.includes('access-control')
          ) {
            this.showmodule = false;
          } else if (url.includes('hackathon')) {
            this.showhackathon = true;
          } else {
            this.showmodule = true;
          }
        }
      });
  }
}
