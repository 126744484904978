<a
  *ngIf="isVisible | async"
  class="d-flex flex-row"
  [routerLink]="[searchLink]"
  [queryParams]="changeQueryParams"
  queryParamsHandling="merge"
>
  <span class="filter-value px-1 com-sm clr-dark">{{ filterValue.label }}</span>
  <span class="float-right filter-value-count ml-auto">
    <!-- <span class="badge badge-secondary badge-pill">{{filterValue.count}}</span>-->
  </span>
</a>
