<div
  ngbDropdown
  class="navbar-nav"
  *ngIf="moreThanOneLanguage"
  display="dynamic"
  placement="bottom-right"
>
  <a
    href="javascript:void(0);"
    role="button"
    [attr.aria-label]="'nav.language' | translate"
    [title]="'nav.language' | translate"
    class="px-3"
    (click)="$event.preventDefault()"
    ngbDropdownToggle
    tabindex="0"
  >
    <!-- <img loading="lazy" class="globe-img"src="assets/images/language.svg"> -->
    <i class="fa fa-globe globe-icon" aria-hidden="true"></i>
  </a>
  <ul
    ngbDropdownMenu
    class="lang-drop"
    [attr.aria-label]="'nav.language' | translate"
  >
    <li
      class="dropdown-item1"
      tabindex="0"
      #langSelect
      *ngFor="let lang of translate.getLangs()"
      (keyup.enter)="useLang(lang)"
      (click)="useLang(lang)"
      [class.active]="lang === translate.currentLang"
    >
      {{ langLabel(lang) }}
    </li>
  </ul>
</div>
