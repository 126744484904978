<header class="shadow-sm" *ngIf="isLoggedIn | async">
  <div class="top-header">
    <div class="logo">
      <a routerLink="/home">
        <img loading="lazy" src="assets/images/logo.png" alt="logo" />
        <span
          style="
            font-size: 11px;
            font-family: 'NotoSans-Bold';
            position: absolute;
            top: 25px;
            left: 39px;
            width: 300px;
            color: var(--blue-color);
          "
          >Enterprise Knowledge Management
        </span>
      </a>
    </div>
    <div class="search-bar">
      <!-- <input type="text" class="form-control" placeholder="search" />
      <img loading="lazy"
        src="assets/images/search_icon.png"
        alt="search icon"
        class="search-icon"
      /> -->
      <div class="bell-icon">
        <img loading="lazy" src="assets/images/bell.png" alt="bell-icon" />
      </div>
    </div>
  </div>
</header>
