<div class="btn-group" data-toggle="buttons">
  <!-- <a
    *ngIf="isToShow(viewModeEnum.ListElement)"
    routerLink="."
    [queryParams]="{ view: 'list' }"
    queryParamsHandling="merge"
    (click)="switchViewTo(viewModeEnum.ListElement)"
    [class.active]="currentMode === viewModeEnum.ListElement"
    class="btn-circle btn-xl mr-1 list-grid mybtn-shadow"
    [attr.data-test]="'list-view' | dsBrowserOnly"
  >
    <img loading="lazy"
      class="grid-img"
      src="assets/images/list-icon.svg"
      title="{{ 'search.view-switch.show-list' | translate }}"
    />
  </a>
  <a
    *ngIf="isToShow(viewModeEnum.GridElement)"
    routerLink="."
    [queryParams]="{ view: 'grid' }"
    queryParamsHandling="merge"
    (click)="switchViewTo(viewModeEnum.GridElement)"
    [class.active]="currentMode === viewModeEnum.GridElement"
    class="btn btn-circle btn-xl mr-1 list-grid mybtn-shadow"
    [attr.data-test]="'grid-view' | dsBrowserOnly"
  >
    <img loading="lazy"
      class="grid-img"
      src="assets/images/grid-icon.svg"
      title="{{ 'search.view-switch.show-grid' | translate }}"
    />
  </a> -->
  <a
    *ngIf="isToShow(viewModeEnum.DetailedListElement)"
    routerLink="."
    [queryParams]="{ view: 'detailed' }"
    queryParamsHandling="merge"
    (click)="switchViewTo(viewModeEnum.DetailedListElement)"
    routerLinkActive="active"
    [class.active]="currentMode === viewModeEnum.DetailedListElement"
    class="btn btn-circle btn-xl pl-5"
    [attr.data-test]="'detail-view' | dsBrowserOnly"
  >
    <i
      class="far fa-square"
      title="{{ 'search.view-switch.show-detail' | translate }}"
    ></i>
  </a>
</div>
