import { Component, Input } from '@angular/core';
import { DSOSelectorComponent } from '../dso-selector.component';
import { SearchService } from '../../../../core/shared/search/search.service';
import { CollectionDataService } from '../../../../core/data/collection-data.service';
import { Observable } from 'rxjs';
import { getFirstCompletedRemoteData } from '../../../../core/shared/operators';
import { map } from 'rxjs/operators';
import { CollectionSearchResult } from '../../../object-collection/shared/collection-search-result.model';
import { SearchResult } from '../../../search/models/search-result.model';
import { DSpaceObject } from '../../../../core/shared/dspace-object.model';
import {
  buildPaginatedList,
  PaginatedList,
} from '../../../../core/data/paginated-list.model';
import { followLink } from '../../../utils/follow-link-config.model';
import { RemoteData } from '../../../../core/data/remote-data';
import { hasValue, isNotEmpty } from '../../../empty.util';
import { NotificationsService } from '../../../notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { Collection } from '../../../../core/shared/collection.model';
import { DSONameService } from '../../../../core/breadcrumbs/dso-name.service';
import { FindListOptions } from '../../../../core/data/find-list-options.model';
import { RequestEntryState } from 'src/app/core/data/request-entry-state.model';

@Component({
  selector: 'ds-authorized-collection-selector',
  styleUrls: ['../dso-selector.component.scss'],
  templateUrl: '../dso-selector.component.html',
})
/**
 * Component rendering a list of collections to select from
 */
export class AuthorizedCollectionSelectorComponent extends DSOSelectorComponent {
  /**
   * If present this value is used to filter collection list by entity type
   */
  @Input() entityType: string;

  constructor(
    protected searchService: SearchService,
    protected collectionDataService: CollectionDataService,
    protected notifcationsService: NotificationsService,
    protected translate: TranslateService,
    protected dsoNameService: DSONameService
  ) {
    super(searchService, notifcationsService, translate, dsoNameService);
  }

  /**
   * Get a query to send for retrieving the current DSO
   */
  getCurrentDSOQuery(): string {
    return this.currentDSOId;
  }

  /**
   * Perform a search for authorized collections with the current query and page
   * @param query Query to search objects for
   * @param page  Page to retrieve
   */
  search(
    query: string,
    page: number
  ): Observable<RemoteData<PaginatedList<SearchResult<DSpaceObject>>>> {
    console.log('inside search method');
    let searchListService$: Observable<RemoteData<PaginatedList<Collection>>> =
      null;
    const findOptions: FindListOptions = {
      currentPage: page,
      elementsPerPage: this.defaultPagination.pageSize,
    };

    if (this.entityType) {
      // console.log('&&&&&&&&&&&&&&&');
      searchListService$ =
        this.collectionDataService.getAuthorizedCollectionByEntityType(
          query,
          this.entityType,
          findOptions
        );
    } else {
      // console.log('inside else loop');
      searchListService$ = this.collectionDataService.getAuthorizedCollection(
        query,
        findOptions,
        true,
        false,
        followLink('parentCommunity')
      );
      var regex = /^[0-9!@#$%^&*()_-]+$/;
      // console.log(query);
      // debugger;
      if ((query !== '' || query !== null) && regex.test(query)) {
        // console.log('if loop for query');

        // this.notifcationsService.error('1234567');
        return searchListService$.pipe(
          getFirstCompletedRemoteData(),
          map(
            (rd) => (
              // console.log('rd', rd),
              (rd.state = RequestEntryState.Error),
              (rd.errorMessage = 'Special characters are not allowed'),
              Object.assign(
                new RemoteData(
                  null,
                  null,
                  null,

                  RequestEntryState.Error,
                  rd.errorMessage,
                  undefined,
                  0
                ),

                {
                  payload:
                    rd.payload === undefined &&
                    rd.state === 'Error' &&
                    rd.statusCode === 0
                      ? buildPaginatedList(
                          null,

                          null
                        )
                      : null,
                }
              )
            )
          )
        );
      } else {
        // console.log('else ');
        return searchListService$.pipe(
          getFirstCompletedRemoteData(),
          map((rd) =>
            // console.log('rd', rd),
            Object.assign(new RemoteData(null, null, null, null), rd, {
              payload: hasValue(rd.payload)
                ? buildPaginatedList(
                    rd.payload.pageInfo,

                    rd.payload.page.map((col) =>
                      Object.assign(new CollectionSearchResult(), {
                        indexableObject: col,
                      })
                    )
                  )
                : null,
            })
          )
        );
      }
    }
  }
}
