import { Injectable } from '@angular/core';

import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { Observable } from 'rxjs';
import { EPerson } from 'src/app/core/eperson/models/eperson.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

/**
 * Prevent unauthorized activating and loading of routes when the current authenticated user doesn't have administrator
 * rights to the {@link Site}
 */
@Injectable({
  providedIn: 'root',
})
export class EkmAdministratorGuard {
  user$: Observable<EPerson>;
  hasRights: boolean;
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router
  ) {
    this.user$ = this.authService.getAuthenticatedUserFromStore();
    this.user$.subscribe((data) => {
      console.log(data);

      this.http
        .get(
          `${environment.rest.baseUrl}/api/eperson/epersons/${data.uuid}/groups`
        )
        .subscribe({
          next: (res) => {
            res['_embedded']['groups'].forEach((element) => {
              if (element['name'] === 'EKM_Admin') {
                element['id'] === environment.ekmGroup.groupId
                  ? (this.hasRights = true)
                  : (this.hasRights = false);
              }
            });
          },
          error: () => {},
        });
    });
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.hasRights) {
      return true;
    } else {
      this.router.navigate(['/403']);
    }
  }
}
