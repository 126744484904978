import { Component } from '@angular/core';

import { Collection } from '../../../core/shared/collection.model';
import { AbstractListableElementComponent } from '../../object-collection/shared/object-collection-element/abstract-listable-element.component';
import { ViewMode } from '../../../core/shared/view-mode.model';
import { listableObjectComponent } from '../../object-collection/shared/listable-object/listable-object.decorator';
import { HttpClient } from '@angular/common/http';
import { HALEndpointService } from 'src/app/core/shared/hal-endpoint.service';
import { NotificationsService } from '../../notifications/notifications.service';

@Component({
  selector: 'ds-collection-list-element',
  styleUrls: ['./collection-list-element.component.scss'],
  templateUrl: './collection-list-element.component.html',
})
/**
 * Component representing list element for a collection
 */
@listableObjectComponent(Collection, ViewMode.ListElement)
export class CollectionListElementComponent extends AbstractListableElementComponent<Collection> {
  baseUrl: string;
  constructor(
    private http: HttpClient,
    private halService: HALEndpointService,
    private notificationsService: NotificationsService
  ) {
    super();
    this.baseUrl = halService.getBaseUrl();
  }
  delColl(id: string) {
    this.http
      .delete(`${this.baseUrl}/api/core/collections/${id}`)
      .subscribe((data) => {
        console.log(data);
        if (data == null) {
          this.notificationsService.success('folder deleted successfully');
        } else {
          this.notificationsService.error('folder not deleted');
        }
      });
  }

  editColl(newVal: string, collectionID: string) {
    let body = [
      {
        op: 'replace',
        path: '/metadata/dc.title',
        value: {
          value: newVal,
          language: null,
        },
      },
    ];
    this.http
      .patch(`${this.baseUrl}/api/core/collections/${collectionID}`, body)
      .subscribe((data) => {
        console.log(data);
        if (data !== null && data['error'] == undefined) {
          this.notificationsService.success('folder created successfully');
        } else {
          this.notificationsService.error('folder not created');
        }
        document.getElementById('closeModal').click();
      });
  }
}
