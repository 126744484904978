<!-- <ds-themed-loading
  *ngIf="(loading | async) || (isAuthenticated | async)"
  class="m-5"
></ds-themed-loading> -->
<div
  *ngIf="!(loading | async) && !(isAuthenticated | async)"
  class="p-0 login-container"
>
  <ng-container *ngFor="let authMethod of authMethods | async; let i = index">
    <!-- <div *ngIf="i === 1" class="text-center mt-2">
      <span class="align-middle">{{"login.form.or-divider" | translate}}</span>
    </div> -->
    <ds-log-in-container
      [authMethod]="authMethod"
      [isStandalonePage]="isStandalonePage"
    ></ds-log-in-container>
  </ng-container>

  <!-- <div class="dropdown-divider"></div> -->
  <!-- <div class="row">
  <div class="col-6">
  <a class="dropdown-item login-register" *ngIf="canRegister$ | async" [routerLink]="[getRegisterRoute()]" [attr.data-test]="'register' | dsBrowserOnly">{{"login.form.new-user" | translate}}</a>
  </div>
  <div class="col-6">
  <a class="dropdown-item login-forget-pass" [routerLink]="[getForgotRoute()]" [attr.data-test]="'forgot' | dsBrowserOnly">{{'forgot-email.form.header' | translate}}</a>
  </div>
</div> -->
</div>
