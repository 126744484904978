<div
  class="mb-0 clamp-{{ background }}--{{ lines }} min-{{ minLines }} {{
    type
  }} {{ fixedHeight ? 'fixedHeight' : 'fixedHeight' }}"
>
  <div #content class="content">
    <ng-content></ng-content>
  </div>
  <!-- <button class="btn btn-link p-0 expandButton" dsDragClick (actualClick)="toggle()">
    <i class="fas fa-angle-down"></i>
    <span class="ml-1">{{ 'item.truncatable-part.show-more' | translate }}</span>
  </button> -->
  <button
    class="btn btn-link p-0 collapseButton"
    dsDragClick
    (actualClick)="toggle()"
    *ngIf="expand && expandable"
  >
    <i class="fas fa-angle-up"></i>
    <span class="ml-1">{{
      "item.truncatable-part.show-less" | translate
    }}</span>
  </button>
</div>
