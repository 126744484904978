<div class="flex-item mb-2" *ngIf="hideCommonFolder(dsoTitle)">
  <div *ngIf="showThumbnails" class="">
    <!-- <a
      *ngIf="linkType != linkTypes.None"
      [target]="linkType == linkTypes.ExternalLink ? '_blank' : '_self'"
      rel="noopener noreferrer"
      [routerLink]="['/collections/', dso.id]"
      class="card-img-top"
    >
      <ds-thumbnail
        [thumbnail]="(dso.logo | async)?.payload"
        [limitWidth]="false"
      >
      </ds-thumbnail
    ></a> -->
    <img
      loading="lazy"
      class="mr-2"
      src="assets/images/rep_folder_icon.svg"
      style="max-width: unset"
    />
  </div>

  <div [ngClass]="showThumbnails ? 'col-9 pl-0' : 'col-md-12'">
    <!-- <div class="d-flex" style="line-height: 1">
      <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
    </div> -->
    <a
      *ngIf="linkType != linkTypes.None"
      [target]="linkType == linkTypes.ExternalLink ? '_blank' : '_self'"
      rel="noopener noreferrer"
      [routerLink]="['/collections/' + dso.id]"
      class="lead align-heading text-truncate d-block all-title"
      ><span>{{ dsoTitle }}</span></a
    >
    <span *ngIf="linkType == linkTypes.None" class="lead all-title">{{
      dsoTitle
    }}</span>
    <!-- <div *ngIf="dso.shortDescription" class="com-md abstract-text" >{{firstMetadataValue('dc.description.abstract')}}</div> -->

    <div *ngIf="dso.shortDescription" class="com-md abstract-text">
      {{ dso.shortDescription }}
    </div>
  </div>
</div>
