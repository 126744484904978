<div class="page-size-settings">
  <div class="accordion sort_accordian" id="accordionExample">
    <div class="card sort-item">
      <div class="card-header sort-header" id="headingfour">
        <h2 class="mb-0">
          <button
            *ngIf="pageSize == 0"
            class="btn sort-btn sort-drop collapsed"
            type="button"
            (click)="isCollapsed()"
            [id]="'search-sidebar-rpp'"
          >
            {{ "result.per.page" | translate }}
            <!-- {{pageSize == 0?'result.per.page' | translate:pageSize }} -->
          </button>
          <button
            *ngIf="pageSize > 0"
            class="btn sort-btn sort-drop collapsed"
            type="button"
            (click)="isCollapsed()"
            (change)="reloadRPP($event)"
          >
            {{ rpp }}
          </button>
        </h2>
      </div>
      <div
        id="collapsefour"
        class="collapse"
        [class]="isCollapse ? 'show' : ''"
        aria-labelledby="headingfour"
        data-parent="#accordionExample"
      >
        <div class="card-body p-0 result_page_list">
          <option
            (click)="isCollapsed(true); reloadRPP($event)"
            *ngFor="
              let pageSizeOption of (paginationOptions$ | async).pageSizeOptions
            "
            [value]="pageSizeOption"
            [selected]="
              pageSizeOption === +(paginationOptions$ | async).pageSize
                ? 'selected'
                : null
            "
          >
            {{ pageSizeOption }}
          </option>
        </div>
      </div>
    </div>
  </div>

  <!-- <ds-sidebar-dropdown
            [id]="'search-sidebar-rpp'"
            [label]="'search.sidebar.settings.rpp'"
            (change)="reloadRPP($event)"
    >
        <option *ngFor="let pageSizeOption of (paginationOptions$ | async).pageSizeOptions"
                [value]="pageSizeOption"
                [selected]="pageSizeOption === +((paginationOptions$ | async).pageSize) ? 'selected': null">
            {{pageSizeOption}}
        </option>
    </ds-sidebar-dropdown> -->
</div>
