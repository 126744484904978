import { Component, Inject } from '@angular/core';
import { SearchResultListElementComponent } from '../search-result-list-element.component';
import { Community } from '../../../../core/shared/community.model';
import { CommunitySearchResult } from '../../../object-collection/shared/community-search-result.model';
import { ViewMode } from '../../../../core/shared/view-mode.model';
import { listableObjectComponent } from '../../../object-collection/shared/listable-object/listable-object.decorator';
import { TruncatableService } from 'src/app/shared/truncatable/truncatable.service';
import { DSONameService } from 'src/app/core/breadcrumbs/dso-name.service';
import { APP_CONFIG, AppConfig } from 'src/config/app-config.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'ds-community-search-result-list-element',
  styleUrls: [
    '../search-result-list-element.component.scss',
    'community-search-result-list-element.component.scss',
  ],
  templateUrl: 'community-search-result-list-element.component.html',
})
/**
 * Component representing a community search result in list view
 */
@listableObjectComponent(CommunitySearchResult, ViewMode.ListElement)
export class CommunitySearchResultListElementComponent extends SearchResultListElementComponent<
  CommunitySearchResult,
  Community
> {
  /**
   * Display thumbnails if required by configuration
   */
  showThumbnails: boolean;
  baseUrl = environment.rest?.baseUrl;
  public constructor(
    private router: Router,
    protected truncatableService: TruncatableService,
    protected dsoNameService: DSONameService,
    private http: HttpClient,
    @Inject(APP_CONFIG) protected appConfig?: AppConfig
  ) {
    super(truncatableService, dsoNameService, appConfig);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.showThumbnails = this.appConfig.browseBy.showThumbnails;
  }
  goToUrl(dso: any) {
    console.log(dso)
    // this.http.get(`${this.baseUrl}/api/ParentcommId/${id}`).subscribe((x: any) => {
    //   if (x['Parent_id']) {
    //     this.router.navigate(['/community-list/' + x['Parent_id']]);
    //   } else {
    //     this.router.navigate(['/community-list/']);
    //   }
    // });
    this.router.navigate(['/community-list/' + dso.id], {
      queryParams: {
        isSearch: true,
        folderName: dso._name
      }
    });
  }
}
