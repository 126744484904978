<div>
  <div class="filters ft-overflow-auto py-2">
    <ds-search-facet-selected-option
      *ngFor="let value of selectedValues$ | async"
      [selectedValue]="value"
      [filterConfig]="filterConfig"
      [selectedValues$]="selectedValues$"
      [inPlaceSearch]="inPlaceSearch"
    ></ds-search-facet-selected-option>
    <ng-container *ngFor="let page of (filterValues$ | async)?.payload">
      <div [@facetLoad]="animationState">
        <ds-search-facet-option
          *ngFor="let value of page.page; trackBy: trackUpdate"
          [filterConfig]="filterConfig"
          [filterValue]="value"
          [selectedValues$]="selectedValues$"
          [inPlaceSearch]="inPlaceSearch"
        ></ds-search-facet-option>
      </div>
    </ng-container>
  </div>
  <div class="clearfix toggle-more-filters">
    <a
      class="float-left show-more"
      *ngIf="!(isLastPage$ | async)"
      (click)="showMore()"
      href="javascript:void(0);"
    >
      {{ "search.filters.filter.show-more" | translate }}
    </a>
    <a
      class="float-right show-more"
      *ngIf="(currentPage | async) > 1"
      (click)="showFirstPageOnly()"
      href="javascript:void(0);"
    >
      {{ "search.filters.filter.show-less" | translate }}
    </a>
  </div>

  <ds-filter-input-suggestions
    [suggestions]="filterSearchResults | async"
    [placeholder]="
      'search.filters.filter.' + filterConfig.name + '.placeholder' | translate
    "
    [label]="
      'search.filters.filter.' + filterConfig.name + '.label' | translate
    "
    [action]="currentUrl"
    [name]="filterConfig.paramName"
    [(ngModel)]="filter"
    (submitSuggestion)="onSubmit($event)"
    (clickSuggestion)="onClick($event)"
    (findSuggestions)="findSuggestions($event)"
    ngDefaultControl
  ></ds-filter-input-suggestions>
</div>
