import { Component, Inject, Injector } from '@angular/core';
import { renderStartsWithFor, StartsWithType } from '../starts-with-decorator';
import { StartsWithAbstractComponent } from '../starts-with-abstract.component';
import { hasValue } from '../../empty.util';
import { MenuSection } from '../../menu/menu-section.model';
import { MenuID } from '../../menu/menu-id.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from '../../menu/menu.service';
import { PaginationService } from 'src/app/core/pagination/pagination.service';

/**
 * A switchable component rendering StartsWith options for the type "Text".
 */
@Component({
  selector: 'ds-starts-with-text',
  styleUrls: ['./starts-with-text.component.scss'],
  templateUrl: './starts-with-text.component.html',
})
@renderStartsWithFor(StartsWithType.text)
export class StartsWithTextComponent extends StartsWithAbstractComponent {
  subSections: MenuSection[];

  menuID = MenuID.PUBLIC;
  id: string;
  url: string;
  link: string;
  public constructor(
    @Inject('startsWithOptions') public startsWithOptions: any[],
    @Inject('paginationId') public paginationId: string,
    protected paginationService: PaginationService,
    protected route: ActivatedRoute,
    protected router: Router,
    @Inject('sectionDataProvider') public menuSection,
    public menuService: MenuService,
    public injector: Injector
  ) {
    super(
      startsWithOptions,
      paginationId,
      paginationService,
      route,
      router,
      menuSection,
      menuService,
      injector
    );
  }

  ngOnInit() {
    if (window.location.host == 'localhost:4000') {
      // console.log("local");
      this.url = window.location.pathname;
    } else {
      this.link = window.location.pathname;
      this.url = this.link.includes('/ecm-test')
        ? this.link.replace('/ecm-test', '')
        : this.link.replace('/ecm', '');
      // console.log(this.url);
      // this.url = ( (this.link.replace("/ecm-test","")) || (this.link.replace("/ecm","")) );
      // console.log(this.url);
    }
    //this.url = window.location.pathname;
    // console.log('url: ', this.url);

    //  console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
    //  console.log("service ********" + this.menuService.getSubSection())
    this.subSections = this.menuService.getSubSection();

    //  const tempArr = [];
    //  this.subSections.forEach( (menu : MenuSection)=>{
    //     tempArr.push('menu.section.'+ menu.id);
    //      console.log('menu: ', menu);
    //  });
    //  console.log('tempArr: ', tempArr);
    //  this.subSections = tempArr;
    // console.log('subSections: ', this.subSections);

    this.subSections.forEach((menu: MenuSection) => {
      this.id = menu.id;
      //  console.log(this.id);
    });
    //console.log(MenuID);
    //this.subSections$.subscribe((data => {console.log('Testing : '+data)}));
    super.ngOnInit();
  }
  /**
   * Get startsWith as text;
   */
  getStartsWith() {
    if (hasValue(this.startsWith)) {
      return this.startsWith;
    } else {
      return '';
    }
  }

  /**
   * Add/Change the url query parameter startsWith using the local variable
   */
  setStartsWithParam(resetPage = true) {
    if (this.startsWith === '0-9') {
      this.startsWith = '0';
    }
    super.setStartsWithParam(resetPage);
  }

  /**
   * Checks whether the provided option is equal to the current startsWith
   * @param option
   */
  isSelectedOption(option: string): boolean {
    if (this.startsWith === '0' && option === '0-9') {
      return true;
    }
    return option === this.startsWith;
  }

  redirectTo(link) {
    // console.log('link');
    this.router.navigateByUrl(link);
  }
}
