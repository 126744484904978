<div
  class="simple-view-element m-0 file-size"
  [class.d-none]="
    hideIfNoTextContent && content.textContent.trim().length === 0
  "
>
  <!-- <h5
    class="simple-view-element-header text-md-bold mb-0 ml-2"
    style="font-size: 12px"
    *ngIf="label"
  >
    {{ label }}
  </h5> -->
  <div #content class="simple-view-element-body">
    <ng-content></ng-content>
  </div>
</div>
