import { InjectionToken } from '@angular/core';
import { makeStateKey } from '@angular/platform-browser';
import { Config } from './config.interface';
import { ServerConfig } from './server-config.interface';
import { CacheConfig } from './cache-config.interface';
import { INotificationBoardOptions } from './notifications-config.interfaces';
import { SubmissionConfig } from './submission-config.interface';
import { FormConfig } from './form-config.interfaces';
import { LangConfig } from './lang-config.interface';
import { ItemConfig } from './item-config.interface';
import { CollectionPageConfig } from './collection-page-config.interface';
import { ThemeConfig } from './theme.model';
import { AuthConfig } from './auth-config.interfaces';
import { UIServerConfig } from './ui-server-config.interface';
import { MediaViewerConfig } from './media-viewer-config.interface';
import { BrowseByConfig } from './browse-by-config.interface';
import { BundleConfig } from './bundle-config.interface';
import { ActuatorsConfig } from './actuators.config';
import { InfoConfig } from './info-config.interface';
import { CommunityListConfig } from './community-list-config.interface';
import { HomeConfig } from './homepage-config.interface';
import { MarkdownConfig } from './markdown-config.interface';
import { ValidationConfig } from './validation-config.interface';
import { DefaultValidationConfig } from './default-validation-config.interface';
import { LogoValidation } from './logo-validation.config';
import { KeyConfig } from './key-config';
import { ItemPageYear } from './itempage-year config';
import { UtilizationConfig } from './uitilization-config.interface';
import { HackathonAdminConfig } from './hackathon-admin-config.interface';
import { TrainingConfig } from './training-config';
import { EkmConfig } from './ekmadmin-config';
import { QualityManualConfig } from './quality-manual-config.interface';

interface AppConfig extends Config {
  ui: UIServerConfig;
  rest: ServerConfig;
  production: boolean;
  cache: CacheConfig;
  auth?: AuthConfig;
  form: FormConfig;
  notifications: INotificationBoardOptions;
  submission: SubmissionConfig;
  debug: boolean;
  defaultLanguage: string;
  languages: LangConfig[];
  browseBy: BrowseByConfig;
  communityList: CommunityListConfig;
  homePage: HomeConfig;
  item: ItemConfig;
  collection: CollectionPageConfig;
  themes: ThemeConfig[];
  mediaViewer: MediaViewerConfig;
  bundle: BundleConfig;
  actuators: ActuatorsConfig;
  info: InfoConfig;
  markdown: MarkdownConfig;
  validation: ValidationConfig[];
  defaultValidation: DefaultValidationConfig;
  logoValidation: LogoValidation;
  key: KeyConfig;
  itemPageYear: ItemPageYear;
  uitilization: UtilizationConfig;
  hackathonAdmin: HackathonAdminConfig;
  trainingGroup: TrainingConfig;
  ekmGroup: EkmConfig;
  qualityManual: QualityManualConfig;
}

/**
 * Injection token for the app configuration.
 * Provided in {@link InitService.providers}.
 */
const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');

const APP_CONFIG_STATE = makeStateKey<AppConfig>('APP_CONFIG_STATE');

export { AppConfig, APP_CONFIG, APP_CONFIG_STATE };
