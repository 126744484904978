import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as coedata from '../../app/coedata.json';
import { element } from 'prop-types';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../shared/notifications/notifications.service';

@Component({
  selector: 'ds-coe',
  templateUrl: './coe.component.html',
  styleUrls: ['./coe.component.scss'],
})
export class CoeComponent implements OnInit {
  public isBtnActive: number = 1;
  codeList: any[];
  deptList: any[];
  coeId = '';
  deptId = '';
  deps: any;
  isReadMore:boolean=false;
  showReadMoreButton:boolean=false;
  depTitle: string;
  depAboutUs: string;
  depAboutUsShort:string;
  depUsers: any[];
  depInitiative: any[];
  bgClasses: ['one', 'two', 'tree', 'four'];

  coeTitle: string;
  coeAboutUs: string;
  coeUsers: any[];
  coeInitiative: any[];
  isSeeAllVisible: boolean=false;
  baseurl: string = environment.rest.baseUrl;
  initiativeList:any=[];
  teamsList:any=[];
  constructor(
    private router: Router,
    private http: HttpClient,
    private notificationService: NotificationsService,
    private cdRef: ChangeDetectorRef
    ) {
      this.notificationService.getCoeNames().subscribe((res:any) =>{
        if(res){
          this.coeId = res.coeid;
          this.deptId = res.depid;
        } else{
          this.coeId = 'coe1';
          this.deptId = 'dep1';
        }
      });
    }

  ngOnInit(): void {
    this.getdata();
    this.getCoeInitiatives();
    this.getCoeTeams();
  }

  getdata() {
    // type DepsType = {
    //   title: string;
    //   about: number;
    // };
    const coeData: any[] = (coedata as any).default;
    this.codeList = coeData;
    if (this.coeId == 'coe1' || this.coeId == 'coe8' || this.coeId == 'coe10') {
      this.deptList = coeData[this.coeId]['deps'];
      this.depTitle = coeData[this.coeId]['deps'][this.deptId]['title'];
      this.depAboutUs = coeData[this.coeId]['deps'][this.deptId]['about'];

      this.depUsers = coeData[this.coeId]['deps'][this.deptId]['team'];
      this.depInitiative =
        coeData[this.coeId]['deps'][this.deptId]['initiative'];
    } else {
      this.deptList = [];
      this.depTitle = coeData[this.coeId]['title'];
      this.depAboutUs = coeData[this.coeId]['about'];
      this.depUsers = coeData[this.coeId]['team'];
      this.depInitiative = coeData[this.coeId]['initiative'];
    }
    this.depAboutUsShort=this.depAboutUs;

    var itemsLength = Object.keys(this.depInitiative).length;
    if(itemsLength>5){
      this.isSeeAllVisible=true;
    }
    else{
      this.isSeeAllVisible=false;
    }
    this.readMoreLogic();
  }
  readMore(){
    this.isReadMore=!this.isReadMore;
    if(this.isReadMore){
     this.depAboutUs=this.depAboutUsShort;
    }
    else{
     this.readMoreLogic();
    }
  }
  onCoeClick(id: string) {
    this.coeId = id;
    this.deptId = 'dep1';
    this.getdata();
    this.getCoeInitiatives();
    this.getCoeTeams();
  }

  myFunction(id: any) {
    this.deptId = id;
    this.getdata();
    this.getCoeInitiatives();
    this.getCoeTeams();
  }

  changeCoe(id: any) {
    this.coeId = 'coe' + id;

    type CodeType = {
      title: string;
      about: number;
    };

    const coes: CodeType[] = (coedata as any).default;

    this.coeTitle = coes[this.coeId]['title'];
    this.coeAboutUs = coes[this.coeId]['about'];
    this.coeUsers = coes[this.coeId]['team'];
    this.coeInitiative = coes[this.coeId]['initiative'];
    // console.log(this.coeInitiative);
  }

  goToSeeAll() {
    this.router.navigate([`coeSeeAll/{{ coeId }}/{{ deptId }}`]);
  }

  goToProfile(route, val) {
    this.router.navigate([`/${route}/${val}`]);
  }
  readMoreLogic(){
    this.showReadMoreButton=false;
    this.isReadMore=false;
    let arr=this.depAboutUs.split(" ",70);
      if(arr.length==70){
        this.isReadMore=false;
        this.showReadMoreButton=true;
        this.depAboutUs=arr.join(" ")+"...";
      }
  }
  getCoeInitiatives(){
    let employeeCoeType:any;let employeeCoeName:any;
    if(this.coeId == 'coe1'){
      employeeCoeType =  'COPE'; 
      if(this.deptId == 'dep1'){
        employeeCoeName = 'Program & product enablement';
      } else if(this.deptId == 'dep2'){
        employeeCoeName = 'Enterprise Consulting';
      } else if(this.deptId == 'dep3'){
        employeeCoeName = 'UI UX';
      } else if(this.deptId == 'dep4'){
        employeeCoeName = 'Process standards and Research';
      } else if(this.deptId == 'dep5'){
        employeeCoeName = 'Product Management';
      }
    } else if(this.coeId == 'coe2'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'Microsoft COE';
    } else if(this.coeId == 'coe3'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE JAVA';
    } else if(this.coeId == 'coe4'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE Testing';
    } else if(this.coeId == 'coe5'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE PMO';
    } else if(this.coeId == 'coe6'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE Sustenance Engineering';
    } else if(this.coeId == 'coe7'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'FCT';
    } else if(this.coeId == 'coe8'){
      employeeCoeType =  'SA'; 
      employeeCoeName = this.deptId == 'dep1' ? 'BOOST':'FINTECH';
    } else if(this.coeId == 'coe9'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'SAP COE';
    }else if(this.coeId == 'coe10'){
      employeeCoeType =  'EA'; 
      employeeCoeName = this.deptId == 'dep1' ? 'EA CoE':'Global Standards';
    }
    let obj={
      "coeType":  employeeCoeType,
      "coeName": employeeCoeName,
    }
    this.http.post(`${this.baseurl}/api/cope/coeInitiatives`,obj)
        .subscribe({
          next: (res: any) => {
            this.initiativeList = res;
            this.cdRef.detectChanges();
          },
        })
  }

  getCoeTeams(){
    let employeeCoeType:any;let employeeCoeName:any;
    if(this.coeId == 'coe1'){
      employeeCoeType =  'COPE'; 
      if(this.deptId == 'dep1'){
        employeeCoeName = 'Program & product enablement';
      } else if(this.deptId == 'dep2'){
        employeeCoeName = 'Enterprise Consulting';
      } else if(this.deptId == 'dep3'){
        employeeCoeName = 'UI UX';
      } else if(this.deptId == 'dep4'){
        employeeCoeName = 'Process standards and Research';
      } else if(this.deptId == 'dep5'){
        employeeCoeName = 'Product Management';
      }
    } else if(this.coeId == 'coe2'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'Microsoft COE';
    } else if(this.coeId == 'coe3'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE JAVA';
    } else if(this.coeId == 'coe4'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE Testing';
    } else if(this.coeId == 'coe5'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE PMO';
    } else if(this.coeId == 'coe6'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE Sustenance Engineering';
    } else if(this.coeId == 'coe7'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'FCT';
    } else if(this.coeId == 'coe8'){
      employeeCoeType =  'SA'; 
      employeeCoeName = this.deptId == 'dep1' ? 'BOOST':'FINTECH';
    } else if(this.coeId == 'coe9'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'SAP COE';
    }else if(this.coeId == 'coe10'){
      employeeCoeType =  'EA'; 
      employeeCoeName = this.deptId == 'dep1' ? 'EA CoE':'Global Standards';
    }
    let obj={
      "coeType":  employeeCoeType,
      "coeName": employeeCoeName,
    }
    this.http.post(`${this.baseurl}/api/cope/coeTeam`,obj)
        .subscribe({
          next: (res: any) => {
            this.teamsList = res;
            this.cdRef.detectChanges();
          },
        })
  }
  goToCoeDtlPage(value){
    this.notificationService.setCoeDetails(value);
  }
}
