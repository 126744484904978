<div>
  <div class="modal-header mb-0">
    {{
      "dso-selector." +
        action +
        "." +
        objectType.toString().toLowerCase() +
        ".head" | translate
    }}
    <button type="button" class="close" (click)="close()" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body pt-0">
    <h5 *ngIf="header" class="model-subhead">{{ header | translate }}</h5>
    <ds-dso-selector
      [currentDSOId]="dsoRD?.payload.uuid"
      [types]="selectorTypes"
      (onSelect)="selectObject($event)"
    ></ds-dso-selector>
  </div>
</div>
