<div
  class="card whats-new ecm-card"
  [routerLink]="[itemPageRoute]"
  style="box-shadow: 0 3px 8px 1px #385edb29"
>
  <div class="position-absolute ml-1">
    <ng-content></ng-content>
  </div>
  <!-- <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="[itemPageRoute]"
        class="card-img-top full-width" [attr.title]="'search.results.view-result' | translate"> -->
  <a
    *ngIf="linkType != linkTypes.None"
    [target]="linkType == linkTypes.ExternalLink ? '_blank' : '_self'"
    rel="noopener noreferrer"
    [routerLink]="[itemPageRoute]"
    class="card-img-top full-width thumbnil-place"
    [ngbTooltip]="dso.name"
  >
    <div>
      <ds-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="false">
      </ds-thumbnail>
    </div>

    <div class="card-body ecm-description" [ngbTooltip]="dso.name">
      <!-- <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
            <ds-access-status-badge [item]="dso"></ds-access-status-badge> -->
      <ds-truncatable [id]="dso.id">
        <ds-truncatable-part [id]="dso.id" [minLines]="3">
          <a class="card-des text-truncate">{{ dsoTitle }}</a>
        </ds-truncatable-part>
        <!-- <ds-truncatable-part [id]="dso.id" [minLines]="1" *ngIf="dso.hasMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*'])">
                    <p class="item-authors card-text text-muted com-md text-truncate">
                        <span *ngIf="dso.hasMetadata('dc.date.issued')" class="item-date item-info">{{firstMetadataValue('dc.date.issued')}}</span>
                        <span *ngFor="let author of allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']);" class="item-info">,
                            <span [innerHTML]="author"></span>
                        </span>
                    </p>
                </ds-truncatable-part> -->
        <!-- <ds-truncatable-part *ngIf="dso.hasMetadata('dc.description.abstract')" [id]="dso.id" [minLines]="3">
                    <p class="item-abstract card-text text-truncate">
                        <span [innerHTML]="firstMetadataValue('dc.description.abstract')" class="item-info"></span>
                    </p>
                </ds-truncatable-part> -->
      </ds-truncatable>
      <!-- <div *ngIf="linkType != linkTypes.None" class="text-center">
                <a [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="[itemPageRoute]"
                    class="lead btn btn-primary viewButton">{{ 'search.results.view-result' | translate}}</a>
            </div> -->
    </div>
  </a>
  <span *ngIf="linkType == linkTypes.None" class="card-img-top full-width">
    <div>
      <ds-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="false">
      </ds-thumbnail>
    </div>
  </span>
  <!-- <div class="card-body card-height">
        <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
        <ds-access-status-badge [item]="dso"></ds-access-status-badge>
        <ds-truncatable [id]="dso.id">
            <ds-truncatable-part [id]="dso.id" [minLines]="3">
                <a class="card-title search-grid-card card-des" [innerHTML]="firstMetadataValue('dc.title')"></a>
            </ds-truncatable-part>
            <ds-truncatable-part [id]="dso.id" [minLines]="1" *ngIf="dso.hasMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*'])">
                <p class="item-authors card-text text-muted com-md">
                    <span *ngIf="dso.hasMetadata('dc.date.issued')" class="item-date item-info">{{firstMetadataValue('dc.date.issued')}}</span>
                    <span *ngFor="let author of allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']);" class="item-info">,
                        <span [innerHTML]="author"></span>
                    </span>
                </p>
            </ds-truncatable-part>
            <ds-truncatable-part *ngIf="dso.hasMetadata('dc.description.abstract')" [id]="dso.id" [minLines]="3">
                <p class="item-abstract card-text">
                    <span [innerHTML]="firstMetadataValue('dc.description.abstract')" class="item-info"></span>
                </p>
            </ds-truncatable-part>
        </ds-truncatable> -->
  <!-- <div *ngIf="linkType != linkTypes.None" class="text-center">
            <a [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="[itemPageRoute]"
                class="lead btn btn-primary viewButton">{{ 'search.results.view-result' | translate}}</a>
        </div> -->
  <!-- </div> -->
  <ng-content></ng-content>
</div>
