<!--ecm thumbnail on explore page bug-->
<div class="d-flex flex-row browse-page">
  <!-- <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="[]" [queryParams]="getQueryParams()" [queryParamsHandling]="'merge'" [ngbTooltip]="object.value" class="lead text-truncate community-card-heading grid-card">
        {{object.value}}
    </a> -->
  <div class="col-auto d-none d-md-none d-lg-block initials-block">
    <!--end of bug code-->
    <div class="initials-container">
      <span class="auth-initials">{{ initialsname }}</span>
    </div>
  </div>
  <a
    *ngIf="linkType != linkTypes.None"
    [target]="linkType == linkTypes.ExternalLink ? '_blank' : '_self'"
    rel="noopener noreferrer"
    [routerLink]="[]"
    [queryParams]="getQueryParams()"
    [queryParamsHandling]="'merge'"
    class="lead text-truncate community-card-heading grid-card"
    ><span [ngbTooltip]="object.value">{{ object.value }}</span>
  </a>
  <span *ngIf="linkType == linkTypes.None" class="lead">
    {{ object.value }}
  </span>
  <span class="pr-2">&nbsp;</span>
  <span class="badge explore-badge-collection align-self-center mr-2">{{
    object.count
  }}</span>
</div>
