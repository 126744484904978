<ul class="navbar-nav" [ngClass]="{ 'mr-auto': (isXsOrSm$ | async) }">
  <li
    *ngIf="
      !(isAuthenticated | async) && !(isXsOrSm$ | async) && (showAuth | async)
    "
    class="nav-item"
    (click)="$event.stopPropagation()"
  >
    <div display="dynamic" class="remove-space">
      <!-- <button type="button" class="btn login-btn-blue"> -->
      <a
        routerLink="/login"
        class="btn login-btn-blue"
        style="color: white; text-decoration: none; margin-bottom: 2px"
        [attr.aria-label]="'nav.login' | translate"
      >
        {{ "nav.login" | translate }}
      </a>
      <!-- </button> -->
      <!-- <div class="loginDropdownMenu" [ngClass]="{'pl-3 pr-3': (loading | async)}" ngbDropdownMenu
           [attr.aria-label]="'nav.login' |translate">
        <ds-log-in
          [isStandalonePage]="false"></ds-log-in>
      </div> -->
    </div>
  </li>
  <li
    *ngIf="!(isAuthenticated | async) && (isXsOrSm$ | async)"
    class="nav-item"
  >
    <a routerLink="/login" routerLinkActive="active" class="remove-space">
      {{ "nav.login" | translate }}<span class="sr-only">(current)</span>
    </a>
  </li>
  <li
    *ngIf="
      (isAuthenticated | async) && !(isXsOrSm$ | async) && (showAuth | async)
    "
    class="nav-item"
  >
    <div
      ngbDropdown
      display="dynamic"
      placement="bottom-right"
      class="d-inline-block"
      @fadeInOut
    >
      <a
        href="javascript:void(0);"
        role="button"
        [attr.aria-label]="'nav.logout' | translate"
        (click)="$event.preventDefault()"
        [title]="'nav.logout' | translate"
        class="px-1"
        [attr.data-test]="'user-menu' | dsBrowserOnly"
        ngbDropdownToggle
      >
        <i class="fas fa-user-circle fa-lg fa-fw" style="color: #013b91"></i
      ></a>
      <div
        class="logoutDropdownMenu"
        ngbDropdownMenu
        [attr.aria-label]="'nav.logout' | translate"
      >
        <ds-user-menu></ds-user-menu>
      </div>
    </div>
  </li>
  <li *ngIf="(isAuthenticated | async) && (isXsOrSm$ | async)" class="nav-item">
    <a
      id="logoutLink"
      role="button"
      [attr.aria-label]="'nav.logout' | translate"
      [title]="'nav.logout' | translate"
      routerLink="/logout"
      routerLinkActive="active"
      class="px-1"
    >
      <i class="fas fa-user-circle fa-lg fa-fw"></i>
      <span class="sr-only">(current)</span>
    </a>
  </li>
</ul>
