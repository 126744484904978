<div class="jumbotron jumbotron-fluid">
  <div class="container">
    <div class="d-flex flex-wrap">
      <div>
        <h1 class="display-3">DSpace 7</h1>
        <p class="lead">
          DSpace is the world leading open source repository platform that
          enables organisations to:
        </p>
      </div>
    </div>
    <ul>
      <li>
        easily ingest documents, audio, video, datasets and their corresponding
        Dublin Core metadata
      </li>
      <li>
        open up this content to local and global audiences, thanks to the
        OAI-PMH interface and Google Scholar optimizations
      </li>
      <li>
        issue permanent urls and trustworthy identifiers, including optional
        integrations with handle.net and DataCite DOI
      </li>
    </ul>
    <p>
      Join an international community of
      <a
        href="https://wiki.lyrasis.org/display/DSPACE/DSpace+Positioning"
        target="_blank"
        >leading institutions using DSpace</a
      >.
    </p>
  </div>
</div>
