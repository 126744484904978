<div class="page-internal-server-error container mt-5 mb-5">
  <h1>500</h1>
  <h2>
    <small>{{ "500.page-internal-server-error" | translate }}</small>
  </h2>
  <br />
  <p>{{ "500.help" | translate }}</p>
  <br />
  <p class="text-center">
    <a href="home" class="btn btn-primary">{{
      "500.link.home-page" | translate
    }}</a>
  </p>
</div>
