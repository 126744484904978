<div class="container mt-4">
  <span class="project-repositiory">
    {{
      "bitstream.download.page"
        | translate : { bitstream: (bitstream$ | async)?.name }
    }}
  </span>
  <div class="pt-3">
    <button (click)="back()" class="btn btn-primary btn-sm pr-btn">
      <i class="fas fa-arrow-left"></i>
      {{ "bitstream.download.page.back" | translate }}
    </button>
  </div>
</div>
