<div class="mb-5 mt-3 px-3">
  <div class="row">
    <div
      class="row-with-sidebar row-offcanvas row-offcanvas-left"
      [@pushInOut]="(isSidebarCollapsed$ | async) ? 'collapsed' : 'expanded'"
    >
      <div
        id="{{ id }}-sidebar-content"
        class="col-12 pt-0 col-md-{{ sideBarWidth }} sidebar-content {{
          sidebarClasses | async
        }}"
      >
        <ng-container *ngTemplateOutlet="sidebarContent"></ng-container>
      </div>
      <div class="col-12 col-md-{{ 12 - sideBarWidth }}">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
