<div *ngIf="!(this.submitted$ | async); else waiting">
  <div class="modal-header">
    {{ "item.version.create.modal.header" | translate }}
    <button
      type="button"
      class="close"
      (click)="onModalClose()"
      aria-label="Close"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="pb-2">
      {{ "item.version.create.modal.text" | translate }}
      <span *ngIf="!firstVersion">
        {{
          "item.version.create.modal.text.startingFrom"
            | translate : { version: versionNumber }
        }}
      </span>
    </p>
    <div class="form-group">
      <label for="summary"
        >{{
          "item.version.create.modal.form.summary.label" | translate
        }}:</label
      >
      <input
        type="text"
        id="summary"
        class="form-control"
        [(ngModel)]="newVersionSummary"
        (keyup.enter)="onModalSubmit()"
        placeholder="{{
          'item.version.create.modal.form.summary.placeholder' | translate
        }}"
      />
      <!--      (keyup.enter)="$event.preventDefault(); $event.stopImmediatePropagation()"-->
    </div>
  </div>
  <div class="modal-footer space-children-mr">
    <button
      class="btn btn-outline-secondary btn-sm ml-0"
      type="button"
      (click)="onModalClose()"
      title="{{
        'item.version.create.modal.button.cancel.tooltip' | translate
      }}"
    >
      <i class="fas fa-times fa-fw"></i>
      {{ "item.version.create.modal.button.cancel" | translate }}
    </button>
    <button
      class="btn btn-success btn-sm ml-0"
      type="submit"
      (click)="onModalSubmit()"
      title="{{
        'item.version.create.modal.button.confirm.tooltip' | translate
      }}"
    >
      <i class="fas fa-check fa-fw"></i>
      {{ "item.version.create.modal.button.confirm" | translate }}
    </button>
  </div>
</div>

<ng-template #waiting>
  <div class="modal-header">
    {{ "item.version.create.modal.submitted.header" | translate }}
  </div>
  <div class="modal-body">
    <p>{{ "item.version.create.modal.submitted.text" | translate }}</p>
    <div class="d-flex justify-content-center">
      <ds-loading [showMessage]="false"></ds-loading>
    </div>
  </div>
</ng-template>
