<div *ngIf="menuVisible | async">
  <ul id="accordion" (click)="rmvCmntyList()">
    <li *ngIf="checkid">
      <!-- <a
        target="_blank"
        class="solo-tab"
        href="http://172.17.60.243/Reports/powerbi/BU%20Dashboard/ReBIT%20MIS?rs:embed=true"
        >ReBIT MIS</a
      > -->
      <!-- <a
        class="solo-tab"
        routerLink="mis"
        >ReBIT MIS</a
      > -->
    </li>
    <li>
      <a
        [class]="active == 'home' ? 'active-menu' : ''"
        (click)="activeState('home')"
        routerLink="/home"
        class="solo-tab"
        >Home
      </a>
    </li>
    <li class="menu-drop">
      <div class="menu-header">
        <a
          id="#open"
          class="collapsed"
          [class]="active == 'rebit' ? 'active-menu' : ''"
          role="button"
          data-toggle="collapse"
          href="#collapse-1"
          aria-controls="collapseExample1"
          (click)="activeState('rebit'); openArrow1 = !openArrow1"
          [attr.aria-expanded]="!openArrow1"
        >
          ReBIT
        </a>
      </div>
      <div
        id="collapseExample1"
        class="collapse"
        data-parent="#accordion"
        [ngbCollapse]="openArrow1"
      >
        <ul>
          <!-- <li [class]="active == 'aboutus' ? 'active-list' : ''">
            <a (click)="activeState('aboutus')">About Us</a>
          </li> -->
          <li [class]="active == 'vertical' ? 'active-list' : ''">
            <a (click)="activeState('vertical')" routerLink="/vertical"
              >Verticals</a
            >
          </li>
          <li [class]="active == 'projectrepo' ? 'active-list' : ''">
            <a (click)="activeState('projectrepo')" routerLink="/community-list"
              >Project Repository</a
            >
          </li>
          <li [class]="active == 'coe' ? 'active-list' : ''">
            <a (click)="activeState('coe')" routerLink="/coe"
              >Center of Excellence</a
            >
          </li>
          <!-- <li [class]="active == 'rebitessentials' ? 'active-list' : ''">
            <a
              (click)="activeState('rebitessentials')"
              routerLink="/underconstruction"
              >ReBIT Common</a
            >
          </li> -->
          <li [class]="active == 'photogallery' ? 'active-list' : ''">
            <a
              (click)="activeState('photogallery')"
              routerLink="communities/31c2a1c6-9c77-4237-bf00-ec8b7c4e6bab"
              >Photo Gallery</a
            >
          </li>
        </ul>
      </div>
    </li>
    <li>
      <a
        [class]="active == 'aria' ? 'active-menu' : ''"
        (click)="activeState('aria')"
        routerLink="/aria"
        class="solo-tab"
        >Aria
      </a>
    </li>

    <li>
      <a
        href="https://www.linkedin.com/learning-login/signin?account=144631970&authUUID=cYxl0%2F3bTw2JvMslASmtyQ%3D%3D&forceAccount=false&loginStepRetryCount=1&redirect=https%3A%2F%2Fwww%2Elinkedin%2Ecom%2Flearning%2F&email=AQGedzEQNKxPyAAAAYhsiTzVN3G8QImzHISikW81n3NBAq3NZB7C9NpZi5IqKQ7Tz3oyKoj1cDki7QO5qy8&bind=false"
        class="solo-tab"
        target="_blank"
      >
        Learning
      </a>
    </li>
    <!-- <li>
      <a
        href="https://rebit.org.in/business-leLearningsaders-forum"
        target="_blank"
        class="solo-tab"
        >Business Leader’s Forum
      </a>
    </li> -->
    <!-- <li class="menu-drop">
      <div class="menu-header">
        <a
          id="#open"
          class="collapsed"
          [class]="active == 'Corporate_center' ? 'active-menu' : ''"
          role="button"
          data-toggle="collapse"
          href="#collapse-5"
          aria-controls="collapseExample5"
          (click)="activeState('Corporate_center'); openArrow5 = !openArrow5"
          [attr.aria-expanded]="!openArrow5"
        >
          Corporate Center
        </a>
      </div>
      <div
        id="collapseExample5"
        class="collapse"
        data-parent="#accordion"
        [ngbCollapse]="openArrow5"
      >
        <ul>
          <li>
            <a
              href="#"
              [class]="active == 'hr' ? 'active-menu' : ''"
              (click)="activeState('hr')"
              routerLink="/underconstruction"
              >Human Resource
            </a>
          </li>
          <li>
            <a
              href="#"
              [class]="active == 'af' ? 'active-menu' : ''"
              (click)="activeState('af')"
              routerLink="/underconstruction"
              >Accounts & Finance
            </a>
          </li>
          <li>
            <a
              href="#"
              [class]="active == 'admin' ? 'active-menu' : ''"
              (click)="activeState('admin')"
              routerLink="/underconstruction"
              >Admin
            </a>
          </li>
        </ul>
      </div>
    </li> -->
    <!-- <li class="menu-drop">
      <div class="menu-header">
        <a
          id="#open"
          class="collapsed"
          [class]="active == 'collaboration' ? 'active-menu' : ''"
          role="button"
          data-toggle="collapse"
          href="#collapse-2"
          aria-controls="collapseExample2"
          (click)="activeState('collaboration'); openArrow2 = !openArrow2"
          [attr.aria-expanded]="!openArrow2"
        >
          Collaboration
        </a>
      </div>
      <div
        id="collapseExample2"
        class="collapse"
        data-parent="#accordion"
        [ngbCollapse]="openArrow2"
      >
        <ul>
          <li>
            <a
              href="#"
              [class]="active == 'chat' ? 'active-list' : ''"
              (click)="activeState('chat')"
              routerLink="/underconstruction"
              >Chat</a
            >
          </li>
          <li>
            <a
              href="#"
              [class]="active == 'chatbot' ? 'active-list' : ''"
              (click)="activeState('chatbot')"
              routerLink="/underconstruction"
              >Chatbot</a
            >
          </li>
        </ul>
      </div>
    </li> -->
    <li class="menu-drop">
      <div class="menu-header">
        <a
          id="#open"
          class="collapsed"
          [class]="active == 'quicklinks' ? 'active-menu' : ''"
          role="button"
          data-toggle="collapse"
          href="#collapse-3"
          (click)="activeState('quicklinks'); openArrow3 = !openArrow3"
          [attr.aria-expanded]="!openArrow3"
          aria-controls="collapseExample3"
        >
          Quick Links
        </a>
      </div>
      <div
        id="collapseExample3"
        class="collapse"
        data-parent="#accordion"
        [ngbCollapse]="openArrow3"
      >
        <ul>
          <li><a href="https://ppm.rebit.org.in/" target="_blank">PPM</a></li>
          <li>
            <a href="https://mypayroll.paysquare.com/" target="_blank"
              >Paysquare</a
            >
          </li>
          <li>
            <a
              href="https://ithelpdesk.rebit.org.in/SapphireIMS/LoginPage.do"
              target="_blank"
              >ITSM</a
            >
          </li>
          <li>
            <a href="https://rebithr.darwinbox.in/user/login" target="_blank"
              >Darwinbox</a
            >
          </li>
          <!-- <li>
            <a
              href="https://172.17.60.142:9020/home"
              target="_blank"
              (click)="clickToTrack('DS')"
              >Design System</a
            >
          </li> -->
        </ul>
      </div>
    </li>
    <!-- <li>
      <a href="#" class="solo-tab" routerLink="/underconstruction"
        >Publications
      </a>
    </li> -->
    <li class="menu-drop">
      <div class="menu-header">
        <a
          id="#open"
          class="collapsed"
          [class]="active == 'publications' ? 'active-menu' : ''"
          role="button"
          data-toggle="collapse"
          href="#collapse-4"
          (click)="activeState('publications'); openArrow4 = !openArrow4"
          [attr.aria-expanded]="!openArrow4"
          aria-controls="collapseExample4"
        >
          Publications
        </a>
      </div>
      <div
        id="collapseExample4"
        class="collapse"
        data-parent="#accordion"
        [ngbCollapse]="openArrow4"
      >
        <ul>
          <li>
            <a href="https://rebit.org.in/blogs" target="_blank">Blogs</a>
          </li>
          <!-- <li>
            <a href="https://rebit.org.in/newsletter" target="_blank"
              >Newsletters</a
            >
          </li> -->
          <li *ngIf="isqualitymanual">
            <a href="https://ekm.rebit.org.in/qualitymanual/html/" target="_blank"
              >Quality Manual</a
            >
          </li>
          <li>
            <a href="https://ekm.rebit.org.in/handbook/html/" target="_blank">CS HandBook</a>
          </li>
          <li>
            <a href="../../../assets/documents/BA Handbook.pdf" target="_blank">BA HandBook</a>
          </li>
        </ul>
      </div>
    </li>
    <li *ngIf="isTrainingGroup">
      <a
        [class]="active == 'trainingForm' ? 'active-menu' : ''"
        (click)="activeState('trainingForm')"
        routerLink="/trainingForm"
        class="solo-tab"
        >Training Feedback
      </a>
    </li>
    <li>
      <a
        [class]="active == 'feedback' ? 'active-menu' : ''"
        class="solo-tab"
        (click)="activeState('feedback')"
        routerLink="/info/feedback"
        >Feedback
      </a>
    </li>
    <li
      *ngIf="isHackAdmin"
      [class]="active == 'hackathon-view' ? 'active-list' : ''"
    >
      <a
        (click)="activeState('hackathon-view')"
        [routerLink]="['/hackathon-view']"
        class="solo-tab"
        routerLinkActive="router-link-active"
        >Hackathon View</a
      >
    </li>
    <!-- <li>
      <a
        [class]="active == 'hackathon' ? 'active-menu' : ''"
        class="solo-tab"
        (click)="activeState('hackathon')"
        routerLink="/hackathon"
        >Hackathon Registration
      </a>
    </li> -->
    <li class="menu-drop" *ngIf="isAdminGroup">
      <div class="menu-header">
        <a
          id="#open"
          class="collapsed"
          [class]="active == 'admin' ? 'active-menu' : ''"
          role="button"
          data-toggle="collapse"
          href="#collapse-5"
          (click)="activeState('admin'); openArrow5 = !openArrow5"
          [attr.aria-expanded]="!openArrow5"
          aria-controls="collapseExample4"
        >
          Admin
        </a>
      </div>
      <div
        id="collapseExample4"
        class="collapse"
        data-parent="#accordion"
        [ngbCollapse]="openArrow5"
      >
        <ul>
          <li [class]="active == 'event' ? 'active-list' : ''">
            <a (click)="activeState('event')" routerLink="event">Event</a>
          </li>

          <li [class]="active == 'news' ? 'active-list' : ''">
            <a
              (click)="activeState('news')"
              [routerLink]="['/news']"
              routerLinkActive="router-link-active"
              >News</a
            >
          </li>
          <li [class]="active == 'announcement' ? 'active-list' : ''">
            <a
              (click)="activeState('announcement')"
              [routerLink]="['/announcement']"
              routerLinkActive="router-link-active"
              >Announcement</a
            >
          </li>

          <li [class]="active == 'cafemenu' ? 'active-list' : ''">
            <a
              (click)="activeState('cafemenu')"
              [routerLink]="['/cafemenu']"
              routerLinkActive="router-link-active"
              >Cafe Menu</a
            >
          </li>
          <!-- <li [class]="active == 'access-control' ? 'active-list' : ''">
            <a (click)="activeState('access-control')"
              [routerLink]="['/access-control/groups']"
              routerLinkActive="router-link-active"
              >Access Control</a
            >
          </li> -->

          <!-- <li [class]="active == 'analytics' ? 'active-list' : ''">
            <a
              (click)="activeState('analytics')"
              [routerLink]="['/analytics']"
              routerLinkActive="router-link-active"
              >Analytics</a
            >
          </li> -->
          <!-- <li>
            <a routerLink="">Cafe Menu</a>
          </li> -->
        </ul>
      </div>
    </li>
    <li *ngIf="isAdminGroup">
      <a
        [class]="active == 'access-control' ? 'active-menu' : ''"
        class="solo-tab"
        (click)="activeState('access-control')"
        routerLink="/access-control/groups"
        >Access Control
      </a>
    </li>
    <li>
      <a
        [class]="active == 'contactus' ? 'active-menu' : ''"
        class="solo-tab"
        (click)="activeState('contactus')"
        routerLink="/contactus"
        >Contact Us
      </a>
    </li>
  </ul>
  <div class="profile col-md-2">
    <div class="user-login" (click)="goToProfile()">
      <img
        loading="lazy"
        src="assets/images/User.svg"
        alt="user-profile"
      /><span class="user-nm" style="cursor: pointer">{{
        (user$ | async)?.name
      }}</span>
    </div>
  </div>
  <script>
    $(document).ready(function () {
      $(".owl-carousel").owlCarousel({
        margin: 10,
        nav: true,
        autoPlay: false,
        dots: true,
        items: 1,
        responsive: {
          0: {
            items: 1,
          },

          600: {
            items: 1,
          },

          1024: {
            items: 1,
          },

          1366: {
            items: 1,
          },
        },
      });

      $(".project-slider").owlCarousel({
        margin: 10,
        nav: true,
        autoPlay: false,
        dots: false,
        items: 3.3,
        responsive: {
          0: {
            items: 1,
          },

          600: {
            items: 3,
          },

          1024: {
            items: 3,
          },

          1366: {
            items: 3.3,
          },
        },
      });
    });
  </script>
</div>
