<div class="container">
  <ds-my-dspace-new-submission
    *dsShowOnlyForRole="[roleTypeEnum.Submitter]"
  ></ds-my-dspace-new-submission>
</div>

<ds-themed-search
  *ngIf="configuration && context"
  class="myecm"
  [configuration]="configuration"
  [configurationList]="configurationList$ | async"
  [context]="context"
  [viewModeList]="viewModeList"
></ds-themed-search>
