import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as coedata from '../../app/coedata.json';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from '../shared/notifications/notifications.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ds-coe-see-all',
  templateUrl: './coe-see-all.component.html',
  styleUrls: ['./coe-see-all.component.scss'],
})
export class CoeSeeAllComponent implements OnInit {
  coeid: string;
  depid: string;
  initiatives: any[];
  initiativesData: any[];
  baseurl: string = environment.rest.baseUrl;
  initiativeList:any;

  constructor(private actRoute: ActivatedRoute, private router: Router,
    private http: HttpClient,
    private notificationService: NotificationsService,
    private cdRef: ChangeDetectorRef) {
    this.coeid = this.actRoute.snapshot.params['coeid'];
    this.depid = this.actRoute.snapshot.params['depid'];
  }

  ngOnInit(): void {
    const initiativesData: any[] = (coedata as any).default;

    if (this.coeid == 'coe1' || this.coeid == 'coe8' || this.coeid == 'coe10') {
      this.initiatives =
        initiativesData[this.coeid]['deps'][this.depid]['initiative'];
    } else {
      this.initiatives = initiativesData[this.coeid]['initiative'];
    }

    // console.log(this.initiatives);

    //this.initiatives = this.initiativesData[this.id]['initiative'];
    //console.log(this.initiatives);
    this.getCoeInitiatives();
  }

  goTodetail() {
    this.router.navigate([
      `coedetail/{{ coeid }}/{{ depid }}/{{ initiative.key }}`,
    ]);
  }
  goToCoePage() {
    let link = ['/coe'];
    this.router.navigate(link);
  }
  getCoeInitiatives(){
    let employeeCoeType:any;let employeeCoeName:any;
    if(this.coeid == 'coe1'){
      employeeCoeType =  'COPE'; 
      if(this.depid == 'dep1'){
        employeeCoeName = 'Program & product enablement';
      } else if(this.depid == 'dep2'){
        employeeCoeName = 'Enterprise Consulting';
      } else if(this.depid == 'dep3'){
        employeeCoeName = 'UI UX';
      } else if(this.depid == 'dep4'){
        employeeCoeName = 'Process standards and Research';
      } else if(this.depid == 'dep5'){
        employeeCoeName = 'Product Management';
      }
    } else if(this.coeid == 'coe2'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'Microsoft COE';
    } else if(this.coeid == 'coe3'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE JAVA';
    } else if(this.coeid == 'coe4'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE Testing';
    } else if(this.coeid == 'coe5'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE PMO';
    } else if(this.coeid == 'coe6'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'COE Sustenance Engineering';
    } else if(this.coeid == 'coe7'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'FCT';
    } else if(this.coeid == 'coe8'){
      employeeCoeType =  'SA'; 
      employeeCoeName = this.depid == 'dep1' ? 'BOOST':'FINTECH';
    } else if(this.coeid == 'coe9'){
      employeeCoeType =  'COE'; 
      employeeCoeName = 'SAP COE';
    }else if(this.coeid == 'coe10'){
      employeeCoeType =  'EA'; 
      employeeCoeName = this.depid == 'dep1' ? 'EA CoE':'Global Standards';
    }
    let obj={
      "coeType":  employeeCoeType,
      "coeName": employeeCoeName,
    }
    this.http.post(`${this.baseurl}/api/cope/coeInitiatives`,obj)
        .subscribe({
          next: (res: any) => {
            this.initiativeList = res.filter(el => el !== null);
            this.cdRef.detectChanges();
          },
        })
  }

  goToCoeDtlPage(value){
    this.notificationService.setCoeDetails(value);
  }
}
