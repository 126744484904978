<ds-alert
  *ngIf="showLatestVersionNotice$ && (showLatestVersionNotice$ | async)"
  [content]="
    'item.version.notice'
      | translate
        : {
            destination: getItemPage(
              ((latestVersion$ | async)?.item | async)?.payload
            )
          }
  "
  [dismissible]="false"
  [type]="AlertTypeEnum.Warning"
>
</ds-alert>
