<div class="ekm" *ngIf="isLoggedIn | async">
  <ds-themed-header-navbar-wrapper></ds-themed-header-navbar-wrapper>
  <section>
    <div class="layout">
      <div class="col-md-2 left-box">
        <ds-themed-admin-sidebar></ds-themed-admin-sidebar>
      </div>
      <div
        class="middle-box"
        [class]="showmodule ? 'col-md-7' : 'search-bar-box'"
      >
        <ds-themed-breadcrumbs></ds-themed-breadcrumbs>

        <router-outlet></router-outlet>
      </div>
      <div class="col-md-3 right-box" *ngIf="showmodule">
        <ds-notificationmodule></ds-notificationmodule>
      </div>
      <ds-notifications-board [options]="notificationOptions">
      </ds-notifications-board>
    </div>
  </section>
</div>
<ds-themed-loading *ngIf="loading | async"></ds-themed-loading>

<div *ngIf="!(isLoggedIn | async)">
  <div class="row" *ngIf="showhackathon">
    <!-- <header style="height: 8vh"></header> -->
    <!-- <div class="col-md-2">hi</div>
    <div class="col-md-8">
      <router-outlet></router-outlet>
    </div>
    <div class="col-md-2">hi</div> -->
  </div>
  <div class="row" *ngIf="showhackathon == true">
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="showhackathon == false">
    <router-outlet></router-outlet>
  </div>
</div>
