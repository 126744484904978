import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Injector,
  OnInit,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  withLatestFrom,
} from 'rxjs/operators';
import { AuthService } from '../../core/auth/auth.service';
import { slideSidebar } from '../../shared/animations/slide';
import { MenuComponent } from '../../shared/menu/menu.component';
import { MenuService } from '../../shared/menu/menu.service';
import { CSSVariableService } from '../../shared/sass-helper/sass-helper.service';
import { AuthorizationDataService } from '../../core/data/feature-authorization/authorization-data.service';
import { MenuID } from '../../shared/menu/menu-id.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ThemeService } from '../../shared/theme-support/theme.service';
import { EPerson } from 'src/app/core/eperson/models/eperson.model';
import { filter } from 'rxjs';
import { FeatureID } from 'src/app/core/data/feature-authorization/feature-id';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StatisticsService } from 'src/app/statistics/statistics.service';
import { RoleService } from 'src/app/core/roles/role.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
/**
 * Component representing the admin sidebar
 */
@Component({
  selector: 'ds-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss'],
  animations: [slideSidebar],
})
export class AdminSidebarComponent extends MenuComponent implements OnInit {
  /**
   * The menu ID of the Navbar is PUBLIC
   * @type {MenuID.ADMIN}
   */
  menuID = MenuID.ADMIN;
  isHackAdmin: boolean = false;

  /**
   * Observable that emits the width of the collapsible menu sections
   */
  sidebarWidth: Observable<string>;

  /**
   * Is true when the sidebar is open, is false when the sidebar is animating or closed
   * @type {boolean}
   */
  sidebarOpen = true; // Open in UI, animation finished

  /**
   * Is true when the sidebar is closed, is false when the sidebar is animating or open
   * @type {boolean}
   */
  // sidebarClosed = !this.sidebarOpen; // Closed in UI, animation finished

  /**
   * Emits true when either the menu OR the menu's preview is expanded, else emits false
   */
  // sidebarExpanded: Observable<boolean>;
  openArrow1 = false;
  openArrow2 = true;
  openArrow3 = true;
  openArrow4 = true;
  openArrow5 = true;
  public user$: Observable<EPerson>;
  name: string;
  email: string;
  uuid: string;
  inFocus$: BehaviorSubject<boolean>;
  active: string = 'home';
  isLogged: boolean = false;
  isAdmin$: Observable<boolean>;
  checkid: boolean = false;
  isTrainingGroup: boolean = false;
  isAdminGroup: boolean = false;
  isqualitymanual: boolean;
  constructor(
    protected menuService: MenuService,
    protected injector: Injector,
    private variableService: CSSVariableService,
    private authService: AuthService,
    public authorizationService: AuthorizationDataService,
    public route: ActivatedRoute,
    protected themeService: ThemeService,
    private router: Router,
    private http: HttpClient,
    private cdRef: ChangeDetectorRef,
    private statisticService: StatisticsService,
    protected roleService: RoleService,
    private notifictnSrvc:NotificationsService
  ) {
    super(menuService, injector, authorizationService, route, themeService);
    this.inFocus$ = new BehaviorSubject(false);
  }

  /**
   * Set and calculate all initial values of the instance variables
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.isAdmin$ = this.roleService.isAdmin();
    this.sidebarWidth = this.variableService.getVariable('sidebarItemsWidth');
    this.authService.isAuthenticated().subscribe((loggedIn: boolean) => {
      this.isLogged = loggedIn;
      if (loggedIn) {
        this.menuService.showMenu(this.menuID);
      }
    });
    this.user$ = this.authService.getAuthenticatedUserFromStore();
    this.user$.subscribe((data) => {
      this.email = data.email;
      this.name = data.name;
      this.uuid = data.uuid;
      this.http
        .get(
          `${environment.rest.baseUrl}/api/eperson/epersons/${data.uuid}/groups`
        )
        .subscribe({
          next: (res) => {
            res['_embedded']['groups'].forEach((element) => {
              if (element['name'] === 'ReBIT_PMV_LC') {
                element['id'] === environment['utilization']['id']
                  ? (this.checkid = true)
                  : (this.checkid = false);
              }
              if (element['name'] === 'HACKATHON_ADMIN') {
                element['id'] === environment.hackathonAdmin.groupId
                  ? (this.isHackAdmin = true)
                  : (this.isHackAdmin = false);
              }
              if (element['name'] === 'TRAINING_GROUP') {
                element['id'] === environment.trainingGroup.groupId
                  ? (this.isTrainingGroup = true)
                  : (this.isTrainingGroup = false);
              }
              if (element['name'] === 'EKM_Admin') {
                element['id'] === environment.ekmGroup.groupId
                  ? (this.isAdminGroup = true)
                  : (this.isAdminGroup = false);
              }
              if (element['name'] === 'QualityManual') {
                element['id'] === environment.qualityManual.groupId
                  ? (this.isqualitymanual = true)
                  : (this.isqualitymanual = false);
              }
            });
            this.cdRef.detectChanges();
          },
          error: () => {},
        });
    });
  }

  @HostListener('focusin')
  public handleFocusIn() {
    this.inFocus$.next(true);
  }

  @HostListener('focusout')
  public handleFocusOut() {
    this.inFocus$.next(false);
  }

  public handleMouseEnter(event: any) {
    if (!this.inFocus$.getValue()) {
      this.expandPreview(event);
    } else {
      event.preventDefault();
    }
  }

  public handleMouseLeave(event: any) {
    if (!this.inFocus$.getValue()) {
      this.collapsePreview(event);
    } else {
      event.preventDefault();
    }
  }

  /**
   * Method to change this.collapsed to false when the slide animation ends and is sliding open
   * @param event The animation event
   */
  // startSlide(event: any): void {
  //   if (event.toState === 'expanded') {
  //     this.sidebarClosed = false;
  //   } else if (event.toState === 'collapsed') {
  //     this.sidebarOpen = false;
  //   }
  // }

  /**
   * Method to change this.collapsed to false when the slide animation ends and is sliding open
   * @param event The animation event
   */

  goToProfile() {
    this.router.navigate([`/userprofile/${this.name}`]);
  }
  activeState(value) {
    this.active = value;
  }

  ngAfterViewInit() {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        //if (event.url === "/home" || event.url === "/home#statistics" || event.url === "/about_us" || event.url == "/") {
        if (event.url === '/home') {
          this.active = 'home';
        }
      });
  }
  clickToTrack(moduleName) {
    const OBJ = {
      uuId: this.uuid,
      emailId: this.email,
      moduleName: moduleName,
    };
    this.statisticService.trackClickedLinks(OBJ).subscribe({
      next: (res) => {},
      error: (e) => {},
    });
  }
  rmvCmntyList(){
    this.notifictnSrvc.setCmntyLstPage(null);
  }
}
