<a
  class="badge badge-primary mr-1 mb-1 d-inline-flex text-capitalize"
  [routerLink]="searchLink"
  [queryParams]="removeParameters | async"
  queryParamsHandling="merge"
>
  {{ "search.filters.applied." + key | translate }}:

  <div *ngIf="key !== 'f.submitter'">
    {{
      "search.filters." + filterName + "." + value
        | translate : { default: normalizeFilterValue(value) }
    }}
  </div>

  <div *ngIf="key === 'f.submitter'" style="margin-left: 3px">{{ label }}</div>
  <span> ×</span>
</a>
