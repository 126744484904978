<ng-container *ngVar="(actionRD$ | async)?.payload as workflowAction">
  <div class="mt-1 mb-3 space-children-mr myecm-page">
    <ds-claimed-task-actions-loader
      *ngFor="let option of workflowAction?.options"
      [item]="item"
      [option]="option"
      [object]="object"
      [workflowitem]="workflowitem"
      (processCompleted)="this.processCompleted.emit($event)"
    >
    </ds-claimed-task-actions-loader>

    <button
      class="icon-btn workflow-view"
      ngbTooltip="{{ 'submission.workflow.generic.view-help' | translate }}"
      [routerLink]="[getWorkflowItemViewRoute(workflowitem)]"
    >
      <i class="fa fa-info-circle"></i>
      <!-- {{"submission.workflow.generic.view" | translate}} -->
    </button>

    <ds-claimed-task-actions-loader
      [item]="item"
      [option]="returnToPoolOption"
      [object]="object"
      [workflowitem]="workflowitem"
      (processCompleted)="this.processCompleted.emit($event)"
    >
    </ds-claimed-task-actions-loader>
  </div>
</ng-container>
