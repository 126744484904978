<div class="ecm-popup">
  <div class="modal-header">
    <div class="modal-title holiday-model-title">
      {{
        "dso-selector." +
          action +
          "." +
          objectType.toString().toLowerCase() +
          ".head" | translate
      }}
    </div>
    <button
      type="button"
      class="close"
      (click)="selectObject(undefined)"
      aria-label="Close"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <!-- <hr class="line"> -->
  <div class="modal-body pop_up_btn pt-0">
    <button
      class="btn rounded-pill btn-search btn-block"
      (click)="selectObject(undefined)"
    >
      {{
        "dso-selector." +
          action +
          "." +
          objectType.toString().toLowerCase() +
          ".button" | translate
      }}
    </button>

    <div class="or_line">
      <span>{{ "or" | translate }}</span>
    </div>

    <!-- <h3 class="position-relative py-1 my-3 font-weight-normal">
            <hr>
             <div id="create-community-or-separator" class="text-center position-absolute w-100">
                <span class="px-4 bg-white">or</span>
            </div> 
        </h3> -->

    <div class="model-subhead">
      {{
        "dso-selector." +
          action +
          "." +
          objectType.toString().toLowerCase() +
          ".input-header" | translate
      }}
    </div>
    <ds-dso-selector
      [currentDSOId]="dsoRD?.payload.uuid"
      [types]="selectorTypes"
      (onSelect)="selectObject($event)"
    ></ds-dso-selector>
  </div>
</div>
