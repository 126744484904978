<button
  *ngIf="shouldShowButton$ | async"
  class="btn icon-btn btn-sm"
  [ngbTooltip]="tooltipMsg | translate"
  (click)="export()"
  [title]="tooltipMsg | translate"
  [attr.aria-label]="tooltipMsg | translate"
>
  <i class="fas fa-file-export fa-fw"></i>
</button>
