<div
  *ngIf="!dismissed"
  class="alert m-0 p-0 my-1 alert-dismissible fade show w-100"
  role="alert"
  [@enterLeave]="animate"
>
  <span *ngIf="content">{{ content | translate }}</span>

  <ng-content></ng-content>

  <button
    *ngIf="dismissible"
    type="button"
    class="close"
    data-dismiss="alert"
    aria-label="Close"
    (click)="dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
