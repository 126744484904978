<a
  class="d-flex flex-row"
  [tabIndex]="-1"
  [routerLink]="[searchLink]"
  [queryParams]="removeQueryParams"
  queryParamsHandling="merge"
>
  <label>
    <input type="checkbox" [checked]="true" class="my-1 align-self-stretch" />
    <span class="filter-value pl-1 text-capitalize">
      {{
        "search.filters." + filterConfig.name + "." + selectedValue.value
          | translate : { default: selectedValue.label }
      }}
    </span>
  </label>
</a>
