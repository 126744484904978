<div class="dashboard-main">
  <div class="card-box user-detail">
    <img loading="lazy" src="assets/images/Great Place to Work_EKM banner.jpg" alt="banner" />
  </div>
  <div class="navigation-menu bg-blue">
    <ul class="nav nav-pills mb-4 middle-box-events" id="pills-tab-events" role="tablist">
      <li class="nav-item" role="presentation">
        <div class="nav-link" [class]="view === 'events' ? 'active' : ''" id="pills-events-tab"
          (click)="changeSections('events')">
          Events
        </div>
      </li>
      <li class="nav-item" role="presentation">
        <div class="nav-link" id="pills-news-tab" [class]="view === 'news' ? 'active' : ''"
          (click)="changeSections('news')">
          News
        </div>
      </li>
      <li class="nav-item" role="presentation">
        <div class="nav-link" id="pills-news-tab" [class]="view === 'newsletter' ? 'active' : ''"
          (click)="changeSections('newsletter')">
          Newsletter
        </div>
      </li>
      <li class="nav-item" role="presentation">
        <div class="nav-link" id="pills-news-tab" [class]="view === 'handbooks' ? 'active' : ''"
          (click)="changeSections('handbooks')">
          HandBooks
        </div>
      </li>
      <li class="nav-item" role="presentation">
        <div class="nav-link" id="pills-news-tab" [class]="view === 'playbooks' ? 'active' : ''"
          (click)="changeSections('playbooks')">
          PlayBooks
        </div>
      </li>
      <li class="nav-item" role="presentation">
        <div class="nav-link" id="pills-news-tab" [class]="view === 'whitepapers' ? 'active' : ''"
          (click)="changeSections('whitepapers')">
          Whitepapers
        </div>
      </li>
      
    </ul>
    <div class="tab-content" id="pills-tab-event-content">
      <div class="tab-pane fade show active multiple-events new-banner" id="pills-events" *ngIf="view === 'events'">
        <div *ngFor="let obj of eventData; let i = index">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                {{ obj.eventName }}
              </div>
             <p class="blog-info about-info" style="white-space: pre-line; min-height: 100px;word-break: break-all;"
                [ngStyle]="{'max-height': (isReadMore && i === readMoreIndex) ? 'none' : '100px' }">
                <span *ngIf="isReadMore && i === readMoreIndex">
                  {{ obj.eventDesc }}
                </span>
                <span *ngIf="i !== readMoreIndex">
                  {{ obj.eventDesc?.length > 200 ? ((obj.eventDesc | slice : 0 : 200)+'...') : obj.eventDesc }}
                </span>
              </p>
              <div style="display: flex;justify-content: space-between;">
                <p class="blog-date date-font">
                  {{ obj.eventDate | date : "dd/MM/yyyy" }}
                </p>
                <p *ngIf="obj.eventDesc?.length > 200" (click)="readMore(i)" class="read-text"
                  style="white-space: pre-line">
                  {{(isReadMore && i === readMoreIndex) ? 'Read Less' : 'Read More'}}
                </p>
              </div>
            </div>
            <div class="event-icon">
              <img loading="lazy" src="{{ baseUrl }}/api/event/image/{{ obj.eventId }}" alt="event"
                onerror="this.src='assets/images/default_event.png'" />
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade show active multiple-events" id="pills-events" *ngIf="view === 'news'">
        <div *ngFor="let obj of newsData; let i = index">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">{{ obj.newsHeading }}</div>
              <p class="blog-info about-info" style="white-space: pre-line; min-height: 100px;word-break: break-all;"
              [ngStyle]="{'max-height': (isReadMore && i === readMoreIndex) ? 'none' : '100px' }">
                
              <span *ngIf="isReadMore && i === readMoreIndex">
                {{ obj.newsDesc }}
              </span>
              <span *ngIf="i !== readMoreIndex">
                {{ obj.newsDesc ?.length > 200 ? ((obj.newsDesc | slice : 0 : 200)+'...') : obj.newsDesc }}
              </span>
               
              </p>
              <div style="display: flex;justify-content: space-between;">
              <p class="blog-date date-font">
                {{ obj.newsDate | date : "dd/MM/yyyy" }}
              </p>
              <p *ngIf="obj.newsDesc.length > 200" (click)="readMore(i)" class="read-text"
                style="white-space: pre-line">
                {{(isReadMore && i === readMoreIndex) ? 'Read Less' : 'Read More'}}
              </p>
            </div>
            </div>
            <div class="event-icon">
              <img loading="lazy" src="{{ baseUrl }}/api/news/image/{{ obj.newsId }}" alt="event"
                onerror="this.src='assets/images/default_event.png'" />
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade show active multiple-events newsletterWrapper" id="pills-events" *ngIf="view === 'newsletter'">
        <div class="justify-content-between d-flex align-items-center mb-3">
          <div class="newletterhead">
            <label>Welcome to</label>
            <label class="label2">ReBIT Monthly Newsletter</label>
          </div>
          
          <select class="selectnewsletter" id="day" (change)="year($event)" name="day">
            <option value="2024">2024</option>
            <option value="2023">2023</option>
            
          </select>
        </div>

          <!-- <div class="card-box content">
            <div class="event-card w-100">
              <div class="eve-heading mb-3" style="white-space: inherit;">Welcome to ReBIT Newsletter</div>

              <table class="table table-bordered newsletter-table">
                <thead>
                  <tr>
                    <th scope="col">Sr. No.</th>
                    <th scope="col">Month</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td><a href="http://172.17.60.142:9001/flipbook/" target="_blank">October 2023</a></td>
                  </tr>
                  
                
                </tbody>
              </table>

            </div>
            
        </div> -->
        <a href="https://ekm.rebit.org.in/udaan/July-2024/flipbook/html/" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Udaan July 2024
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 128;
             text-align: justify;"
             >
                <span>
                  Welcome to the latest edition of our newsletter. We're excited to announce several new projects with a strong focus on AI and Machine Learning as the technology focus in the edition. Catch all the action from our dynamic teams across verticals, pushing boundaries, developing cutting-edge solutions for multiple functions of RBI. Flip and glance for a quick walkthrough of all events and updates you informed and engaged with the latest at ReBIT. 
                </span>
               
              </p>
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/images/june-24.png'/>
            </div>
          </div>
        </a>
        <a href="https://ekm.rebit.org.in/udaan/May-2024/html/" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Udaan May 2024
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 145px;
             text-align: justify;"
             >
                <span>
                  Our newsletter is now live packed with all the action and updates, blog, article and key highlights, success stories on projects at ReBIT. Happy Reading and we welcome your feedback and ideas for our next edition.


                </span>
               
              </p>
              <!-- <div style="display: flex;justify-content: space-between;">
                <p class="blog-date date-font">
                  Oct 2023
                </p>
              </div> -->
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/images/may-24.png'/>
            </div>
          </div>
        </a>
        <a href="http://ekm.rebit.org.in/udaan/Mar-2024/html" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Udaan March 2024
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 145px;
             text-align: justify;"
             >
                <span>
                  March 2024 edition is live! Catch all the CSR action, events, celebrations, project milestones and COE updates across ReBIT. Highlights includes Sarvottam, Mani App, PRAVAAH, Sachet, EkMev and more. Happy reading and let’s continue the path of excellence.


                </span>
               
              </p>
              <!-- <div style="display: flex;justify-content: space-between;">
                <p class="blog-date date-font">
                  Oct 2023
                </p>
              </div> -->
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/images/Feb.png'/>
            </div>
          </div>
        </a>
        <a href="http://ekm.rebit.org.in/udaan/Jan-2024/html" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Udaan January 2024
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 145px;
             text-align: justify;"
             >
                <span>
                  Welcome to the New Year 2024! Dive into all the action, success and celebrations in January 2024 with updates across verticals showcasing the spirit of progress and possibilities. Happy reading and wishing ReBITians a fantastic year ahead.
                </span>
               
              </p>
              <!-- <div style="display: flex;justify-content: space-between;">
                <p class="blog-date date-font">
                  Oct 2023
                </p>
              </div> -->
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/images/newletter4.PNG'/>
            </div>
          </div>
        </a>
        <a href="http://ekm.rebit.org.in/udaan/Dec-2023/" target="_blank" *ngIf="choosenyear === 2023">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Udaan December 2023
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 145px;
             text-align: justify;"
             >
                <span>
                  As we stride into a new year of 2024, Udaan congratulates ReBITians on the remarkable journey of 2023. Successful projects, go-lives, XMas celebrations, CSR, Leadership academy and more in this edition. Happy Reading !
                </span>
               
              </p>
              <!-- <div style="display: flex;justify-content: space-between;">
                <p class="blog-date date-font">
                  Oct 2023
                </p>
              </div> -->
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/images/newletter3.png'/>
            </div>
          </div>
        </a>
        <a href="https://ekm.rebit.org.in/udaan/Nov-2023/" target="_blank" *ngIf="choosenyear === 2023">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Udaan November 2023
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 145px;
             text-align: justify;"
             >
                <span>
                  The month was action-packed at ReBIT, with festivities, events, project go-lives, and the much-awaited Hackathon 2023 finals. The spotlight in this edition is on the Account Aggregator framework that now supports regulated entities of all financial sector regulators and a design handbook launch. Get a quick walkthrough of all the key updates and highlights of November 2023. Happy Reading!
                </span>
               
              </p>
              <!-- <div style="display: flex;justify-content: space-between;">
                <p class="blog-date date-font">
                  Oct 2023
                </p>
              </div> -->
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/images/newletter2.png'/>
            </div>
          </div>
        </a>
        <a href="https://ekm.rebit.org.in/udaan/Oct-2023/" target="_blank" *ngIf="choosenyear === 2023">
        <div class="card-box content">
          <div class="event-card">
            <div class="eve-heading" style="white-space: inherit;">
              Udaan October 2023
            </div>
           <p class="blog-info about-info" style="white-space: pre-line; min-height: 142px;text-align: justify;"
           >
              <span>
                This month, we share our flight of success across projects CIMS, Samadhaan, UDGAM, AMRMS, CIMS, NGCB, and more. The spotlight is on the UDGAM project, which is drawing close to 2 lakh+ visitors per month, and we talk about how blockchain can play a role in trade and finance. Enjoy the new way to stay informed on the latest updates and fun-filled events at ReBIT.
              </span>
             
            </p>
            <!-- <div style="display: flex;justify-content: space-between;">
              <p class="blog-date date-font">
                Oct 2023
              </p>
            </div> -->
          </div>
          <div class="event-icon">
            <img loading="lazy" alt="event"
             src='assets/images/newletter1.png'/>
          </div>
        </div>
      </a>
      </div>
      <div class="tab-pane fade show active multiple-events newsletterWrapper" id="pills-events" *ngIf="view === 'handbooks'">
        <div class="justify-content-between d-flex align-items-center mb-3">
          <div class="newletterhead">
            <label>Welcome to</label>
            <label class="label2">ReBIT Handbooks</label>
          </div>
          
          <select class="selectnewsletter" id="day" (change)="year($event)" name="day">
            <option value="2024">2024</option>
            <!-- <option value="2023">2023</option>
             -->
          </select>
        </div>
        <a href="assets/documents/BA Handbook/html/index.html" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                BA HandBooks
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 128px;
             text-align: justify;"
             >
                <span>
                  The Business Analyst's handbook is a comprehensive guide designed to help business analysts understand their roles, responsibilities and key methodologies to manage projects and stakeholders in ReBIT.
                </span>
               
              </p>
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/images/BA handbook.png'/>
            </div>
          </div>
        </a>

        <a href="https://ekm.rebit.org.in/handbook/html/" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Cyber Security Awareness Hygiene Practices
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 162px;
             text-align: justify;"
             >
                <span>
                  The handbook aims to increase awareness on the cyber security best practices, practical strategies, and techniques for securing organization assets like devices, accounts, and data from malicious actors. Reporting security incidents has significant importance for the organization as it helps promptly reporting any suspicious activity or security incidents and mitigate potential risks to ReBIT as a way forward.
                </span>
               
              </p>
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/images/handbook1.png'/>
            </div>
          </div>
        </a>
        <a href="assets/documents/CS Handbook/Vulnerability Assessment & Penetration Testing/flipbook/html/index.html" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Vulnerability Assessment and Penetration Testing
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 162px;
             text-align: justify;"
             >
                <span>
                  This handbook outlines the Vulnerability Assessment and Penetration Testing (VAPT) requirements for ReBIT's IT systems, covering both new and existing ones. Based on international standards like ISO 27001:2022 and NIST publications, it ensures a secure and up-to-date approach to identifying vulnerabilities. Regular testing and adherence to these guidelines strengthens ReBIT's cybersecurity posture.</span>
               
              </p>
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/documents/CS Handbook/Vulnerability Assessment & Penetration Testing/Vulnerability Assessment and Penetration Testing Thumbnail.png'/>
            </div>
          </div>
        </a>
        <a href="assets/documents/CS Handbook/Identity & Access Management/Identity & Access Management/flipbook/html/index.html" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Identity & Access Management
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 128px;
             text-align: justify;"
             >
                <span>
                  Identity and Access Management (IAM) is a vital component of IT systems and security. IAM establishes guidelines for managing digital identities and controlling access to resources. This handbook covers ReBITs IDAM process in detail.                </span>
               
              </p>
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/documents/CS Handbook/Identity & Access Management/Identity & Access Management/Identity & Access Management Policy thumbnail.png'/>
            </div>
          </div>
        </a>
        <a href="assets/documents/CS Handbook/Data classification/flipbook/html/index.html" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Data Classification
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 128px;
             text-align: justify;"
             >
                <span>

                  Data classification is crucial for information security: it identifies and categorizes data based on sensitivity, enabling selection and deployment of fitting security controls. This handbook covers ReBITs data classification and Klassify tool introduction in detail.                </span>
               
              </p>
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/documents/CS Handbook/Data classification/Data classification thumbnail.png'/>
            </div>
          </div>
        </a>
        
     
      </div>
      <div class="tab-pane fade show active multiple-events newsletterWrapper" id="pills-events" *ngIf="view === 'playbooks'">
        <div class="justify-content-between d-flex align-items-center mb-3">
          <div class="newletterhead">
            <label>Welcome to</label>
            <label class="label2">ReBIT PlayBooks</label>
          </div>
          
          <select class="selectnewsletter" id="day" (change)="year($event)" name="day">
            <option value="2024">2024</option>
            <!-- <option value="2023">2023</option>
             -->
          </select>
        </div>

        <a href="assets/documents/Fortifying APIs V1.pdf" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Fortifying APIs V1
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 128;
             text-align: justify;"
             >
                <span>
                  This Playbook dwelves into the world of APIs and API Security. It explores how APIs work, different types of API security threats, and the best practices to ensure the APIs are well-protected.
                </span>
               
              </p>
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/images/Fortifying APIs Playbook thumbnail.png'/>
            </div>
          </div>
        </a>
        <a href="assets/documents/Third-Party  Cyber  Security  Requirements.pdf" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Third-Party Cyber Security Requirements
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 128;
             text-align: justify;"
             >
                <span>
                  This playbook outlines cyber security requirements for third parties working with ReBIT, including suppliers and joint ventures.
                </span>
               
              </p>
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/images/Third-Party Cyber Security Requirements Playbook Thumbnail.png'/>
            </div>
          </div>
        </a>
        <a href="assets/documents/Playbook software supply chain security V1.pdf" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Supply Chain Security
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 128;
             text-align: justify;"
             >
                <span>
                  Supply Chain Security Playbook focuses on supply chain management that addresses the threats posed by external suppliers, vendors, resellers, logistics, and transportation.
                </span>
               
              </p>
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/images/Supply chain security Playbook Thumbnaild.png'/>
            </div>
          </div>
        </a>
        <a href="assets/documents/Playbook - Preparing for a  Ransomware Attack.pdf" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Ransomware Attack
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 128;
             text-align: justify;"
             >
                <span>
                  The Comprehensive Playbook on Modern Ransomware Attacks, Prevention & Mitigation Techniques showcases the best practices to be prepared for ransomware threats and how to mitigate them.
                </span>
               
              </p>
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/images/Playbook - Preparing for a  Ransomware Attack.png'/>
            </div>
          </div>
        </a>
        <a href="assets/documents/Vulnerability Management.pdf" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Vulnerability Management
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 128;
             text-align: justify;"
             >
                <span>
                  This playbook highlights Regularly identifying, evaluating, addressing vulnerabilities, mitigating risks and fortifying the defenses against potential cyber threats.
                </span>
               
              </p>
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/images/VUlnerability management thumbnail.png'/>
            </div>
          </div>
        </a>
        <a href="assets/documents/Defence Paradigms  in Cyber Security V2.pdf" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Malware Paradigm in Cyber Security 
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 128px;
             text-align: justify;"
             >
                <span>
                  Protecting Your Systems: Essential Malware Security Strategies and Solutions for Modern Vulnerabilities and Threats
                </span>
               
              </p>
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/images/MALWARE Paradigm in Cyber Security playbook thumbnailad.png'/>
            </div>
          </div>
        </a>
        
     
      </div>
      <div class="tab-pane fade show active multiple-events newsletterWrapper" id="pills-events" *ngIf="view === 'whitepapers'">
        <div class="justify-content-between d-flex align-items-center mb-3">
          <div class="newletterhead">
            <label>Welcome to</label>
            <label class="label2">ReBIT Whitepapers</label>
          </div>
          
          <select class="selectnewsletter" id="day" (change)="year($event)" name="day">
            <option value="2024">2024</option>
            <!-- <option value="2023">2023</option>
             -->
          </select>
        </div>
        <a href="assets/documents/Whitepaper/DEMYSTIFYING ZERO TRUST/flipbook/html/index.html" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
               Demystifying Zero trust
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 144px;
             text-align: justify;"
             >
                <span>
                  Zero Trust is a modern cybersecurity framework that doesn't trust any user or device, requiring constant verification. It shifts trust assumptions from the perimeter to each access point within an organization. Continuous verification and evaluation are essential components of a robust Zero Trust strategy. This whitepaper covers aspects of Zero trust and its importance.                </span>
               
              </p>
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/documents/Whitepaper/DEMYSTIFYING ZERO TRUST/Zero Trust thumbnail.png'/>
            </div>
          </div>
        </a>
        <a href="assets/documents/Whitepaper/Basic Process of Digital Forensics/flipbook/html/index.html" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                Basic Process of Digital Forensics 
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 144px;
             text-align: justify;"
             >
                <span>
                  Digital forensics is crucial for investigating cybercrimes, data misuse, and illicit activities utilizing computers and the internet, given the rapid technological advancements worldwide. This whitepaper covers processes involved in conducting systematic examinations while maintaining a documented chain of custody for admissible electronic evidence in courts or disciplinary hearings.
                </span>
               
              </p>
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/documents/Whitepaper/Basic Process of Digital Forensics/Digital forensics thumbnail.png'/>
            </div>
          </div>
        </a>
        <a href="assets/documents/Whitepaper/Gap Assessment Report between v1.1 and v2.0/flipbook/html/index.html" target="_blank" *ngIf="choosenyear === 2024">
          <div class="card-box content">
            <div class="event-card">
              <div class="eve-heading" style="white-space: inherit;">
                NIST CSF-Gap Assessment Report between v1.1 and v2.0
              </div>
             <p class="blog-info about-info" style="white-space: pre-line;
             min-height: 144px;
             text-align: justify;"
             >
                <span>
                  The last version of NIST Cybersecurity Framework (CSF) was v1.1, published in 2018. In response to the ever-evolving cybersecurity landscape, NIST released an updated version, CSF v2.0, in 2024. This expansion goes beyond critical infrastructure sectors, aiming to benefit all industries and organizational types, with increased focus on governance and resources for effective cybersecurity implementation.                 </span>
               
              </p>
            </div>
            <div class="event-icon">
              <img loading="lazy" alt="event"
               src='assets/documents/Whitepaper/Gap Assessment Report between v1.1 and v2.0/GAP between thumbnail.png'/>
            </div>
          </div>
        </a>
       
    
     
     
      </div>
      
      
    </div>
  </div>

