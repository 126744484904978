import { map } from 'rxjs/operators';
import {
  Component,
  Inject,
  OnInit,
  OnChanges,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { interval, Observable } from 'rxjs';
import {
  AuthenticateAction,
  ResetAuthenticationMessagesAction,
} from '../../../../core/auth/auth.actions';

import {
  getAuthenticationError,
  getAuthenticationInfo,
} from '../../../../core/auth/selectors';
import { isNotEmpty } from '../../../empty.util';
import { fadeOut } from '../../../animations/fade';
import { AuthMethodType } from '../../../../core/auth/models/auth.method-type';
import { renderAuthMethodFor } from '../log-in.methods-decorator';
import { AuthMethod } from '../../../../core/auth/models/auth.method';
import { AuthService } from '../../../../core/auth/auth.service';
import { HardRedirectService } from '../../../../core/services/hard-redirect.service';
import { CoreState } from '../../../../core/core-state.model';
import { Login } from '../login.model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import * as JsEncryptModule from 'jsencrypt';
import { environment } from 'src/environments/environment';
import { CookieService } from 'src/app/core/services/cookie.service';

/**
 * /users/sign-in
 * @class LogInPasswordComponent
 */
@Component({
  selector: 'ds-log-in-password',
  templateUrl: './log-in-password.component.html',
  styleUrls: ['./log-in-password.component.scss'],
  animations: [fadeOut],
})
@renderAuthMethodFor(AuthMethodType.Password)
export class LogInPasswordComponent implements OnInit, OnChanges {
  public data = [];

  public results = [];
  /**
   * The authentication method data.
   * @type {AuthMethod}
   */
  public authMethod: AuthMethod;

  /**
   * The error if authentication fails.
   * @type {Observable<string>}
   */
  public error: Observable<string>;

  /**
   * Has authentication error.
   * @type {boolean}
   */
  public hasError = false;

  /**
   * The authentication info message.
   * @type {Observable<string>}
   */
  public message: Observable<string>;

  /**
   * Has authentication message.
   * @type {boolean}
   */
  public hasMessage = false;

  /**
   * The authentication form.
   * @type {FormGroup}
   */
  public form: UntypedFormGroup;
  // content: boolean;

  // @ViewChild('closebutton') closebutton;

  closeResult = '';
  resp: Observable<any>;
  anotherInstance: any;
  res: import('../login.model').LoginResponseModel;

  encryptedUsername: string;
  encryptedPassword: string;
  password: string;
  show: boolean;
  captchaImg: string;
  isCaptchaLoaded: boolean = false;
  isCaptchaEnabled: boolean = false;
  id: any = 0;
  captcha_status: boolean = false;
  captcha_message: string;

  /**
   * @constructor
   * @param {AuthMethod} injectedAuthMethodModel
   * @param {boolean} isStandalonePage
   * @param {AuthService} authService
   * @param {HardRedirectService} hardRedirectService
   * @param {Store<State>} store
   */
  constructor(
    @Inject('authMethodProvider') public injectedAuthMethodModel: AuthMethod,
    @Inject('isStandalonePage') public isStandalonePage: boolean,
    private authService: AuthService,
    private hardRedirectService: HardRedirectService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private store: Store<CoreState>,
    private changeDetector: ChangeDetectorRef,
    private cookieService: CookieService
  ) {
    this.authMethod = injectedAuthMethodModel;
    // interval(2 * 60 * 1000).subscribe((x) => {
    //   if (cookieService.get('dsAuthInfo') == undefined) {
    //     console.log('constructor interval');
    //     this.loadNewCaptcha(true);
    //   }
    // });
  }
  ngOnChanges(): void {
    interval(2 * 60 * 1000).subscribe((x) => {
      if (this.cookieService.get('dsAuthInfo') == undefined) {
        // console.log('constructor interval');
        this.loadNewCaptcha(true);
      }
    });
  }

  loadNewCaptcha(changeState: boolean) {
    // this.resetTimer();
    // this.id = setInterval(() => {
    //   this.loadNewCaptcha(false);
    // }, 1000 * 120);
    //console.log("change state",changeState)
    this.captcha_status = false;
    if (changeState) {
      this.isCaptchaLoaded = false;
    }
    this.authService.captcha().subscribe((data) => {
      this.isCaptchaEnabled = data['captcha_enabled'];
      if (data['captcha_enabled'] == 'true') {
        this.isCaptchaEnabled = true;
        this.captchaImg = data['captcha_image'];
        this.changeDetector.detectChanges();
      } else {
        this.isCaptchaEnabled = false;
      }
      this.isCaptchaLoaded = true;
      if (changeState) {
        this.changeDetector.detectChanges();
      }
    });
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * @method ngOnInit
   */
  public ngOnInit() {
    this.loadNewCaptcha(true);
    // set formGroup
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      captcha: [''],
    });
    this.password = 'password';
    // set error
    this.error = this.store.pipe(
      select(getAuthenticationError),
      map((error) => {
        this.hasError = isNotEmpty(error);
        return error;
      })
    );

    // set error
    this.message = this.store.pipe(
      select(getAuthenticationInfo),
      map((message) => {
        this.hasMessage = isNotEmpty(message);
        return message;
      })
    );
  }

  /**
   * Reset error or message.
   */
  public resetErrorOrMessage() {
    if (this.hasError || this.hasMessage) {
      this.store.dispatch(new ResetAuthenticationMessagesAction());
      this.hasError = false;
      this.hasMessage = false;
      this.captcha_status = false;
    }
  }
  onClick(): void {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
  loginWithCaptcha(content) {
    this.resetErrorOrMessage();
    this.captcha_status = false;
    if (!this.form.get('captcha').value) {
      this.captcha_status = true;
      this.captcha_message = 'Empty Captcha';
      return;
    }
    let captcha: string = this.form.get('captcha').value;
    this.authService.validateCaptcha(captcha).subscribe((data) => {
      if (data['captcha_status'].toString() == 'true') {
        this.login(content);
      } else {
        this.loadNewCaptcha(false);
        this.form.reset();
        this.captcha_status = true;
        //this.error.pipe(tap(err=>err="dqlknfiwqnfio"))
        this.captcha_message = 'Invalid Captcha';
      }
    });

    //  this.submit();
  }
  login(content) {
    const encrypt = new JsEncryptModule.JSEncrypt();
    let key = environment.key;
    encrypt.setPublicKey(key[0].publicKey);

    this.encryptedUsername = btoa(
      encrypt.encrypt(this.form.get('email').value).toString()
    );
    this.encryptedPassword = btoa(
      encrypt.encrypt(this.form.get('password').value).toString()
    );
    //console.log(this.encryptedUsername);
    const loginObject: Login = {
      email: this.encryptedUsername,
      password: this.encryptedPassword,
      userID: this.encryptedUsername,
      id: '',
      type: undefined,
      uuid: '',
      okay: false,
      authenticated: false,
    };
    this.checkSessionAlreadyExistPopUp(content, loginObject);
  }

  /**
   * Submit the authentication form.
   * @method submit
   */
  public submit() {
    this.resetErrorOrMessage();
    // get email and password values

    // trim values
    // email.trim();
    // password.trim();

    if (!this.isStandalonePage) {
      this.authService.setRedirectUrl(
        this.hardRedirectService.getCurrentRoute()
      );
    } else {
      this.authService.setRedirectUrlIfNotSet('/');
    }

    // console.log("inside login");
    // dispatch AuthenticationAction
    //this.store.dispatch(new AuthenticateAction(this.form.get('email').value, this.form.get('password').value));
    this.store.dispatch(
      new AuthenticateAction(this.encryptedUsername, this.encryptedPassword)
    );

    // console.log("outside login");
    // clear form
    this.form.reset();
  }

  private checkSessionAlreadyExistPopUp(content, loginObject: any) {
    const email: string = this.form.get('email').value;
    const password: string = this.form.get('password').value;

    //if email
    if (
      loginObject !== null &&
      loginObject.email != '' &&
      loginObject.email != null &&
      loginObject.email != undefined
    ) {
      //if(email !== " "){
      this.authService
        .authenticateExsistingsession(loginObject.email)
        .subscribe((data) => {
          //this.authService.authenticateExsistingsession(email).subscribe((data) => {
          // console.log(data);
          // this.res = data;
          if (data.sessionExists === true) {
            //console.log("Session Exists true");
            this.modalService
              .open(content, { ariaLabelledBy: 'modal-basic-title' })
              .result.then(
                (result) => {
                  this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                  this.closeResult = `Dismissed ${this.getDismissReason(
                    reason
                  )}`;
                  // console.log("close");
                }
              );
            // this.content = true;
          } else {
            //console.log("Session Exists false");
            // console.log("else condition");
            //  this.content = false;

            this.submit();
          }

          //  error => {
          // this.content = false;
          //console.log("exception occured");
          //console.log(error);
          //  }
        });
    }
  }

  revoke() {
    const encrypt = new JsEncryptModule.JSEncrypt();
    let key = environment.key;
    encrypt.setPublicKey(key[0].publicKey);

    this.encryptedUsername = btoa(
      encrypt.encrypt(this.form.get('email').value).toString()
    );
    //console.log(this.encryptedUsername);
    const loginObject: Login = {
      email: this.encryptedUsername,
      password: this.form.get('password').value,
      userID: '',
      id: '',
      type: undefined,
      uuid: '',
      okay: false,
      authenticated: false,
    };
    this.authService
      .authenticateRevokesession(loginObject.email)
      .subscribe((data) => {
        //  this.res =data;
        if (data != null) {
          if (data.revokeSessionSuccess == true) {
            // console.log("revoke method");
            //  this.authService.loggingout();
            // console.log("+++++++++++++++++++++");
            this.submit();
          } else {
            throw new Error('unable to login');
          }
        } else {
          throw new Error('something went wrong');
        }
      });
  }

  homepage() {
    // console.log("non existing user");
    // this.authService.setRedirectUrlIfNotSet('/');

    this.router.navigateByUrl('/');
    this.modalService.dismissAll();
    // this.closebutton.nativeElement.click();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // ngOnDestroy() {
  //   this.resetTimer();
  // }

  // resetTimer() {
  //   if (this.id) {
  //     clearInterval(this.id);
  //   }
  // }
}
