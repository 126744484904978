<div
  [ngbTooltip]="dso.name"
  [routerLink]="['/communities/', dso.id]"
  class="grid-card"
>
  <!-- <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/communities/', dso.id]" class="card-img-top" [attr.title]="'search.results.view-result' | translate"> -->
  <a
    *ngIf="linkType != linkTypes.None"
    [target]="linkType == linkTypes.ExternalLink ? '_blank' : '_self'"
    rel="noopener noreferrer"
    [routerLink]="['/communities/', dso.id]"
    class="card-img-top"
  >
    <ds-thumbnail
      [thumbnail]="(dso.logo | async)?.payload"
      [limitWidth]="false"
    >
    </ds-thumbnail>

    <div class="card-body card-height mt-2">
      <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
      <h4 class="card-title search-grid-card card-des text-truncate">
        {{ dso.name }}
      </h4>
      <p
        *ngIf="dso.shortDescription"
        class="card-text item-info text-truncate"
        style="font-size: 12px"
      >
        {{ dso.shortDescription }}
      </p>

      <!-- <div *ngIf="linkType != linkTypes.None" class="text-center">
                <a [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/communities/', dso.id]" class="lead btn btn-dark viewButton" >{{ 'search.results.view-result' | translate}}</a>
            </div> -->
    </div>
  </a>
  <span *ngIf="linkType == linkTypes.None" class="card-img-top">
    <ds-thumbnail
      [thumbnail]="(dso.logo | async)?.payload"
      [limitWidth]="false"
    >
    </ds-thumbnail>
  </span>
  <!-- <div class="card-body card-height">
        <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
        <h4 class="card-title search-grid-card card-des" >{{dso.name}}</h4>
        <p *ngIf="dso.shortDescription" class="card-text item-info text-truncate" style="font-size:12px;">{{dso.shortDescription}}</p>
         -->
  <!-- <div *ngIf="linkType != linkTypes.None" class="text-center">
            <a [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/communities/', dso.id]" class="lead btn btn-dark viewButton" >{{ 'search.results.view-result' | translate}}</a>
        </div> -->
  <!-- </div> -->
  <ng-content></ng-content>
</div>
