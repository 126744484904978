import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { uniqueId } from 'lodash';
import { BehaviorSubject, Observable, of as observableOf} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import { AuthStatus } from 'src/app/core/auth/models/auth-status.model';
import { RemoteDataBuildService } from 'src/app/core/cache/builders/remote-data-build.service';
import { RemoteData } from 'src/app/core/data/remote-data';
import { GetRequest } from 'src/app/core/data/request.models';
import { RequestService } from 'src/app/core/data/request.service';
import { RestRequest } from 'src/app/core/data/rest-request.model';
import { HttpOptions } from 'src/app/core/dspace-rest/dspace-rest.service';
import { HALEndpointService } from 'src/app/core/shared/hal-endpoint.service';
import { getFirstCompletedRemoteData } from 'src/app/core/shared/operators';
import { environment } from '../../../environments/environment';
import { isNotEmpty } from '../empty.util';
import { FollowLinkConfig } from '../utils/follow-link-config.model';
import { NotificationOptions } from './models/notification-options.model';
import { NotificationType } from './models/notification-type';
import { INotification, Notification } from './models/notification.model';
import { NewNotificationAction, RemoveAllNotificationsAction, RemoveNotificationAction } from './notifications.actions';

@Injectable()
export class NotificationsService {
  protected linkName = 'event';
  private projectDetails = new BehaviorSubject<object>({});
  private projVerticalName = new BehaviorSubject<object>({});
  private coeDetails = new BehaviorSubject<object>({});
  private coeName = new BehaviorSubject<object>(null);
  // private cmntyLstPage = new BehaviorSubject<object>(null);
  // private folderId = new BehaviorSubject<object>(null);
  private folderName = new BehaviorSubject<object>(null);

  constructor(
    private store: Store<Notification>,
    private translate: TranslateService,
    protected requestService: RequestService,
    protected halService: HALEndpointService,
    private rdbService: RemoteDataBuildService,
    private httpClient: HttpClient
  ) { }

  private add(notification: Notification) {
    let notificationAction;
    notificationAction = new NewNotificationAction(notification);
    this.store.dispatch(notificationAction);
  }

  success(
    title: any = observableOf(''),
    content: any = observableOf(''),
    options: Partial<NotificationOptions> = {},
    html: boolean = false
  ): INotification {
    const notificationOptions = { ...this.getDefaultOptions(), ...options };
    const notification = new Notification(
      uniqueId(),
      NotificationType.Success,
      title,
      content,
      notificationOptions,
      html
    );
    // console.log('*&*&*&^&*');
    this.add(notification);
    // console.log(notification);
    return notification;
  }

  error(
    title: any = observableOf(''),
    content: any = observableOf(''),
    options: Partial<NotificationOptions> = {},
    html: boolean = false
  ): INotification {
    const notificationOptions = { ...this.getDefaultOptions(), ...options };
    const notification = new Notification(
      uniqueId(),
      NotificationType.Error,
      title,
      content,
      notificationOptions,
      html
    );
    this.add(notification);
    return notification;
  }

  info(
    title: any = observableOf(''),
    content: any = observableOf(''),
    options: Partial<NotificationOptions> = {},
    html: boolean = false
  ): INotification {
    const notificationOptions = { ...this.getDefaultOptions(), ...options };
    const notification = new Notification(
      uniqueId(),
      NotificationType.Info,
      title,
      content,
      notificationOptions,
      html
    );
    this.add(notification);
    return notification;
  }

  warning(
    title: any = observableOf(''),
    content: any = observableOf(''),
    options: NotificationOptions = this.getDefaultOptions(),
    html: boolean = false
  ): INotification {
    const notificationOptions = { ...this.getDefaultOptions(), ...options };
    const notification = new Notification(
      uniqueId(),
      NotificationType.Warning,
      title,
      content,
      notificationOptions,
      html
    );
    this.add(notification);
    return notification;
  }

  notificationWithAnchor(
    notificationType: NotificationType,
    options: NotificationOptions,
    href: string,
    hrefTranslateLabel: string,
    messageTranslateLabel: string,
    interpolateParam: string
  ) {
    this.translate
      .get(hrefTranslateLabel)
      .pipe(first())
      .subscribe((hrefMsg) => {
        const anchor = `<a class="align-baseline btn btn-link p-0 m-0" href="${href}" >
                        <strong>${hrefMsg}</strong>
                      </a>`;
        const interpolateParams = Object.create({});
        interpolateParams[interpolateParam] = anchor;
        this.translate
          .get(messageTranslateLabel, interpolateParams)
          .pipe(first())
          .subscribe((m) => {
            switch (notificationType) {
              case NotificationType.Success:
                this.success(null, m, options, true);
                break;
              case NotificationType.Error:
                this.error(null, m, options, true);
                break;
              case NotificationType.Info:
                this.info(null, m, options, true);
                break;
              case NotificationType.Warning:
                this.warning(null, m, options, true);
                break;
            }
          });
      });
  }

  remove(notification: INotification) {
    const actionRemove = new RemoveNotificationAction(notification.id);
    this.store.dispatch(actionRemove);
  }

  removeAll() {
    const actionRemoveAll = new RemoveAllNotificationsAction();
    this.store.dispatch(actionRemoveAll);
  }

  private getDefaultOptions(): NotificationOptions {
    return new NotificationOptions(
      environment.notifications.timeOut,
      environment.notifications.clickToClose,
      environment.notifications.animate
    );
  }

  getEventList() {
    //gets number of total categories
    this.linkName = 'event';
    return this.getRequest('eventList');
  }
  getFilteredEventList() {
    //gets number of total categories
    this.linkName = 'event';
    return this.getRequest('filterEvent');
  }
  getNewsList() {
    //gets number of total categories
    this.linkName = 'news';
    return this.getRequest('newsList');
  }
  getFilteredNewsList() {
    //gets number of total categories
    this.linkName = 'news';
    return this.getRequest('filterNews');
  }
  getAnnouncementList() {
    //gets number of total categories
    this.linkName = 'announcement';
    return this.getRequest('announcementList');
  }
  getFilteredAnnouncementList() {
    //gets number of total categories
    this.linkName = 'announcement';

    return this.getRequest('filterAnnouncement');
  }

  public getRequest(
    method: string,
    options?: HttpOptions,
    ...linksToFollow: FollowLinkConfig<any>[]
  ): Observable<RemoteData<AuthStatus>> {
    return this.halService.getEndpoint(this.linkName).pipe(
      filter((href: string) => isNotEmpty(href)),
      map((endpointURL) =>
        this.getEndpointByMethod(endpointURL, method, ...linksToFollow)
      ),
      distinctUntilChanged(),
      map(
        (endpointURL: string) =>
          new GetRequest(
            this.requestService.generateRequestId(),
            endpointURL,
            undefined,
            options
          )
      ),
      tap((request: GetRequest) => this.requestService.send(request)),
      mergeMap((request: GetRequest) =>
        this.fetchRequest(request, ...linksToFollow)
      ),
      distinctUntilChanged()
    );
  }
  protected getEndpointByMethod(
    endpoint: string,
    method: string,
    ...linksToFollow: FollowLinkConfig<AuthStatus>[]
  ): string {
    let url = isNotEmpty(method) ? `${endpoint}/${method}` : `${endpoint}`;
    if (linksToFollow?.length > 0) {
      linksToFollow.forEach(
        (link: FollowLinkConfig<AuthStatus>, index: number) => {
          url += (index === 0 ? '?' : '&') + `embed=${link.name}`;
        }
      );
    }

    return url;
  }
  protected fetchRequest(
    request: RestRequest,
    ...linksToFollow: FollowLinkConfig<AuthStatus>[]
  ): Observable<RemoteData<AuthStatus>> {
    return this.rdbService
      .buildFromRequestUUID<AuthStatus>(request.uuid, ...linksToFollow)
      .pipe(getFirstCompletedRemoteData());
  }

  addEvent(val: FormData) {
    return this.httpClient.post(
      `${environment.rest.baseUrl}/api/event/addEvent`,
      val
    );
  }
  addNews(val: FormData) {
    return this.httpClient.post(
      `${environment.rest.baseUrl}/api/news/addNews`,
      val
    );
  }
  addAnnouncement(val: FormData) {
    return this.httpClient.post(
      `${environment.rest.baseUrl}/api/announcement/addAnnouncement`,
      val
    );
  }
  updateEvent(val) {
    return this.httpClient.post(
      `${environment.rest.baseUrl}/api/event/updateEvent`,
      val
    );
  }
  updateNews(val) {
    return this.httpClient.post(
      `${environment.rest.baseUrl}/api/news/updateNews`,
      val
    );
  }
  updateAnnouncement(val) {
    return this.httpClient.post(
      `${environment.rest.baseUrl}/api/announcement/updateAnnouncement`,
      val
    );
  }
  deleteEvent(val) {
    return this.httpClient.post(
      `${environment.rest.baseUrl}/api/event/deleteEvent`,
      val
    );
  }
  deleteNews(val) {
    return this.httpClient.post(
      `${environment.rest.baseUrl}/api/news/deleteNews`,
      val
    );
  }
  deleteAnnouncement(val) {
    return this.httpClient.post(
      `${environment.rest.baseUrl}/api/announcement/deleteAnnouncement`,
      val
    );
  }
  archiveEvent(val) {
    return this.httpClient.post(
      `${environment.rest.baseUrl}/api/event/tobeArchivedEvent`,
      val
    );
  }
  archiveNews(val) {
    return this.httpClient.post(
      `${environment.rest.baseUrl}/api/news/tobeArchivedNews`,
      val
    );
  }
  archiveAnnouncement(val) {
    return this.httpClient.post(
      `${environment.rest.baseUrl}/api/announcement/tobeArchivedAnnouncement`,
      val
    );
  }
  archivedNewsList() {
    return this.httpClient.get(
      `${environment.rest.baseUrl}/api/news/archivedNews`
    );
  }
  archivedEventList() {
    return this.httpClient.get(
      `${environment.rest.baseUrl}/api/event/archivedEvent`
    );
  }
  archivedAnnouncementList() {
    return this.httpClient.get(
      `${environment.rest.baseUrl}/api/announcement/archivedAnnouncement`
    );
  }
  //   archivedEvent

  // archivedAnnouncement

  // archivedNews

  addCafeMenu(val: FormData): Observable<any> {
    return this.httpClient.post(
      `${environment.rest.baseUrl}/api/cafemenu/addMenu`,
      val
    );
  }
  updateCafeMenu(cafeMenuId: number, val: any): Observable<any> {
    return this.httpClient.post(
      `${environment.rest.baseUrl}/api/cafemenu/updateMenu/${cafeMenuId}`,
      val
    );
  }

  getCafeMenuList(): Observable<any> {
    return this.httpClient.get(
      `${environment.rest.baseUrl}/api/cafemenu/getMenuList`
    );
  }

  getCafeMenu(): Observable<CafeMenuResponse> {
    return this.httpClient.get<CafeMenuResponse>(`${environment.rest.baseUrl}/api/cafemenu/getMenu`);
  }

  deleteCafeMenu(cafeMenuId): Observable<any> {
    return this.httpClient.post(
      `${environment.rest.baseUrl}/api/cafemenu/deleteCafe/${cafeMenuId}`,
      null
    );
  }
  setProjectDetails(value:any) {
    this.projectDetails.next(value);
  }

  getProjectDetails() {
    return this.projectDetails.asObservable();
  }
  setProjVerticalName(value:any) {
    this.projVerticalName.next(value);
  }

  getProjVerticalName() {
    return this.projVerticalName.asObservable();
  }
  setCoeDetails(value:any) {
    this.coeDetails.next(value);
  }

  getCoeDetails() {
    return this.coeDetails.asObservable();
  }
  setCoeNames(value:any) {
    this.coeName.next(value);
  }

  getCoeNames() {
    return this.coeName.asObservable();
  }
  setCmntyLstPage(fldr:any) {
    this.folderName.next(fldr);
  }

  getCmntyLstPage() {
    return this.folderName.asObservable();
  }
}


export type CafeMenuResponse = CafeMenuModel[]

export interface CafeMenuModel {
  cafeId: number
  cafeCreatedDate: string
  cafeCreatedBy: string
  cafeModifiedDate: string
  cafeModifiedBy: string
  cafeStartDate: string
  cafeEndDate: string
  cafeImage: any
  cafeLocation: string
  cafeIsDeleted: number
}