<div class="page-internal-server-error container">
  <h1 data-test="status">{{ status }}</h1>
  <h2>
    <small>{{ "error-page.description." + status | translate }}</small>
  </h2>
  <br />
  <p>{{ "error-page." + code | translate }}</p>
  <br />
  <p class="text-center">
    <a href="/home" class="btn btn-primary">{{
      status + ".link.home-page" | translate
    }}</a>
  </p>
</div>
