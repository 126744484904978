<ds-themed-loading *ngIf="loading$ | async"></ds-themed-loading>
<div *ngIf="!(loading$ | async)">
  <div class="border-bottom">
    <span class="dropdown-item-text text-smd-bold">{{
      (user$ | async)?.name
    }}</span>
    <span class="dropdown-item-text text-sm logout-block mb-2">{{
      (user$ | async)?.email
    }}</span>
  </div>
  <a
    class="dropdown-item text-sm logout-block mt-2"
    [routerLink]="[profileRoute]"
    routerLinkActive="active"
    >{{ "nav.profile" | translate }}</a
  >
  <a
    class="dropdown-item text-sm logout-block"
    [routerLink]="[mydspaceRoute]"
    routerLinkActive="active"
    >{{ "nav.mydspace" | translate }}</a
  >
  <span class="dropdown-item-text text-sm logout-block"
    >{{ "last.login" | translate }} : {{ getLastActive }}</span
  >

  <ds-log-out></ds-log-out>
</div>
