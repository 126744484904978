<div *ngIf="isCaptchaLoaded">
  <form class="form-login" [formGroup]="form" novalidate>
    <div class="form-fields">
      <label>{{ "login.form.email" | translate }}</label>
      <input
        type="text"
        class="form-control"
        [attr.aria-label]="'login.form.email' | translate"
        autocomplete="off"
        autofocus
        formControlName="email"
        placeholder="{{ 'login.form.email' | translate }}"
        required
        type="email"
        [attr.data-test]="'email' | dsBrowserOnly"
      />
    </div>
    <div class="form-fields my-field">
      <label>{{ "login.form.password" | translate }}</label>
      <input
        class="form-control"
        [attr.aria-label]="'login.form.password' | translate"
        autocomplete="off"
        placeholder="{{ 'login.form.password' | translate }}"
        formControlName="password"
        id="password"
        required
        [type]="password"
        [attr.data-test]="'password' | dsBrowserOnly"
      />
      <span class="eye-viewer">
        <img src="assets/images/eye.svg" (click)="onClick()" *ngIf="!show" class="cur-ptr" />
        <img src="assets/images/eye-slash.svg" (click)="onClick()" *ngIf="show" class="cur-ptr" />
        <!-- <i
          (click)="onClick()"
          class="fa fa-eye"
          aria-hidden="true"
          *ngIf="!show"
        ></i> -->
        <!-- <i
          (click)="onClick()"
          class="fa fa-eye-slash"
          aria-hidden="true"
          *ngIf="show"
        ></i> -->
      </span>
    </div>
    <!-- <div class="form-fields forgot-txt">
      <span>Forgot Password ?</span>
    </div> -->

    <div class="row px-3" *ngIf="isCaptchaEnabled">
      <img loading="lazy"
        class="col-auto pr-0"
        src="data:image/jpeg;base64,{{ captchaImg }}"
        style="height: 36px"
      />

      <span class="col-auto pl-0 pt-2" style="cursor: pointer">
        <i
          (click)="loadNewCaptcha(false)"
          class="fa fa-refresh"
          aria-hidden="true"
        ></i>
      </span>
      <input
        #captcha
        class="col form-control form-control-sm position-relative input-style captcha-space"
        *ngIf="isCaptchaEnabled"
        type="text"
        formControlName="captcha"
        placeholder="Enter Captcha"
      />
    </div>
    <div
      *ngIf="captcha_status"
      class="text-danger small mt-2"
      role="alert"
      @fadeOut
    >
      {{ captcha_message | translate }}
    </div>
    <div
      *ngIf="(error | async) && hasError"
      class="text-danger small mt-2"
      role="alert"
      @fadeOut
    >
      {{ error | async | translate }}
    </div>
    <div
      *ngIf="(message | async) && hasMessage"
      class="alert alert-info"
      role="alert"
      @fadeOut
    >
      {{ message | async | translate }}
    </div>

    <button
      class="submit-btn"
      (click)="isCaptchaEnabled ? loginWithCaptcha(content) : login(content)"
      type="submit"
    >
      {{ "login.title" | translate }}
    </button>
  </form>
</div>

<ng-template
  #content
  let-modal
  data-target="#modal"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-center">
      This user is already logged in. Do you still want to continue from here?
    </p>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="revoke()">
      Yes
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary"
      data-dismiss="modal"
      (click)="homepage()"
    >
      No
    </button>
  </div>
</ng-template>
